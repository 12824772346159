export type Prefilled = Partial<{
  customerEmail: string;
  customerName: string;
}>;

const paramMap: Record<string, keyof Prefilled> = {
  customer_email: 'customerEmail',
  customer_name: 'customerName',
};

let prefilled: Prefilled = {};

export function clear() {
  prefilled = {};
}

export function get(overwrite: Prefilled = {}): Prefilled {
  return {
    ...prefilled,
    ...overwrite,
  };
}

export function initialize() {
  const parsed = new URL(window.location.href);

  prefilled = {};

  Object.keys(paramMap).forEach((k: keyof typeof paramMap) => {
    const fromUrl = parsed.searchParams.get(k);
    if (fromUrl) {
      prefilled[paramMap[k]] = fromUrl;
    }
  });
}

function isStringStringArray(
  item: [string | undefined, string | undefined],
): item is [string, string] {
  return typeof item[0] == 'string' && typeof item[1] === 'string';
}

export function getUrlParams(): Array<[string, string]> {
  const paramEntires = Object.entries(paramMap);
  const prefilledEntires = Object.entries(prefilled).filter(
    isStringStringArray,
  );

  return prefilledEntires
    .map<[string | undefined, string | undefined]>(([key, value]) => {
      const param = paramEntires.find(
        ([urlKey, prefilledKey]) => prefilledKey === key,
      );

      if (param) {
        const [urlKey] = param;
        return [urlKey, value];
      }

      return [undefined, undefined];
    })
    .filter(isStringStringArray);
}
