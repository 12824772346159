import { createHash } from 'crypto';
import { useContext, useEffect, useState } from 'react';
import useDebounce from './use-debounced';
import { NotificationsContext } from './use-notifications';

export const useIsPasswordPwned = (password: string) => {
  const [timesSeen, setTimesSeen] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const { addNotification } = useContext(NotificationsContext);

  const debouncedPassword = useDebounce(password, 500);

  useEffect(() => {
    setFetching(true);
    setError(undefined);
    setTimesSeen(0);
  }, [setError, setFetching, setTimesSeen, password]);

  useEffect(() => {
    const fetchHashes = async () => {
      const hash = createHash('sha1')
        .update(debouncedPassword)
        .digest('hex')
        .toUpperCase();

      const prefix = hash.substring(0, 5);
      const suffix = hash.substring(5);

      const url = `https://api.pwnedpasswords.com/range/${prefix}`;

      let seen = 0;
      let error: Error | undefined = undefined;

      try {
        const res = await fetch(url, { headers: { 'Add-Padding': 'true' } });
        const data = (await res.text()).split('\n');
        const found = data.find(row => row.startsWith(suffix));

        if (found) {
          const freq = found.split(':');
          seen = Number(freq[1]);
        }
      } catch (e) {
        error = e;
      }

      if (password === debouncedPassword) {
        setTimesSeen(seen);
        setError(error);
        setFetching(false);
      }
    };

    fetchHashes().catch(e => {
      let message = 'Something went wrong';
      if (e instanceof Error) {
        message = e.message;
      }
      addNotification({ variant: 'error', message });
    });
  }, [
    addNotification,
    debouncedPassword,
    password,
    setError,
    setFetching,
    setTimesSeen,
  ]);

  return { timesSeen, fetching, error };
};
