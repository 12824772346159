import React from 'react';
import { Box, Tabs, Tab } from '@material-ui/core';
import ChannelsIcon from '../../components/ChannelsIcon/ChannelsIcon';
import { DashboardLineChart } from '../../components/DashboardLineChart/DashboardLineChart';
import DashboardMetricBox from '../../components/DashboardMetrixBox/DashboardMetricBox';
import { DashboardSectionText } from '../../components/DashboardSectionText/DashboardSectionText';
import { Dashboard_dashboard as Dashboard } from '../../schema';
import { ChartMetric } from './state';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core';

export type DashboardData = Dashboard['facebook'] | Dashboard['instagram'];
export type ChartData = {
  value: number;
  year: string;
  month: string;
  change: number;
};

type FbIgOptions = 'FACEBOOK' | 'INSTAGRAM';

type Props = {
  channel: FbIgOptions;
  metric: ChartMetric;
  dashboardData: DashboardData;
  setChartMetric: (metric: ChartMetric, channel: FbIgOptions) => void;
};

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    border: 'none',
    '& td': {
      verticalAlign: 'middle',
      textAlign: 'center',
    },
  },
}));

export const FbIgDashboardSection: React.FC<Props> = ({
  channel,
  metric,
  setChartMetric,
  dashboardData,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (!dashboardData) {
    return <div></div>;
  }

  const dataForChart: ChartData[] = dashboardData[metric].trend.map(entry => ({
    ...entry,
    year: dayjs(entry.label).format('YYYY'),
    month: t(`enums.Month.${dayjs(entry.label).format('MMMM')}`),
  }));

  const metrics: Array<{
    metric: ChartMetric;
    label: string;
  }> = [
    {
      metric: 'followers',
      label: 'Followers',
    },
    {
      metric: 'reach',
      label: 'Reach',
    },
    {
      metric: 'impressions',
      label: 'Impressions',
    },
  ];

  const textBoxWidth = 120;

  return (
    <>
      {/* let's use a raw html table, the easiest way to properly align and arrange items as requested*/}
      <table className={classes.container} cellSpacing="0" cellPadding="0">
        <tr>
          <td rowSpan={4}>
            <div>
              <ChannelsIcon channel={channel} size={50} />
            </div>
            <div>
              <DashboardSectionText
                color="active"
                text={String(t('components.dashboard.analyzed-stores'))}
                width={textBoxWidth}
              />
            </div>
            <div>
              <DashboardSectionText
                color="white"
                text={dashboardData.analyzedStores}
                width={textBoxWidth}
              />
            </div>
          </td>
          <td>
            <DashboardSectionText
              color="active"
              text={String(t('components.dashboard.follower'))}
              width={textBoxWidth}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={dashboardData.followers.num}
              width={textBoxWidth}
              change={dashboardData.followers.change}
            />
          </td>
          <td rowSpan={3} style={{ textAlign: 'right' }}>
            <Box
              style={{
                background: '#fff',
                borderRadius: 5,
                border: '1px solid #ccc',
                width: 640,
                display: 'inline-block',
              }}
            >
              <Tabs
                style={{ background: '#fff' }}
                value={metrics.findIndex(m => m.metric === metric)}
                aria-label="chart tabs"
                variant="fullWidth"
                onChange={(_, value) =>
                  setChartMetric(metrics[value].metric, channel)
                }
              >
                {metrics.map((metric, index) => (
                  <Tab key={index} label={metric.label} />
                ))}
              </Tabs>
              <br />
              <DashboardLineChart data={dataForChart} />
            </Box>
          </td>
        </tr>
        <tr>
          <td>
            <DashboardSectionText
              color="active"
              text={String(t('components.dashboard.reach'))}
              width={textBoxWidth}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={dashboardData.reach.num}
              width={textBoxWidth}
              change={dashboardData.reach.change}
            />
          </td>
        </tr>
        <tr>
          <td>
            <DashboardSectionText
              color="active"
              text={String(t('components.dashboard.impression'))}
              width={textBoxWidth}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={dashboardData.impressions.num}
              width={textBoxWidth}
              change={dashboardData.impressions.change}
            />
          </td>
        </tr>
        <tr>
          <td colSpan={2} style={{ fontSize: 10 }}>
            {t(`components.dashboard.comparison-disclaimer`)}
          </td>
        </tr>
      </table>
    </>
  );
};
