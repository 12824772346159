import ApolloClient from 'apollo-boost';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../../schema.json';
import { TokenType } from '../auth';
import { BACKEND_URI } from '../../constants';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionQueryResultData as any,
});

export function createClient(token: TokenType) {
  const cache = new InMemoryCache({ fragmentMatcher });

  const backendRoot = new URL(BACKEND_URI, window.location.href).href;

  const graphqlUri = new URL('/graphql', backendRoot);

  const client = new ApolloClient({
    uri: graphqlUri.href,
    cache,
    request: (operation): void => {
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      });
    },
  });

  return client;
}
