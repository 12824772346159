import {
  Box,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { ChangeEvent } from 'react';

interface PaginationSelectProps {
  currentPage: number;
  maxPage: number;
  onChangePage: (page: number) => void;
  close: () => void;
}

const useStyles = makeStyles({
  pageSelection: {
    minWidth: 120,
    marginBottom: 11,
  },
});

const range = (size: number, startAt = 0): ReadonlyArray<number> => {
  return [...Array(size).keys()].map(i => i + startAt);
};

export const PaginationSelect: React.FC<PaginationSelectProps> = ({
  currentPage,
  maxPage,
  close,
  onChangePage,
}) => {
  const classes = useStyles();

  const handleSetPage = (e: ChangeEvent<{ value: unknown }>) => {
    const newPage = e.target.value as number;
    onChangePage(newPage);
    close();
  };

  return (
    <Box mt={2} p={1}>
      <FormControl variant="standard">
        <form>
          <Box display="flex" alignItems="center">
            <Select
              id="page-select"
              className={classes.pageSelection}
              value={currentPage}
              onChange={handleSetPage}
            >
              {range(maxPage, 0).map(pageNum => {
                return (
                  <MenuItem key={String(pageNum)} value={pageNum}>
                    {/* Starting page numbers as one for a more user friendly experience */}
                    {pageNum + 1}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </form>
      </FormControl>
    </Box>
  );
};
