import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../lib/auth';
import {
  Grid,
  Box,
  Typography,
  Divider,
  CircularProgress,
  Link,
} from '@material-ui/core';
import InteractiveButton from '../../components/InteractiveButton/InteractiveButton';
import TextInput from '../../components/TextInput/TextInput';
import { LoginVariables, Login } from '../../schema';
import { useThrowingMutation } from '../../lib/graphql/graphql';
import { LOG_IN } from '../../lib/graphql/resolvers/mutations/login';
import { NotificationsContext } from '../../lib/use-notifications';
import { useAppTheme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { useLoginWithLocalAuthtokenMutation } from '../../lib/graphql/resolvers/mutations/login-with-auth-token';
import { useLoginWithUppAuthtokenMutation } from '../../lib/graphql/resolvers/mutations/login-with-auth-token';
import { ClientConfigContext } from '../../lib/client-config';
import { SsoType } from '../../lib/types';
import { getUrlParams as getPrefillUrlParams } from '../../lib/prefill';
import { useGqlClient } from '../../lib/graphql/use-gql-client';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: `${theme.spacing(8)}px auto`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 400,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    minWidth: 180,
  },
  logo: {
    maxHeight: '40px',
  },
  rememberMe: {
    color: theme.palette.grey[400],
    '& svg': {
      color: theme.palette.grey[400],
    },
    '& span.Mui-checked svg': {
      color: theme.palette.grey[600],
    },
  },
  showLoginButton: {
    position: 'fixed',
    left: 5,
    bottom: 1,
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.05)',
  },
}));

const LoginView: React.FC = props => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useAppTheme();
  const [emailState, setEmailState] = useState('');
  const [passwordState, setPasswordState] = useState('');
  const [emailError, setEmailError] = useState('');
  const authContext = useContext(AuthContext);
  const { addNotification } = useContext(NotificationsContext);
  const { t } = useTranslation();
  const {
    config: { featureFlags, name, uppSsoUrl },
  } = useContext(ClientConfigContext);
  const gqlClient = useGqlClient();
  const [autoLoginLoading, setAutoLoginLoading] = useState(false);

  const [login] = useThrowingMutation<Login, LoginVariables>(LOG_IN);
  const loginWithLocalAuthtoken = useLoginWithLocalAuthtokenMutation();
  const loginWithUppAuthtoken = useLoginWithUppAuthtokenMutation();
  const currUrl = new URL(document.location.href);
  const afterLoginPath = currUrl.searchParams.get('next') || '/';
  const uppAuthToken = currUrl.searchParams.get('auth-token');

  const additionalLoginMethods: Array<'auth0' | 'gotu'> = [];
  if (emailState.endsWith('@gotu.io')) {
    additionalLoginMethods.push('gotu');
  }
  if (featureFlags.includes('HAS_AUTH_WITH_AUTH0')) {
    additionalLoginMethods.push('auth0');
  }

  useEffect(() => {
    const initialError = new URL(document.location.href).searchParams.get(
      'error',
    );
    if (initialError) {
      addNotification({ message: initialError, variant: 'error' });
    }

    // it is correct to have empty array here- this hook must run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let mounted = true;

    async function tryLoginWithLocalTokenFromUrl() {
      const params = new URL(document.location.href).searchParams;

      const jwt = params.get('jwt');
      if (jwt) {
        return jwt;
      }

      const authToken = params.get('token');
      if (authToken) {
        const res = await loginWithLocalAuthtoken({ token: authToken });
        return res.loginWithLocalAuthToken.token;
      }

      return undefined;
    }

    async function tryLoginWithUppToken() {
      if (uppAuthToken) {
        const res = await loginWithUppAuthtoken({
          token: uppAuthToken,
        });
        return res.loginWithUppAuthToken.token;
      }

      return undefined;
    }

    if (uppSsoUrl) {
      // login using Upp SSO
      if (uppAuthToken) {
        // second step for Upp SSO: browser was redirect back after successful login on upp,
        // use returned token to get local identity and login
        setAutoLoginLoading(true);
        tryLoginWithUppToken()
          .then(jwt => {
            if (jwt) {
              authContext.setToken(jwt);
              history.push(afterLoginPath);
            }
          })
          .catch(e => {
            console.log(e);
            addNotification({ message: e.message, variant: 'error' });
          })
          .finally(() => {
            if (mounted) {
              setAutoLoginLoading(false);
            }
          });
      }
    } else {
      // default login using email+pwd or auth0
      setAutoLoginLoading(true);
      tryLoginWithLocalTokenFromUrl()
        .then(jwt => {
          if (jwt) {
            authContext.setToken(jwt);
            history.push(afterLoginPath);
          }
        })
        .catch(e => {
          console.log(e);
          addNotification({ message: e.message, variant: 'error' });
        })
        .finally(() => {
          if (mounted) {
            setAutoLoginLoading(false);
          }
        });
    }

    return () => {
      mounted = false;
    };

    // it is correct to have empty array here- this hook must run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [redirectingSso, setRedirectingSso] = useState(false);

  const handleSso = async (type: SsoType) => {
    if (uppSsoUrl) {
      handleUppSsoLogin();
      return;
    }
    const returnUrl = new URL(document.location.href);
    returnUrl.searchParams.delete('action');
    returnUrl.searchParams.delete('access_token'); // wind3 sends us this and we don't need it at all
    returnUrl.searchParams.set('jwt', '__token__');

    const prefillParams = getPrefillUrlParams();
    prefillParams.forEach(([key, value]) =>
      returnUrl.searchParams.set(key, value),
    );

    try {
      const res = await gqlClient.loginSso({
        type,
        redirectUrl: returnUrl.href,
      });

      // this is not an error, because we want to use the old href (before async call) as base for return url
      // eslint-disable-next-line require-atomic-updates
      setRedirectingSso(true);
      document.location.href = res.loginSso.redirectUrl;
    } catch (e) {
      setRedirectingSso(false);
      console.log(e);
    }
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;
    if (!emailRegex.test(email)) {
      setEmailError(t('validation-errors.valid-email'));
      return false;
    }
    setEmailError('');
    return true;
  };

  const getAction = () => {
    return new URL(document.location.href).searchParams.get('action') || null;
  };

  useEffect(() => {
    const action = getAction();

    // for backward compatibility action can be either "sso" (curr action) or "auth0" (old action)
    const validSsoActions = ['sso', 'auth0'];
    if (action && validSsoActions.includes(action)) {
      handleSso(SsoType.DEFAULT).catch(console.error);
    }

    // it is correct to have empty array here- this hook must run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    event.preventDefault();
    if (validateEmail(emailState)) {
      try {
        const res = await login({
          email: emailState,
          password: passwordState,
        });
        authContext.setToken(res.login.token);
        history.push(afterLoginPath);
      } catch (e) {
        setEmailState('');
        setPasswordState('');
        addNotification({
          message: t('components.login.failed'),
          variant: 'error',
        });
        console.error(e);
      }
    }
  };

  const handleUppSsoLogin = (): void => {
    setAutoLoginLoading(true);
    // first auth step for Upp SSO: redirect to Upp to get auth token
    const redirectUrl = new URL(window.location.href);
    redirectUrl.searchParams.delete('action');

    const uppFullUrl = new URL(uppSsoUrl);
    uppFullUrl.searchParams.set('redirect', redirectUrl.toString());
    window.location.href = uppFullUrl.toString();
  };

  const handleNavigateToRequestReset = (e: React.SyntheticEvent) => {
    e.preventDefault();
    history.push('reset-password');
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={12} md={5}>
        <Box
          bgcolor="grey.200"
          color="black"
          height="100%"
          pt={16}
          pb={4}
          paddingX={4}
        >
          <Box width={300} margin={'auto'}>
            <Box mb={3}>
              <img
                className={classes.logo}
                src={theme.assets.logo}
                alt="logo"
              />
            </Box>
            <Typography variant="h4" gutterBottom>
              {t(`components.login.title.${name}`)
                .split('\n')
                .map((c, index) => (
                  <div key={index}>{c}</div>
                ))}
            </Typography>
            <Typography variant="h6" style={{ fontWeight: 'normal' }}>
              {t('components.login.summary')}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid container item xs={12} md={7} alignItems="center">
        <div className={classes.paper}>
          <>
            {autoLoginLoading ? (
              <CircularProgress size={60} />
            ) : (
              <>
                {!uppSsoUrl && (
                  <Typography component="h1" variant="h4">
                    {t(`components.login.sign-in.${name}`)}
                  </Typography>
                )}
                {!uppSsoUrl && (
                  <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <TextInput
                      id="email"
                      label={t('fields.user.email')}
                      name="email"
                      autoComplete="email"
                      autoFocus
                      error={Boolean(emailError)}
                      helperText={emailError}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        setEmailState(event.target.value);
                      }}
                      onBlur={(
                        event: React.FocusEvent<HTMLInputElement>,
                      ): void => {
                        validateEmail(event.target.value);
                      }}
                      value={emailState}
                    />
                    <TextInput
                      name="password"
                      label={t('fields.user.password')}
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        setPasswordState(event.target.value);
                      }}
                      value={passwordState}
                    />
                    <InteractiveButton
                      className={classes.button}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      disabled={!emailState || !passwordState}
                      label={t(`components.login.sign-in.${name}`)}
                      onClick={handleSubmit}
                    />
                    {additionalLoginMethods.length > 0 && (
                      <>
                        <Box
                          marginY={1}
                          textAlign={'center'}
                          color="text.secondary"
                          position="relative"
                        >
                          <Divider variant="middle" />
                          <Typography
                            style={{
                              display: 'inline',
                              position: 'absolute',
                              top: -12,
                              left: `calc(50% - ${Math.round(
                                t(`components.login.or-sign-in-with.${name}`)
                                  .length * 4.5,
                              )}px)`,
                              background: '#fafafa',
                              padding: '0 8px',
                            }}
                          >
                            {t(`components.login.or-sign-in-with.${name}`)}
                          </Typography>
                        </Box>
                        <Box textAlign={'center'} width="100%">
                          {additionalLoginMethods.includes('auth0') && (
                            <InteractiveButton
                              type="button"
                              className={classes.button}
                              variant="contained"
                              color="primary"
                              onClick={() => handleSso(SsoType.DEFAULT)}
                              label={t(`components.login.sso.${name}`)}
                              disabled={redirectingSso}
                            />
                          )}
                          {additionalLoginMethods.includes('gotu') && (
                            <InteractiveButton
                              type="button"
                              className={classes.button}
                              variant="contained"
                              color="primary"
                              onClick={() => handleSso(SsoType.GOTU)}
                              label={t('components.login.gotu')}
                              disabled={redirectingSso}
                            />
                          )}
                        </Box>
                      </>
                    )}
                  </form>
                )}
                {!!uppSsoUrl && !getAction() && (
                  <InteractiveButton
                    className={classes.button}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    label={t(`components.login.sign-in.${name}`)}
                    onClick={handleUppSsoLogin}
                  />
                )}
              </>
            )}
          </>
          {featureFlags.includes('CAN_RESET_PASSWORD') && !uppSsoUrl && (
            <Box flex justifyContent="flex-start" style={{ width: '100%' }}>
              <Typography>
                <Link
                  href="/reset-password"
                  onClick={handleNavigateToRequestReset}
                >
                  {t('components.login.forgot-password')}
                </Link>
              </Typography>
            </Box>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default LoginView;
