import React, {
  ReactNode,
  useMemo,
  useContext,
  useEffect,
  StrictMode,
  Suspense,
} from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useFavicon, useTitle } from 'react-use';
import { AuthContext, useAuthContextState } from './lib/auth';
import { ApolloProvider } from 'react-apollo';
import { createClient } from './lib/graphql/apollo';
import {
  NotificationsContext,
  useNotificationsContextState,
} from './lib/use-notifications';
import { GetCurrentUser } from './schema';
import { createTheme } from './theme';
import Header from './components/Header/Header';
import { useGetCurrentUserQuery } from './lib/graphql/resolvers/queries/current-user';
import FullscreenLoading from './views/Layout/FullscreenLoading';
import Notification from './components/Notification/Notification';
import LoginView from './views/Login/LoginView';
import { useTranslation } from 'react-i18next';
import StoreListView from './views/Stores/List/StoreListView';
import StoreView from './views/Stores/Store/StoreView';
import TaskListView from './views/Tasks/List/TaskListView';
import TaskListPerStoreView from './views/Tasks/ListPerStore/TaskListPerStoreView';
import PublishingTargetListView from './views/PublishingTarget/List/PublishingTargetListView';
import FbOnboarding from './views/FbOnboarding/FbOnboarding';
import {
  ClientConfigContext,
  useClientConfigContextState,
} from './lib/client-config';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import { ChangePasswordView } from './views/ChangePassword/ChangePasswordView';
import { GlobalPermission, Permission } from './lib/types';
import { ResetPasswordView } from './views/ResetPassword/ResetPasswordView';
import IterationListView from './views/Iterations/List/IterationListView';
import RedirectToUberallView from './views/RedirectToUberall/RedirectToUberall';
import { DashboardView } from './views/DashboardView/DashboardView';

type LoggedInProps = {
  user: NonNullable<GetCurrentUser['currentUser']>;
};

const LoggedIn: React.FC<LoggedInProps> = ({ user }) => {
  const {
    config: { interfaceLanguages, name },
  } = useContext(ClientConfigContext);
  const { t, i18n } = useTranslation();
  useTitle(t(`window.title.${name}`));

  useEffect(() => {
    const currentLanguage = i18n.language;
    const availableLanguages = interfaceLanguages.map<string>(il => il.locale);

    if (!availableLanguages.includes(currentLanguage)) {
      void i18n.changeLanguage(availableLanguages[0]);
    }

    // it is correct to have empty array here- this hook must run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle login url when logged in and allow login redirects to work when logged in too
  const url = new URL(document.location.href);
  const nextPath = url.searchParams.get('next') || '/';

  const defaultPath = '/stores';

  return (
    <>
      <Header user={user} />
      <Switch>
        <Route
          exact
          path="/"
          render={(): ReactNode => {
            return <Redirect to={defaultPath} />;
          }}
        />
        <Route path="/fbonboarding" component={FbOnboarding} />
        <Route path="/stores/:remoteId" component={StoreView} />
        <Route
          path="/tasks/:remoteId/:taskType"
          component={TaskListPerStoreView}
        />
        <Route path="/stores" render={() => <StoreListView user={user} />} />
        <Route
          path="/iterations"
          render={() => <IterationListView user={user} />}
        />
        <Route path="/tasks" render={() => <TaskListView user={user} />} />
        <Route
          path="/publishing-targets"
          component={PublishingTargetListView}
        />
        <Route path="/login">
          <Redirect to={nextPath} />
        </Route>
        <Route path="/change-password">
          {!user.permissions.includes(Permission.USER_CHANGE_PASSWORD) ? (
            <Redirect to="/" />
          ) : (
            <ChangePasswordView user={user} />
          )}
        </Route>
        <Route
          path="/redirect-to-locations"
          render={() => <RedirectToUberallView user={user} />}
        />
        <Route path="/dashboard">
          {user.globalPermissions.includes(
            GlobalPermission.ACCESS_DASHBOARD,
          ) ? (
            <DashboardView />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
      </Switch>
    </>
  );
};

const LoggedOut: React.FC = () => {
  const {
    config: { interfaceLanguages, featureFlags, name },
  } = useContext(ClientConfigContext);

  const location = useLocation();
  const { t, i18n } = useTranslation();
  useTitle(t(`window.title.${name}`));

  useEffect(() => {
    const currentLanguage = i18n.language;
    const availableLanguages = interfaceLanguages.map<string>(il => il.locale);

    if (!availableLanguages.includes(currentLanguage)) {
      void i18n.changeLanguage(availableLanguages[0]);
    }

    // it is correct to have empty array here- this hook must run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route path="/fbonboarding" component={FbOnboarding} />
      <Route exact path="/login" component={LoginView} />
      {featureFlags.includes('CAN_RESET_PASSWORD') && (
        <Route path="/reset-password" component={ResetPasswordView} />
      )}
      <Route
        path="*"
        render={(): ReactNode => {
          const search =
            location.pathname === '/'
              ? undefined
              : `?next=${location.pathname}`;

          return <Redirect to={{ pathname: '/login', search }} />;
        }}
      />
    </Switch>
  );
};

const App: React.FC = () => {
  const { data: currentUser, loading } = useGetCurrentUserQuery();
  const user = currentUser && currentUser.currentUser;

  if (loading) {
    return <FullscreenLoading />;
  }

  return (
    <Suspense fallback={<FullscreenLoading />}>
      <CssBaseline />
      <Router>
        {user && <LoggedIn user={user} />}
        {!user && <LoggedOut />}
      </Router>
    </Suspense>
  );
};

const AppNotificationWrapper: React.FC = ({ children }) => {
  const notificationsContextState = useNotificationsContextState();
  const notification = notificationsContextState.notifications.find(() => true);

  return (
    <NotificationsContext.Provider value={notificationsContextState}>
      {children}
      {notification && (
        <Notification
          open={true}
          onClose={() => notificationsContextState.closeFirstNotification()}
          message={notification.message}
          variant={notification.variant}
        />
      )}
    </NotificationsContext.Provider>
  );
};

const AppThemeWrapper: React.FC = ({ children }) => {
  const clientConfig = useContext(ClientConfigContext);

  const theme = createTheme(clientConfig.config.theme, clientConfig.baseUri);

  useFavicon(theme.assets.favicon);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const AppApolloWrapper: React.FC = ({ children }) => {
  const auth = useContext(AuthContext);
  const apollo = useMemo(() => createClient(auth.token), [auth.token]);

  return <ApolloProvider client={apollo}>{children}</ApolloProvider>;
};

const AppAuthWrapper: React.FC = ({ children }) => {
  const authContextState = useAuthContextState();

  return (
    <AuthContext.Provider value={authContextState}>
      {children}
    </AuthContext.Provider>
  );
};

const AppClientConfigWrapper: React.FC = ({ children }) => {
  const { loading, config } = useClientConfigContextState();

  if (loading || typeof config === 'undefined') {
    return <FullscreenLoading />;
  }

  return (
    <ClientConfigContext.Provider value={config}>
      {children}
    </ClientConfigContext.Provider>
  );
};

const FullyWrappedApp: React.FC = ({ children }) => {
  return (
    <StrictMode>
      <AppClientConfigWrapper>
        <AppAuthWrapper>
          <AppApolloWrapper>
            <AppThemeWrapper>
              <AppNotificationWrapper>
                <MuiPickersUtilsProvider utils={DayjsUtils}>
                  <App />
                </MuiPickersUtilsProvider>
              </AppNotificationWrapper>
            </AppThemeWrapper>
          </AppApolloWrapper>
        </AppAuthWrapper>
      </AppClientConfigWrapper>
    </StrictMode>
  );
};

export default FullyWrappedApp;
