import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  UpsertStoreInput,
  Operators_operators as Operator,
} from '../../../../schema';
import UpsertStore from './UpsertStore';

const useStyles = makeStyles(theme => ({
  actionButton: {
    textTransform: 'capitalize',
    boxShadow: 'none',
    height: 56,
  },
}));

interface Props {
  onClick: () => void;
  done: () => Promise<void>;
  operators: Operator[];
}

const CreateStore: React.FC<Props> = ({ onClick, done, operators }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = () => {
    onClick();
    setDialogOpen(true);
  };

  const defaultFormState: UpsertStoreInput = {
    remoteId: '',
    contactName: null,
    companyName: '',
    storeManagerEmail: null,
    phone: null,
    website: null,
    area: null,
    googleMybusinessLocation: null,
    areaManagerEmail: null,
    facebookPage: null,
    digitalAccountEmail: null,
    salesAccountEmail: null,
    salesDirectorEmail: null,
    operatorEmail: null,
  };

  return (
    <>
      <Button
        className={classes.actionButton}
        type="button"
        onClick={handleClickOpen}
        variant="contained"
        size="large"
        color="secondary"
      >
        {t('components.upsertStoreModal.createStore')}
      </Button>
      <UpsertStore
        type={'insert'}
        onSaved={async () => {
          setDialogOpen(false);
          await done();
        }}
        defaultFormState={defaultFormState}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        operators={operators}
      ></UpsertStore>
    </>
  );
};
export default CreateStore;
