import { TFunction } from 'i18next';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import UnreachableCaseError from '../../lib/unreachable-case.error';
import {
  validatePassword,
  validatePasswordConfirmation,
} from '../ChangePassword/state';

type SetPassword = {
  type: 'SET_PASSWORD';
  password: string;
};

type SetPasswordConfirmation = {
  type: 'SET_PASSWORD_CONFIRMATION';
  passwordConfirmation: string;
};

type ResetInput = {
  type: 'RESET_INPUT';
};

type Action = SetPassword | SetPasswordConfirmation | ResetInput;

type State = {
  password: string;
  passwordError: string | undefined;
  passwordConfirmation: string;
  passwordConfirmationError: string | undefined;
};

const initialState: State = {
  password: '',
  passwordError: undefined,
  passwordConfirmation: '',
  passwordConfirmationError: undefined,
};

function buildPasswordResetReducer(t: TFunction) {
  return function passwordReducer(state: State, action: Action): State {
    switch (action.type) {
      case 'SET_PASSWORD': {
        const error = validatePassword(t, action.password);

        return {
          ...state,
          password: action.password,
          passwordError: error,
        };
      }
      case 'SET_PASSWORD_CONFIRMATION': {
        const error = validatePasswordConfirmation(
          t,
          action.passwordConfirmation,
          state.password,
        );

        return {
          ...state,
          passwordConfirmation: action.passwordConfirmation,
          passwordConfirmationError: error,
        };
      }
      case 'RESET_INPUT': {
        return { ...initialState };
      }
      default:
        throw new UnreachableCaseError(action);
    }
  };
}

export default function usePasswordResetReducer() {
  const { t } = useTranslation();
  const reducer = buildPasswordResetReducer(t);
  return useReducer(reducer, initialState);
}
