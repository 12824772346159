import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    zIndex: 10000,
  },
});

const FullscreenLoading: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CircularProgress size={60} />
    </div>
  );
};

export default FullscreenLoading;
