export const LIST_ITEMS_PER_PAGE = 20;
export const DROPZONE_MAX_FILE_SIZE_IN_KB = 2048;
export const FACEBOOK_SDK_APP_ID = '590875117612218';
export const FACEBOOK_LOGIN_SCOPES =
  'manage_pages,pages_show_list,read_insights,instagram_basic,instagram_manage_insights,pages_read_engagement,business_management';

export const BACKEND_URI =
  process.env.REACT_APP_BACKEND_URI || 'http://localhost:3001/';

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';
