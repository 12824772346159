import { useDirectQuery } from '../../graphql';
import gql from 'graphql-tag';
import { Areas } from '../../../../schema';
import { useQuery } from 'react-apollo';

const AREAS = gql`
  query Areas {
    areas
  }
`;

export function useGetAreasDirectQuery() {
  return useDirectQuery<Areas, null>(AREAS);
}

export function useGetAreasQuery() {
  return useQuery<Areas, null>(AREAS, {
    fetchPolicy: 'network-only',
  });
}
