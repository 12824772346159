import React, { useContext } from 'react';
import { TasksInput } from '../../../../schema';
import {
  makeStyles,
  Grid,
  FormControl,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import { TaskType, TaskStatus } from '../../../../lib/types';
import { useTranslation } from 'react-i18next';
import BootstrapInput from '../../../../components/BootstrapInput/BootstrapInput';
import { StrictFilterState } from '../../../../components/RemoteDataTable/RemoteDataTable';
import {
  IterationList_iterationList_iterations as IterationItem,
  Operators_operators as Operator,
} from '../../../../schema';
import * as _ from 'lodash';
import { ClientConfigContext } from '../../../../lib/client-config';
import {
  iterationDatesToKey,
  iterationKeyToDates,
  iterationsToOptions,
} from '../../../../lib/util';

const useStyles = makeStyles(theme => ({
  filterControl: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

const TaskFilter: React.FC<{
  options?: IterationItem[];
  operators: Operator[];
  state: StrictFilterState<TasksInput>;
  onChange: (value: StrictFilterState<TasksInput>) => void;
}> = ({ options, state, onChange, operators }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    config: { iterationLabelFormat },
  } = useContext(ClientConfigContext);

  return (
    <Grid container>
      <Grid item xs={6} sm={4} md={3}>
        <FormControl variant="outlined" className={classes.filterControl}>
          <Typography variant="subtitle2" color="textSecondary">
            {t('fields.task.type')}
          </Typography>
          <Select
            input={<BootstrapInput />}
            value={state.type}
            onChange={e =>
              onChange({
                ...state,
                type: e.target.value as TaskType,
              })
            }
          >
            {Object.keys(TaskType).map(type => (
              <MenuItem key={type} value={type}>
                {t(`enums.TaskType.${type}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <FormControl variant="outlined" className={classes.filterControl}>
          <Typography variant="subtitle2" color="textSecondary">
            {t('fields.task.status')}
          </Typography>
          <Select
            input={<BootstrapInput />}
            value={state.status}
            onChange={e =>
              onChange({
                ...state,
                status: e.target.value as TaskStatus,
              })
            }
          >
            {Object.keys(TaskStatus).map(status => (
              <MenuItem key={status} value={status}>
                {t(`enums.TaskStatus.${status}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {options && (
        <Grid item xs={6} sm={4} md={3}>
          <FormControl variant="outlined" className={classes.filterControl}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('fields.task.period')}
            </Typography>
            <Select
              input={<BootstrapInput />}
              value={
                state.iterationStartsAt && state.iterationEndsBefore
                  ? iterationDatesToKey({
                      startsAt: state.iterationStartsAt,
                      endsBefore: state.iterationEndsBefore,
                    })
                  : ''
              }
              onChange={e => {
                const iteration = iterationKeyToDates(String(e.target.value));
                onChange({
                  ...state,
                  ...iteration,
                });
              }}
            >
              {iterationsToOptions(options, iterationLabelFormat, t).map(
                (entry, index) => (
                  <MenuItem key={index} value={entry.value}>
                    {entry.label}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </Grid>
      )}
      {operators.length > 0 && (
        <Grid item xs={6} sm={4} md={3}>
          <FormControl variant="outlined" className={classes.filterControl}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('components.taskList.assignedTo')}
            </Typography>
            <Select
              input={<BootstrapInput />}
              value={state.operatorId}
              onChange={e => {
                onChange({
                  ...state,
                  operatorId: e.target.value
                    ? parseInt(String(e.target.value))
                    : null,
                });
              }}
            >
              {operators.map(operator => (
                <MenuItem key={operator.id} value={operator.id}>
                  {operator.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};
export default TaskFilter;
