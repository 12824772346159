import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, Box, Badge, Button, Grid } from '@material-ui/core';
import { Stores_stores_stores as Store } from '../../../../schema';
import InteractiveButton from '../../../../components/InteractiveButton/InteractiveButton';
import { NotificationsContext } from '../../../../lib/use-notifications';
import { Permission } from '../../../../lib/types';
import { useGqlClient } from '../../../../lib/graphql/use-gql-client';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  actions: {
    background: 'white',
    padding: theme.spacing(2.5),
  },
  actionButton: {
    padding: '0 20px 0 0',
    '& button': {
      textTransform: 'capitalize',
      boxShadow: 'none',
      height: 56,
    },
  },
  filterControl: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  simpleTable: {
    width: '100%',
    borderCollapse: 'collapse',
    '& tr': {
      borderBottom: '1px solid #777',
    },
    '& th': {
      textAlign: 'left',
    },
    '& td': {
      padding: '3px',
    },
  },
}));

const StoreAction: React.FC<{
  stores: Store[];
  title: string;
  confirm: string;
  submit: (stores: Store[]) => Promise<void>;
  done: () => Promise<void> | void;
  disabled: boolean;
}> = ({ stores, title, confirm, submit, done, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { addNotification } = useContext(NotificationsContext);

  return (
    <span className={classes.actionButton}>
      <Badge
        badgeContent={stores.length > 1 ? stores.length : null}
        color="secondary"
      >
        <InteractiveButton
          disabled={disabled}
          variant="outlined"
          color="secondary"
          label={title}
          confirm={{
            title,
            content: (
              <>
                <div>{confirm}</div>
                <br />
                <table className={classes.simpleTable}>
                  <tr>
                    <th>{t('components.storeActions.storeId')}</th>
                    <th>{t('components.storeActions.storeName')}</th>
                  </tr>
                  {stores.map((store, index) => (
                    <tr key={index}>
                      <td>{store.remoteId}</td>
                      <td>{store.companyName}</td>
                    </tr>
                  ))}
                </table>
              </>
            ),
            submit: {
              onClick: async () => {
                try {
                  await submit(stores);
                  addNotification({
                    message: t('components.storeActions.success'),
                    variant: 'success',
                  });
                } catch (e) {
                  console.log(e);
                } finally {
                  await done();
                }
              },
            },
          }}
        />
      </Badge>
    </span>
  );
};

interface StoreActionsProps {
  selectedStores: Store[];
  actionDone: () => Promise<void> | void;
  unselectAll: () => void;
}

const StoreActions: React.FC<StoreActionsProps> = ({
  selectedStores,
  actionDone,
  unselectAll,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const gqlClient = useGqlClient();

  const getEligibleStores = () => {
    return {
      sendInvitationEmail: selectedStores.filter(store =>
        store.permissions.includes(Permission.STORE_SEND_INVITATION_EMAIL),
      ),
      onboardingDone: selectedStores.filter(store =>
        store.permissions.includes(Permission.STORE_SET_ONBOARDING_DONE),
      ),
      disable: selectedStores.filter(store =>
        store.permissions.includes(Permission.STORE_DISABLE),
      ),
      cancel: selectedStores.filter(store =>
        store.permissions.includes(Permission.STORE_CANCEL),
      ),
      reactivate: selectedStores.filter(store =>
        store.permissions.includes(Permission.STORE_REACTIVATE),
      ),
    };
  };

  const [actionToEligibleStores, setActionToEligibleStores] = useState(
    getEligibleStores(),
  );

  useEffect(() => {
    setActionToEligibleStores(getEligibleStores());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStores]);

  const [busy, setBusy] = useState(false);

  const doAction = async (actionFn: () => Promise<void>) => {
    setBusy(true);
    try {
      await actionFn();
      setBusy(false);
    } catch (e) {
      setBusy(false);
      throw e;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Box className={classes.actions}>
          {/**
           * STORE ACTIONS
           */}
          {actionToEligibleStores.sendInvitationEmail.length > 0 && (
            <StoreAction
              disabled={busy}
              stores={actionToEligibleStores.sendInvitationEmail}
              title={t('components.storeActions.sendInvitationEmail')}
              confirm={t('components.storeActions.confirm')}
              submit={async (stores: Store[]) => {
                await doAction(async () => {
                  await gqlClient.sendInvitationEmail({
                    input: {
                      stores: stores.map(store => store.id),
                    },
                  });
                });
              }}
              done={actionDone}
            ></StoreAction>
          )}

          {actionToEligibleStores.onboardingDone.length > 0 && (
            <StoreAction
              disabled={busy}
              stores={actionToEligibleStores.onboardingDone}
              title={t('components.storeActions.onboardingDone')}
              confirm={t('components.storeActions.confirm')}
              submit={async (stores: Store[]) => {
                await doAction(async () => {
                  await gqlClient.storeOnboardingDone({
                    input: {
                      stores: stores.map(store => store.id),
                    },
                  });
                });
              }}
              done={actionDone}
            ></StoreAction>
          )}

          {actionToEligibleStores.disable.length > 0 && (
            <StoreAction
              disabled={busy}
              stores={actionToEligibleStores.disable}
              title={t('components.storeActions.disableStore')}
              confirm={t('components.storeActions.confirm')}
              submit={async (stores: Store[]) => {
                await doAction(async () => {
                  await gqlClient.disableStore({
                    input: {
                      stores: stores.map(store => store.id),
                    },
                  });
                });
              }}
              done={actionDone}
            ></StoreAction>
          )}

          {actionToEligibleStores.cancel.length > 0 && (
            <StoreAction
              disabled={busy}
              stores={actionToEligibleStores.cancel}
              title={t('components.storeActions.cancelStore')}
              confirm={t('components.storeActions.confirm')}
              submit={async (stores: Store[]) => {
                await doAction(async () => {
                  await gqlClient.cancelStore({
                    input: {
                      stores: stores.map(store => store.id),
                    },
                  });
                });
              }}
              done={actionDone}
            ></StoreAction>
          )}

          {actionToEligibleStores.reactivate.length > 0 && (
            <StoreAction
              disabled={busy}
              stores={actionToEligibleStores.reactivate}
              title={t('components.storeActions.reactivateStore')}
              confirm={t('components.storeActions.confirm')}
              submit={async (stores: Store[]) => {
                await doAction(async () => {
                  await gqlClient.reactivateStore({
                    input: {
                      stores: stores.map(store => store.id),
                    },
                  });
                });
              }}
              done={actionDone}
            ></StoreAction>
          )}

          <span className={classes.actionButton}>
            <Button
              disabled={busy}
              type="button"
              variant="contained"
              color="primary"
              onClick={unselectAll}
            >
              {t('components.storeActions.unselectAll')}
            </Button>
          </span>
        </Box>
      </Grid>
    </Grid>
  );
};
export default StoreActions;
