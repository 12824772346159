export function dashboardFormatNumber(
  num: number,
  opts?: {
    showSignAlways?: boolean;
    decimalPlaces?: number;
  },
): string {
  // so far dashboard (and more in general all data project customers) are italian,
  // using a fixed formatter (TODO- should work based on some client config)
  return new Intl.NumberFormat('it-IT', {
    signDisplay: opts?.showSignAlways ? 'always' : 'auto',
    minimumFractionDigits: opts?.decimalPlaces || undefined,
    maximumFractionDigits: opts?.decimalPlaces || undefined,
  }).format(parseFloat(String(num)));
}
