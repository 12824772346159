import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableHead,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useGqlClient } from '../../../lib/graphql/use-gql-client';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import InteractiveButton from '../../../components/InteractiveButton/InteractiveButton';
import { useStyles } from '../../../components/RemoteDataTable/RemoteDataTable';
import { useGetScheduledPublishingTargetsQuery } from '../../../lib/graphql/resolvers/queries/scheduled-publishing-targets';
import { FullScreenError } from '../../../components/FullScreenError/FullScreenError';
import FullscreenLoading from '../../Layout/FullscreenLoading';
import { useGetIterationListQuery } from '../../../lib/graphql/resolvers/queries/iteration-list';
import { IterationDates } from '../../../lib/types';
import {
  iterationDatesToKey,
  iterationDatesToLabel,
  iterationKeyToDates,
  iterationsToOptions,
} from '../../../lib/util';
import { ClientConfigContext } from '../../../lib/client-config';

const PublishingTargetListView: React.FC<{ iterations: IterationDates[] }> = ({
  iterations,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const gqlClient = useGqlClient();
  const {
    config: { iterationLabelFormat },
  } = useContext(ClientConfigContext);
  const [iteration, setIteration] = useState(
    iterations.length ? iterationDatesToKey(iterations[0]) : '',
  );
  const [target, setTarget] = useState(1);
  const {
    loading,
    data,
    error,
    refetch,
  } = useGetScheduledPublishingTargetsQuery({
    input: { take: 20 }, // always getting the n most recent ones
  });
  const [availableReports, setAvailableReports] = useState<number | null>(null);

  const setPublishingTarget = async () => {
    const it = iterationKeyToDates(iteration);
    try {
      await gqlClient.setPublishingTarget({
        input: {
          ...it,
          target,
        },
      });
      await refetch();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setAvailableReports(null);
    const it = iterationKeyToDates(iteration);
    gqlClient
      .getAvailableReports(it)
      .then(res => setAvailableReports(res.availableReports))
      .catch(e => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iteration]);

  if (error) {
    return <FullScreenError error={error} />;
  }

  return (
    <>
      <Box p={3} style={{ background: '#fff' }}>
        <Grid container item xs={12} md={12}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Grid container item xs={12} md={12} alignItems="flex-end">
              <Grid item xs={6}>
                <InputLabel>
                  {t('components.publishingTargetList.period')}
                </InputLabel>
                <Select
                  value={iteration}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    setIteration(String(e.target.value))
                  }
                >
                  {iterationsToOptions(iterations, iterationLabelFormat, t).map(
                    (entry, index) => (
                      <MenuItem key={index} value={entry.value}>
                        {entry.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>
                  {t('components.publishingTargetList.target')}
                </InputLabel>
                <Select
                  value={target}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                    setTarget(e.target.value as number)
                  }
                >
                  {_.range(0, 51).map(val => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InteractiveButton
                  disabled={!iteration}
                  color="secondary"
                  variant="contained"
                  label={t('components.publishingTargetList.update')}
                  confirm={{
                    title: t(
                      'components.publishingTargetList.updateModalTitle',
                    ),
                    content: (
                      <>
                        {availableReports == null && (
                          <CircularProgress size={60} />
                        )}
                        {availableReports != null && (
                          <div>
                            {t('components.publishingTargetList.updateWarning')}
                          </div>
                        )}
                        {availableReports != null && availableReports > 0 && (
                          <div>
                            <br />
                            {t(
                              'components.publishingTargetList.existingReportsWarning',
                              {
                                num: availableReports,
                              },
                            )}
                          </div>
                        )}
                      </>
                    ),
                    abort: {
                      label: t('components.publishingTargetList.cancel'),
                    },
                    submit: {
                      disabled: availableReports == null,
                      label: t('components.publishingTargetList.confirm'),
                      onClick: () => setPublishingTarget(),
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {data && data.scheduledPublishingTargets.targets.length > 0 && (
        <Grid container>
          <Grid item xs={12} md={12}>
            <Box p={2.5} className={classes.dataTableWrapper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell>
                      {t('components.publishingTargetList.period')}
                    </TableCell>
                    <TableCell align="right">
                      {t('components.publishingTargetList.target')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        className={classes.loadingContainer}
                      >
                        <FullscreenLoading />
                      </TableCell>
                    </TableRow>
                  )}
                  {data.scheduledPublishingTargets.targets.map(
                    (target, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {iterationDatesToLabel(
                            target,
                            iterationLabelFormat,
                            t,
                          )}
                        </TableCell>
                        <TableCell align="right">{target.target}</TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
      )}
      {data && data.scheduledPublishingTargets.targets.length == 0 && (
        <Alert severity="warning">
          {t('components.remoteDataTable.noData')}
        </Alert>
      )}
    </>
  );
};

const PreloadedPublishingTargetListView: React.FC<void> = () => {
  const { loading, data, error } = useGetIterationListQuery({ input: {} });

  if (loading) {
    return <FullscreenLoading />;
  }

  if (error) {
    return <h1>{String(error)}</h1>;
  }

  return (
    <PublishingTargetListView
      iterations={data ? data.iterationList.iterations : []}
    ></PublishingTargetListView>
  );
};

export default PreloadedPublishingTargetListView;
