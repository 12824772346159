import gql from 'graphql-tag';
import { useDirectMutation } from '../../graphql';
import {
  RequestPasswordReset,
  RequestPasswordResetVariables,
} from '../../../../schema';

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input) {
      success
    }
  }
`;

export function useRequestPasswordResetMutation() {
  return useDirectMutation<RequestPasswordReset, RequestPasswordResetVariables>(
    REQUEST_PASSWORD_RESET,
  );
}
