import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React, { useContext, useState } from 'react';
import TextInput from '../../components/TextInput/TextInput';
import { NotificationsContext } from '../../lib/use-notifications';
import { useTranslation } from 'react-i18next';
import { useGqlClient } from '../../lib/graphql/use-gql-client';

type Props = {
  classes: ClassNameMap<'heading' | 'button' | 'input'>;
};

const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;

export const RequestReset: React.FC<Props> = ({ classes }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [loading, setLoading] = useState(false);
  const { addNotification } = useContext(NotificationsContext);

  const gqlClient = useGqlClient();

  const validateEmail = (email: string): boolean => {
    if (!emailRegex.test(email)) {
      setEmailError(t('components.reset-password.validations.email'));
      return false;
    }

    setEmailError('');
    return true;
  };

  const handleChangeEmail = (e: React.ChangeEvent<{ value: unknown }>) => {
    setEmail(e.target.value as string);
    validateEmail(e.target.value as string);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    setLoading(true);

    try {
      await gqlClient.requestPasswordReset({ input: { email } });
      addNotification({
        message: t('components.reset-password.request-success'),
        variant: 'success',
      });

      setEmail('');
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <>
      <Box mb={5}>
        <Typography component="h1" variant="h4" className={classes.heading}>
          {t('components.reset-password.request-title')}
        </Typography>
      </Box>
      <form noValidate onSubmit={handleSubmit}>
        <TextInput
          label={t('components.reset-password.labels.email')}
          value={email}
          type="email"
          error={Boolean(emailError)}
          helperText={emailError}
          onChange={handleChangeEmail}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
            validateEmail(e.target.value)
          }
        />
        <Button
          variant="contained"
          type="submit"
          className={classes.button}
          color="secondary"
          disabled={!email || Boolean(emailError)}
          fullWidth
        >
          {loading ? (
            <CircularProgress color="inherit" />
          ) : (
            t('components.reset-password.submit')
          )}
        </Button>
      </form>
    </>
  );
};
