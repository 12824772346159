import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import { MutationResult } from 'react-apollo';
import { useCallback } from 'react';

export function useThrowingMutation<TData, TVariables>(
  mutation: DocumentNode,
): [(variables: TVariables) => Promise<TData>, MutationResult<TData>] {
  const [execute, result] = useMutation(mutation);

  const mutate = useCallback(
    async function (variables: TVariables): Promise<TData> {
      const { errors, data } = await execute({ variables });
      if (errors) {
        throw errors;
      }
      return data;
    },
    [execute],
  );

  return [mutate, (result as unknown) as MutationResult<TData>];
}

export function useDirectMutation<TData, TVariables>(
  mutation: DocumentNode,
): (variables: TVariables) => Promise<TData> {
  const apollo = useApolloClient();

  return useCallback(
    async (variables: TVariables): Promise<TData> => {
      const { data, errors } = await apollo.mutate({
        mutation,
        variables,
      });

      if (errors) {
        throw errors;
      }

      return data;
    },
    [apollo, mutation],
  );
}

export function useDirectQuery<TData, TVariables>(
  query: DocumentNode,
): (variables: TVariables) => Promise<TData> {
  const apollo = useApolloClient();

  return useCallback(
    async (variables: TVariables): Promise<TData> => {
      const { data, errors } = await apollo.query({
        query,
        variables,
        fetchPolicy: 'network-only',
      });

      if (errors) {
        throw errors;
      }

      return data;
    },
    [apollo, query],
  );
}
