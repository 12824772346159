import React, { useContext } from 'react';
import {
  PeriodsInput,
  IterationList_iterationList_iterations as Period,
  GetCurrentUser_currentUser as User,
} from '../../../../schema';
import {
  makeStyles,
  Grid,
  FormControl,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BootstrapInput from '../../../../components/BootstrapInput/BootstrapInput';
import { StrictFilterState } from '../../../../components/RemoteDataTable/RemoteDataTable';
import {
  iterationDatesToKey,
  iterationKeyToDates,
  iterationsToOptions,
} from '../../../../lib/util';
import { ClientConfigContext } from '../../../../lib/client-config';
import { Role } from '../../../../lib/types';

const useStyles = makeStyles(theme => ({
  filterControl: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

const IterationFilter: React.FC<{
  state: StrictFilterState<PeriodsInput>;
  onChange: (value: StrictFilterState<PeriodsInput>) => void;
  periods: Period[];
  user: User;
}> = ({ state, onChange, periods, user }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    config: { iterationLabelFormat, name, featureFlags },
  } = useContext(ClientConfigContext);

  return (
    <Grid container>
      {periods.length > 0 && (
        <Grid item xs={6} sm={4} md={3}>
          <FormControl variant="outlined" className={classes.filterControl}>
            <Typography variant="subtitle2" color="textSecondary">
              {t(`fields.iteration.iteration.${name}`)}
            </Typography>
            <Select
              input={<BootstrapInput />}
              value={
                state.startsAt && state.endsBefore
                  ? iterationDatesToKey({
                      startsAt: state.startsAt,
                      endsBefore: state.endsBefore,
                    })
                  : ''
              }
              onChange={e => {
                const selectedIteration = iterationKeyToDates(
                  String(e.target.value),
                );
                onChange({
                  ...state,
                  ...selectedIteration,
                });
              }}
            >
              {iterationsToOptions(periods, iterationLabelFormat, t).map(
                (period, index) => (
                  <MenuItem key={index} value={period.value}>
                    {period.label}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </Grid>
      )}
      {(user.role == Role.SUPER_USER ||
        featureFlags.includes('CAN_FILTER_ITERATIONS_BY_REPORT')) && (
        <Grid item xs={6} sm={4} md={3}>
          <FormControl variant="outlined" className={classes.filterControl}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('fields.iteration.hasReport')}
            </Typography>
            <Select
              input={<BootstrapInput />}
              value={
                state.hasReport === undefined ? '' : String(state.hasReport)
              }
              onChange={e => {
                onChange({
                  ...state,
                  hasReport:
                    String(e.target.value) == 'true'
                      ? true
                      : String(e.target.value) == 'false'
                      ? false
                      : undefined,
                });
              }}
            >
              <MenuItem value="true">{t('fields.iteration.yes')}</MenuItem>
              <MenuItem value="false">{t('fields.iteration.no')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};
export default IterationFilter;
