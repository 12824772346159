import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import {
  Button,
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  btn: {
    textTransform: 'capitalize',
    marginTop: '10px',
  },
  instructions: {
    '& li': {
      marginBottom: '10px',
    },
  },
  alert: {
    marginTop: '10px',
  },
});

const Info: React.FC<Record<string, unknown>> = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Alert severity="info" className={classes.alert}>
        <Typography variant="body2">
          {t('components.fb-onboarding.instagram-disclaimer')}
        </Typography>
        <Button
          className={classes.btn}
          onClick={handleClickOpen}
          variant="outlined"
          color="primary"
        >
          {t('components.fb-onboarding.instagram-instructions-button')}
        </Button>
      </Alert>
      <Dialog
        onClose={handleClose}
        aria-labelledby="ig-dialog-title"
        open={open}
      >
        <DialogTitle id="ig-dialog-title">
          {t('components.fb-onboarding.instagram-instructions-dialog-title')}
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body2" gutterBottom>
            {t('components.fb-onboarding.instagram-instructions-dialog-intro')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <ol className={classes.instructions}>
              <li>
                {t('components.fb-onboarding.instagram-instructions-step-1')}
              </li>
              <li>
                {t('components.fb-onboarding.instagram-instructions-step-2')}
              </li>
              <li>
                {t('components.fb-onboarding.instagram-instructions-step-3')}
              </li>
              <li>
                {t('components.fb-onboarding.instagram-instructions-step-4')}
              </li>
            </ol>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Info;
