import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../../components/TextInput/TextInput';
import { NotificationsContext } from '../../lib/use-notifications';
import usePasswordChangeReducer from './state';
import { useIsPasswordPwned } from '../../lib/use-is-password-pwned';
import { PasswordBreachedWarning } from './PasswordBreachedWarning';
import useFormField from '../../lib/use-form-field';
import { useGqlClient } from '../../lib/graphql/use-gql-client';

type Props = {
  user: {
    id: number;
  };
};

const useStyles = makeStyles(theme => ({
  heading: {
    textAlign: 'center',
    marginBottom: `${theme.spacing(3)}px`,
  },
  button: {
    minWidth: 200,
    height: '3rem',
  },
  mb: {
    marginBottom: '1.5rem',
  },
}));

export const ChangePasswordView: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const gqlClient = useGqlClient();

  const [loading, setLoading] = useState(false);
  const { addNotification } = useContext(NotificationsContext);

  const [state, dispatch] = usePasswordChangeReducer();
  const { timesSeen, fetching } = useIsPasswordPwned(state.newPassword.value);

  const newPasswordField = useFormField(
    state.newPassword.value,
    state.newPassword.error,
    pw =>
      dispatch({
        type: 'SET_NEW_PASSWORD',
        value: pw,
      }),
  );

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.preventDefault();

    if (!state.newPassword.error && !state.passwordConfirmation.error) {
      setLoading(true);

      try {
        await gqlClient.changePassword({
          input: {
            userId: user.id,
            password: state.newPassword.value,
            passwordConfirmation: state.passwordConfirmation.value,
          },
        });

        dispatch({
          type: 'RESET_INPUT',
        });

        addNotification({
          message: t('components.change-password.success'),
          variant: 'success',
        });
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
  };

  return (
    <Container>
      <Box mt={10}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={10} sm={6} md={5} lg={4}>
            <Box mb={5}>
              <Typography
                component="h1"
                variant="h4"
                className={classes.heading}
              >
                {t('components.change-password.title')}
              </Typography>
            </Box>

            <form noValidate onSubmit={handleSubmit}>
              <TextInput
                label={t('components.change-password.labels.old-password')}
                value={state.oldPassword.value}
                error={!!state.oldPassword.error}
                helperText={state.oldPassword.error}
                type="password"
                onChange={e =>
                  dispatch({
                    type: 'SET_OLD_PASSWORD',
                    value: e.target.value,
                  })
                }
                onBlur={e =>
                  dispatch({
                    type: 'SET_OLD_PASSWORD',
                    value: e.target.value,
                  })
                }
              />
              <TextInput
                label={t('components.change-password.labels.new-password')}
                type="password"
                {...newPasswordField}
              />
              <TextInput
                label={t(
                  'components.change-password.labels.password-confirmation',
                )}
                type="password"
                value={state.passwordConfirmation.value}
                className={classes.mb}
                error={!!state.passwordConfirmation.error}
                helperText={state.passwordConfirmation.error}
                onChange={e =>
                  dispatch({
                    type: 'SET_PASSWORD_CONFIRMATION ',
                    value: e.target.value,
                  })
                }
                onBlur={e =>
                  dispatch({
                    type: 'SET_PASSWORD_CONFIRMATION ',
                    value: e.target.value,
                  })
                }
              />
              {!fetching && timesSeen && !state.newPassword.error ? (
                <PasswordBreachedWarning found={timesSeen} />
              ) : null}
              <Button
                variant="contained"
                type="submit"
                className={classes.button}
                color="secondary"
                disabled={
                  !state.oldPassword.value ||
                  !state.newPassword.value ||
                  !state.passwordConfirmation.value ||
                  !!state.oldPassword.error ||
                  !!state.newPassword.error ||
                  !!state.passwordConfirmation.error
                }
                fullWidth
              >
                {loading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  t('components.change-password.submit')
                )}
              </Button>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
