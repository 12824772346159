import { Button, makeStyles, Typography } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Card, { CardStatus } from './Card';
import roundFbLogo from './round-fb-logo.png';

const useFbButtonStyles = makeStyles({
  button: {
    color: 'white',
    background: '#1877f2',
    borderWidth: 0,
    borderRadius: 4,
    margin: 5,
    textTransform: 'none',
    height: 40,
    fontFamily: 'Helvetica, Arial, sans-serif',
    letterSpacing: 0.25,
    fontSize: 16,
    fontWeight: 'bold',
    paddingRight: 12,

    '&:hover': {
      background: '#1877f2',
    },

    '& img': {
      height: 24,
      width: 24,
      margin: 8,
    },
  },
});

const FacebookButton: React.FC<ButtonProps> = props => {
  const classes = useFbButtonStyles();

  return <Button {...props} className={classes.button} />;
};

type Props = {
  login: () => void;
  logout: () => void;
  name?: string;
  status?: CardStatus;
};

const Connect: React.FC<Props> = ({
  login,
  logout,
  name = undefined,
  status,
}) => {
  const { t } = useTranslation();

  return (
    <Card
      title={t('components.fb-onboarding.login-step-title')}
      status={status}
    >
      {name && (
        <Typography variant="body1">
          {t('components.fb-onboarding.you-are-logged-in-as', { name })}
        </Typography>
      )}
      {name && (
        <FacebookButton onClick={logout}>
          <img src={roundFbLogo} alt="" />
          {t('components.fb-onboarding.logout')}
        </FacebookButton>
      )}
      {!name && (
        <FacebookButton onClick={login}>
          <img src={roundFbLogo} alt="" />
          {t('components.fb-onboarding.login')}
        </FacebookButton>
      )}
    </Card>
  );
};

export default Connect;
