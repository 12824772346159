import { InstagramBusinessAccount } from '../use-facebook';
import {
  makeStyles,
  Box,
  Card,
  CardHeader,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  container: {
    padding: '0 25px',
  },
  label: {
    marginBottom: '5px',
  },
  avatar: {
    borderRadius: '50%',
    height: '40px',
  },
  loadingContainer: {
    width: '100%',
    textAlign: 'center',
  },
});

const IgAccount: React.FC<{
  account?: InstagramBusinessAccount;
  loading: boolean;
}> = ({ account, loading }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress size={40}></CircularProgress>
        </div>
      )}
      {account && !loading && (
        <Box className={classes.container}>
          <Typography variant="body2" className={classes.label}>
            {t('components.fb-onboarding.your-linked-ig-account')}
          </Typography>
          <Card>
            <CardHeader
              avatar={
                <img
                  alt="instagram profile picture"
                  className={classes.avatar}
                  src={account.profile_picture_url}
                ></img>
              }
              title={account.name}
              subheader={account.username}
            />
          </Card>
        </Box>
      )}
    </>
  );
};

export default IgAccount;
