import React, { useCallback, useContext } from 'react';
import { useDropzone, DropEvent } from 'react-dropzone';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { NotificationsContext } from '../../../../lib/use-notifications';
import { DROPZONE_MAX_FILE_SIZE_IN_KB } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

interface Props {
  handleDrop: (files: any) => void;
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    background: '#F8F8F8',
    padding: theme.spacing(2),
  },
  image: {
    width: '100%',
    height: '100%',
    maxHeight: 150,
    objectFit: 'cover',
    cursor: 'pointer',
  },
  imageWrapper: {
    margin: 4,
  },
  paper: {
    padding: 4,
    // paddingBottom: 0,
    height: '100%',
    width: '100%',
    boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.8);',
    transition: 'all 0.3s ease-in',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  upload: {
    background: 'white',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    borderRadius: 5,
    padding: '16px 8px',
    '&:focus': {
      outline: 'none',
    },
  },
}));

const Dropzone: React.FC<Props> = ({ handleDrop }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { addNotification } = useContext(NotificationsContext);

  const onDropAccepted = useCallback(
    acceptedFiles => {
      if (handleDrop) {
        handleDrop(acceptedFiles);
      }
    },
    [handleDrop],
  );

  const onDropRejected = (files: any[], event: DropEvent) => {
    const message = `Invalid file format or file is too large. Max size allowed is ${Math.round(
      DROPZONE_MAX_FILE_SIZE_IN_KB / 1024,
    )}MB`;
    addNotification({
      message: message,
      variant: 'error',
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: [
      //"text/csv",
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    maxSize: DROPZONE_MAX_FILE_SIZE_IN_KB * 1024,
  });

  return (
    <div className={classes.wrapper}>
      <Box>
        <div {...getRootProps()} className={classes.upload}>
          <input {...getInputProps()} />
          <Box
            display="flex"
            width="50%"
            margin="0 auto"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <CloudUploadIcon style={{ fontSize: 40 }}></CloudUploadIcon>
            {isDragActive ? (
              <Typography>{t('components.dropzone.dropHere')}</Typography>
            ) : (
              <Typography>{t('components.importFromFile.upload')}</Typography>
            )}
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default Dropzone;
