import { makeStyles, Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Card, { CardStatus } from './Card';

const useStyles = makeStyles({
  button: {
    height: 40,
  },
});

type Props = {
  onConfirm: () => void;
  status: CardStatus;
};

const Confirm: React.FC<Props> = ({ onConfirm, status }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card
      title={t('components.fb-onboarding.confirm-step-title')}
      status={status}
    >
      <Button
        color="secondary"
        variant="contained"
        className={classes.button}
        onClick={onConfirm}
      >
        {t('components.fb-onboarding.confirm')}
      </Button>
    </Card>
  );
};

export default Confirm;
