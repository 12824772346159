import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from './Card';

const useStyles = makeStyles({
  title: {
    width: '100%',
    textAlign: 'center',
  },
  closePage: {
    marginTop: 30,
  },
});

type Props = Record<string, unknown>;

const Done: React.FC<Props> = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card
      title={
        <div className={classes.title}>
          {t('components.fb-onboarding.thank-you-title')}
        </div>
      }
    >
      <Typography variant="body1">
        {t('components.fb-onboarding.thank-you-body')}
      </Typography>
    </Card>
  );
};

export default Done;
