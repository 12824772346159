import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import { SendPdfReport, SendPdfReportVariables } from '../../../../schema';

const SEND_PDF_REPORT = gql`
  mutation SendPdfReport($input: IterationsInput!) {
    sendPdfReports(input: $input) {
      message
    }
  }
`;

export function useSendPdfReportMutation() {
  return useDirectMutation<SendPdfReport, SendPdfReportVariables>(
    SEND_PDF_REPORT,
  );
}
