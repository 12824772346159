import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, Box, Badge, Button, Grid } from '@material-ui/core';
import { Iterations_iterations_iterations as Iteration } from '../../../../schema';
import InteractiveButton from '../../../../components/InteractiveButton/InteractiveButton';
import { NotificationsContext } from '../../../../lib/use-notifications';
import { Permission } from '../../../../lib/types';
import { useGqlClient } from '../../../../lib/graphql/use-gql-client';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '../../../../lib/util';
import * as _ from 'lodash';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  actions: {
    background: 'white',
    padding: theme.spacing(2.5),
  },
  actionButton: {
    padding: '0 20px 0 0',
    '& button': {
      textTransform: 'capitalize',
      boxShadow: 'none',
      height: 56,
    },
  },
  filterControl: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  simpleTable: {
    width: '100%',
    borderCollapse: 'collapse',
    '& tr': {
      borderBottom: '1px solid #777',
    },
    '& th': {
      textAlign: 'left',
    },
    '& td': {
      padding: '3px',
    },
  },
  reportPeriod: {
    marginTop: 20,
    marginLeft: 15,
  },
}));

const IterationAction: React.FC<{
  iterations: Iteration[];
  title: string;
  confirm: string;
  alertMsg?: string;
  submit: (iterations: Iteration[]) => Promise<void>;
  done: () => Promise<void> | void;
  disabled: boolean;
}> = ({ iterations, title, confirm, submit, done, alertMsg, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { addNotification } = useContext(NotificationsContext);

  return (
    <span className={classes.actionButton}>
      <Badge
        badgeContent={iterations.length > 1 ? iterations.length : null}
        color="secondary"
      >
        <InteractiveButton
          disabled={disabled}
          variant="outlined"
          color="secondary"
          label={title}
          confirm={{
            title,
            content: (
              <>
                {alertMsg && <Alert severity="info">{alertMsg}</Alert>}
                <hr />
                <div>{confirm}</div>
                <br />
                <table className={classes.simpleTable}>
                  <tr>
                    <th>{t('components.iterationActions.storeId')}</th>
                    <th>{t('components.iterationActions.storeName')}</th>
                    <th>{t('components.iterationActions.period')}</th>
                  </tr>
                  {iterations.map((iteration, index) => (
                    <tr key={index}>
                      <td>{iteration.store.remoteId}</td>
                      <td>{iteration.store.companyName}</td>
                      <td>{`${iteration.startsAt} ${iteration.endsBefore}`}</td>
                    </tr>
                  ))}
                </table>
              </>
            ),
            submit: {
              onClick: async () => {
                try {
                  await submit(iterations);
                  addNotification({
                    message: t('components.iterationActions.success'),
                    variant: 'success',
                  });
                } catch (e) {
                  console.log(e);
                } finally {
                  await done();
                }
              },
            },
          }}
        />
      </Badge>
    </span>
  );
};

interface IterationActionsProps {
  selectedIterations: Iteration[];
  actionDone: () => Promise<void> | void;
  unselectAll: () => void;
}

const IterationActions: React.FC<IterationActionsProps> = ({
  selectedIterations,
  actionDone,
  unselectAll,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const gqlClient = useGqlClient();
  const { addNotification } = useContext(NotificationsContext);

  const getEligibleIterations = () => {
    return {
      createFbPageTask: selectedIterations.filter(iteration =>
        iteration.permissions.includes(
          Permission.ITERATION_CREATE_FB_PAGE_TASK,
        ),
      ),
      getPdfReport: selectedIterations.filter(iteration =>
        iteration.permissions.includes(Permission.ITERATION_GET_REPORT),
      ),
      sendPdfReport: selectedIterations.filter(iteration =>
        iteration.permissions.includes(Permission.ITERATION_SEND_REPORT),
      ),
      generatePdfReport: selectedIterations.filter(iteration =>
        iteration.permissions.includes(Permission.ITERATION_GENERATE_REPORT),
      ),
    };
  };

  const [actionToEligibleIterations, setActionToEligibleIterations] = useState(
    getEligibleIterations(),
  );

  useEffect(() => {
    setActionToEligibleIterations(getEligibleIterations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIterations]);

  const [busy, setBusy] = useState(false);

  const doAction = async (actionFn: () => Promise<void>) => {
    setBusy(true);
    try {
      await actionFn();
      setBusy(false);
    } catch (e) {
      setBusy(false);
      throw e;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Box className={classes.actions}>
          {/**
           * ITERATION ACTIONS
           */}
          {actionToEligibleIterations.createFbPageTask.length > 0 && (
            <IterationAction
              disabled={busy}
              iterations={actionToEligibleIterations.createFbPageTask}
              title={t('components.iterationActions.createFbPageTask')}
              confirm={t('components.iterationActions.confirm')}
              submit={async (iterations: Iteration[]) => {
                await doAction(async () => {
                  await gqlClient.createFbPageTasks({
                    input: {
                      iterations: iterations.map(it => it.id),
                    },
                  });
                });
              }}
              done={actionDone}
            ></IterationAction>
          )}

          {actionToEligibleIterations.generatePdfReport.length > 0 && (
            <IterationAction
              disabled={busy}
              iterations={actionToEligibleIterations.generatePdfReport}
              title={t('components.iterationActions.generateReport')}
              confirm={t('components.iterationActions.confirm')}
              submit={async (iterations: Iteration[]) => {
                await doAction(async () => {
                  await gqlClient.generateIterationReports({
                    input: {
                      iterations: iterations.map(it => it.id),
                    },
                  });
                });
              }}
              done={actionDone}
            ></IterationAction>
          )}

          {actionToEligibleIterations.getPdfReport.length > 0 && (
            <IterationAction
              disabled={busy}
              iterations={actionToEligibleIterations.getPdfReport}
              title={t('components.iterationActions.getPdfReport')}
              confirm={t('components.iterationActions.confirm')}
              submit={async (iterations: Iteration[]) => {
                await doAction(async () => {
                  let path = '';
                  if (iterations.length > 1) {
                    path = `reports/iteration-report-archive?iterationIds=${iterations
                      .map(iteration => iteration.id)
                      .join(',')}`;
                  } else {
                    const iteration = iterations[0];
                    path = `reports/iteration-report-pdf/${iteration.id}`;
                  }
                  try {
                    await downloadFile(path);
                  } catch (e) {
                    console.log(e);
                    addNotification({
                      message: e.message,
                      variant: 'error',
                    });
                  }
                });
              }}
              done={actionDone}
              alertMsg={
                actionToEligibleIterations.getPdfReport.length > 1
                  ? t('components.iterationActions.getPdfReportDisclaimer')
                  : undefined
              }
            ></IterationAction>
          )}

          {actionToEligibleIterations.sendPdfReport.length > 0 && (
            <IterationAction
              disabled={busy}
              iterations={actionToEligibleIterations.sendPdfReport}
              title={t('components.iterationActions.sendPdfReport')}
              confirm={t('components.iterationActions.confirm')}
              submit={async (iterations: Iteration[]) => {
                await doAction(async () => {
                  await gqlClient.sendPdfReport({
                    input: {
                      iterations: iterations.map(it => it.id),
                    },
                  });
                });
              }}
              done={actionDone}
            ></IterationAction>
          )}

          <span className={classes.actionButton}>
            <Button
              disabled={busy}
              type="button"
              variant="contained"
              color="primary"
              onClick={unselectAll}
            >
              {t('components.iterationActions.unselectAll')}
            </Button>
          </span>
        </Box>
      </Grid>
    </Grid>
  );
};
export default IterationActions;
