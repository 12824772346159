import React, { useState } from 'react';
import {
  Grid,
  TableRow,
  TableCell,
  makeStyles,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Tasks, Tasks_tasks_tasks as Task, TasksInput } from '../../../schema';
import * as _ from 'lodash';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';
import RemoteDataTable, {
  StrictFilterState,
} from '../../../components/RemoteDataTable/RemoteDataTable';
import TaskFilter from './components/TaskFilter';
import { useGetTasksQuery } from '../../../lib/graphql/resolvers/queries/tasks';
import { DatePicker } from '@material-ui/pickers';
import { DateType } from '@date-io/type';
import dayjs from 'dayjs';
import localCache from '../../../lib/local-cache';
import { useGetOperatorsQuery } from '../../../lib/graphql/resolvers/queries/operators';
import FullscreenLoading from '../../Layout/FullscreenLoading';
import { GetCurrentUser_currentUser as User } from '../../../schema';

const useStyles = makeStyles(theme => ({
  table: {},
  actionButton: {
    margin: 5,
  },
  button: {
    padding: '10px 15px',
    margin: '0 ' + theme.spacing(1) + 'px',
    boxShadow: 'none',
    height: 56,
    textTransform: 'capitalize',
  },
  label: { fontSize: '16px', lineHeight: '60px', paddingRight: '20px' },
  downloadReportButton: {
    textTransform: 'capitalize',
    boxShadow: 'none',
    marginRight: 10,
    '& button': {
      textTransform: 'capitalize',
      boxShadow: 'none',
    },
  },
}));

const TaskListView: React.FC<{
  user: User;
}> = ({ user }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const CACHED_FILTER_FROM_KEY = 'taskListFilterFrom';
  const CACHED_FILTER_TO_KEY = 'taskListFilterTo';

  const cachedFilterFrom = localCache.getPersistentObject<string>(
    CACHED_FILTER_FROM_KEY,
  );
  const cachedFilterTo = localCache.getPersistentObject<string>(
    CACHED_FILTER_TO_KEY,
  );

  const [filterFrom, setFilterFrom] = useState<DateType | null>(
    cachedFilterFrom ? dayjs(cachedFilterFrom) : null,
  );
  const [filterTo, setFilterTo] = useState<DateType | null>(
    cachedFilterTo ? dayjs(cachedFilterTo) : null,
  );

  /** not passing yearMonths options on purpose so as not to render period select (for this view using
   * from and to external filters) */
  const filterFactory = (
    state: StrictFilterState<TasksInput>,
    onChange: (value: StrictFilterState<TasksInput>) => void,
  ) => (
    <TaskFilter
      state={state}
      onChange={onChange}
      operators={data ? data.operators : []}
    />
  );

  const { data, error, loading } = useGetOperatorsQuery();
  if (loading) {
    return <FullscreenLoading />;
  }

  if (error) {
    return <h1>{String(error)}</h1>;
  }

  return (
    <Grid>
      <Typography variant="body2" color="error" style={{ padding: '10px' }}>
        NOTA: se non viene impostato il filtro Da / A oppure le date non
        selezionate non fanno parte della stessa iterazione vengono mostrati i
        dati relativi all&apos;iterazione corrente
      </Typography>
      <Grid item xs={12} md={12}>
        <RemoteDataTable
          cacheId="tasks-list"
          searchable={true}
          renderHeaderRow={() => (
            <TableRow>
              <TableCell component="th">
                {t('components.taskList.storeId')}
              </TableCell>
              <TableCell component="th">
                {t('components.taskList.storeName')}
              </TableCell>
              <TableCell component="th">
                {t('components.taskList.created')}
              </TableCell>
              <TableCell component="th">
                {t('components.taskList.completed')}
              </TableCell>
              <TableCell component="th">
                {t('components.taskList.status')}
              </TableCell>
              <TableCell component="th">
                {t('components.taskList.type')}
              </TableCell>
              <TableCell component="th">
                {t('components.taskList.assignedTo')}
              </TableCell>
              <TableCell component="th" padding="checkbox"></TableCell>
            </TableRow>
          )}
          renderDataRow={(task: Task) => (
            <TableRow key={task.id}>
              <TableCell>{task.iteration.store.remoteId}</TableCell>
              <TableCell>{task.iteration.store.companyName}</TableCell>
              <TableCell>{task.targetCreatedAt}</TableCell>
              <TableCell>{task.completedAt}</TableCell>
              <TableCell>{t(`enums.TaskStatus.${task.status}`)}</TableCell>
              <TableCell>{t(`enums.TaskType.${task.type}`)}</TableCell>
              <TableCell>
                {task.iteration.store.operator
                  ? task.iteration.store.operator.email
                  : ''}
              </TableCell>
              <TableCell padding="checkbox">
                <Link
                  onClick={() => {
                    // filter task list per store with the iteration of selected task
                    const cachedFilter = localCache.getPersistentObject(
                      'per-store-tasks-list',
                    );
                    localCache.persistObject('per-store-tasks-list', {
                      ...(typeof cachedFilter === 'object' ? cachedFilter : {}),
                      iterationStartsAt: task.iteration.startsAt,
                      iterationEndsBefore: task.iteration.endsBefore,
                    });
                  }}
                  to={`/tasks/${task.iteration.store.remoteId}/${task.type}`}
                >
                  <IconButton>
                    <ExitToAppIcon />
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
          )}
          useQuery={useGetTasksQuery}
          parseData={(data: Tasks) => data.tasks.tasks}
          parseFilteredCount={(data: Tasks) => data.tasks.filteredCount}
          filter={{
            emptyState: {
              year: '',
              month: '',
              type: '',
              status: '',
              operatorId: '',
            },
            factory: filterFactory,
          }}
          upperRightHeader={
            <>
              <Typography variant="body2" className={classes.label}>
                {t('components.taskList.period')}
              </Typography>
              <DatePicker
                autoOk
                label={t('components.taskList.from')}
                clearable
                value={filterFrom}
                onChange={date => {
                  setFilterFrom(date);
                  localCache.persistObject(
                    CACHED_FILTER_FROM_KEY,
                    date ? date.format() : null,
                  );
                }}
                format="DD/MM/YYYY"
                placeholder={dayjs().format('DD/MM/YYYY')}
                maxDate={filterTo ? filterTo : undefined}
              />
              <DatePicker
                autoOk
                label={t('components.taskList.to')}
                clearable
                value={filterTo}
                onChange={date => {
                  setFilterTo(date);
                  localCache.persistObject(
                    CACHED_FILTER_TO_KEY,
                    date ? date.format() : null,
                  );
                }}
                format="DD/MM/YYYY"
                placeholder={dayjs().format('DD/MM/YYYY')}
                minDate={filterFrom ? filterFrom : undefined}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  setFilterFrom(null);
                  setFilterTo(null);
                  localCache.persistObject(CACHED_FILTER_FROM_KEY, null);
                  localCache.persistObject(CACHED_FILTER_TO_KEY, null);
                }}
              >
                {t('components.taskList.clear')}
              </Button>
            </>
          }
          externalFilter={
            filterFrom && filterTo
              ? {
                  from: filterFrom.format(),
                  to: filterTo.format(),
                }
              : undefined
          }
        />
      </Grid>
    </Grid>
  );
};
export default TaskListView;
