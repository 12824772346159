import { useDirectQuery } from '../../graphql';
import gql from 'graphql-tag';
import {
  ScheduledPublishingTargets,
  ScheduledPublishingTargetsVariables,
} from '../../../../schema';
import { useQuery } from 'react-apollo';

const SCHEDULED_PUBLISHING_TARGETS = gql`
  query ScheduledPublishingTargets($input: ScheduledPublishingTargetsInput!) {
    scheduledPublishingTargets(input: $input) {
      targets {
        startsAt
        endsBefore
        target
      }
    }
  }
`;

export function useGetScheduledPublishingTargetsDirectQuery() {
  return useDirectQuery<
    ScheduledPublishingTargets,
    ScheduledPublishingTargetsVariables
  >(SCHEDULED_PUBLISHING_TARGETS);
}

export function useGetScheduledPublishingTargetsQuery(
  variables: ScheduledPublishingTargetsVariables,
) {
  return useQuery<
    ScheduledPublishingTargets,
    ScheduledPublishingTargetsVariables
  >(SCHEDULED_PUBLISHING_TARGETS, {
    variables,
    fetchPolicy: 'network-only',
  });
}
