import React from 'react';
import { Dashboard } from './state';
import { makeStyles } from '@material-ui/core';
import { DashboardSectionText } from '../../components/DashboardSectionText/DashboardSectionText';
import { useTranslation } from 'react-i18next';
import DashboardMetricBox from '../../components/DashboardMetrixBox/DashboardMetricBox';
import { DashboardStoreList } from '../../components/DashboardStoreList/DashboardStoreList';
import { DashboardBarChart } from '../../components/DashboardBarChart/DashboardBarChart';
import {
  Dashboard_dashboard_scores_avg,
  Dashboard_dashboard_scores_bestStores,
  Dashboard_dashboard_scores_worstStores,
  Dashboard_dashboard_scores_distribution,
} from '../../schema';

type Props = {
  metrics: Dashboard['scores'];
};

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    border: 'none',
    '& td': {
      verticalAlign: 'middle',
      textAlign: 'center',
    },
  },
  legendBox: {
    height: 10,
    width: 10,
    display: 'inline-block',
    marginRight: 5,
  },
  legendLabel: {
    fontSize: 10,
  },
}));

export const ScoreSection: React.FC<Props> = ({ metrics }) => {
  const { t } = useTranslation();

  const scoreLabelWidth = 200;
  const scoreMetricWidth = 100;

  const classes = useStyles();

  const scoreKeys: Array<keyof Dashboard_dashboard_scores_avg> = [
    'usage',
    'engagement',
    'brandCommunicationQuality',
    'responsiveness',
    'overall',
  ];

  const getTrStyle = (scoreKey: typeof scoreKeys[number]) => {
    if (scoreKey === 'overall') {
      return {
        borderTop: '1px solid red',
      };
    }
    return {};
  };

  return (
    <>
      {/* let's use a raw html table, the easiest way to properly align and arrange items as requested*/}
      <table
        className={classes.container}
        cellSpacing="0"
        cellPadding="0"
        style={{ marginBottom: 30 }}
      >
        <tr>
          <td></td>
          <td>
            <DashboardSectionText
              color="active"
              text={t('components.dashboard.average-score').toString()}
              width={scoreLabelWidth}
            />
          </td>
          <td>
            <DashboardSectionText
              color="active"
              text={t('components.dashboard.top-stores').toString()}
              width={scoreLabelWidth}
            />
          </td>
          <td>
            <DashboardSectionText
              color="active"
              text={t('components.dashboard.stores-to-improve').toString()}
              width={scoreLabelWidth}
            />
          </td>
          <td>
            <DashboardSectionText
              color="active"
              text={t('components.dashboard.distribution').toString()}
              width={scoreLabelWidth + 140}
            />
          </td>
        </tr>
        {scoreKeys.map((scoreKey, index) => (
          <tr key={index}>
            <td style={getTrStyle(scoreKey)}>
              <DashboardSectionText
                color="active"
                text={t(`components.dashboard.${scoreKey}`).toString()}
                width={scoreLabelWidth}
              />
            </td>
            <td style={getTrStyle(scoreKey)}>
              <DashboardMetricBox
                value={
                  (metrics.avg[
                    scoreKey
                  ] as Dashboard_dashboard_scores_avg['usage']).num
                }
                width={scoreMetricWidth}
                change={
                  (metrics.avg[
                    scoreKey
                  ] as Dashboard_dashboard_scores_avg['usage']).change
                }
              />
            </td>
            <td style={getTrStyle(scoreKey)}>
              <DashboardStoreList
                items={
                  metrics.bestStores[
                    scoreKey
                  ] as Dashboard_dashboard_scores_bestStores['usage']
                }
              />
            </td>
            <td style={getTrStyle(scoreKey)}>
              <DashboardStoreList
                items={
                  metrics.worstStores[
                    scoreKey
                  ] as Dashboard_dashboard_scores_worstStores['usage']
                }
              />
            </td>
            <td style={getTrStyle(scoreKey)}>
              <DashboardBarChart
                data={(metrics.distribution[
                  scoreKey
                ] as Dashboard_dashboard_scores_distribution['usage']).map(
                  e => ({
                    comparedIterationScore: e.refVal,
                    selectedIterationScore: e.val,
                    label: e.label,
                  }),
                )}
              />
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={4} style={{ fontSize: 10 }}>
            {t(`components.dashboard.comparison-disclaimer`)}
          </td>
          <td>
            <div>
              <div
                className={classes.legendBox}
                style={{ background: '#e60000' }}
              ></div>
              <span className={classes.legendLabel}>
                {t('components.dashboard.iteration-analyzed')}
              </span>
              &nbsp;&nbsp;&nbsp;
              <div
                className={classes.legendBox}
                style={{ background: 'grey' }}
              ></div>
              <span className={classes.legendLabel}>
                {t('components.dashboard.iteration-to-compare')}
              </span>
            </div>
          </td>
        </tr>
      </table>
    </>
  );
};
