import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  header: {
    padding: 5,
    fontWeight: 700,
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  content: {
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'center',
    position: 'relative', // for disabledOverlay position:absolute
  },
  disabledOverlay: {
    position: 'absolute',
    cursor: 'wait',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9999,
  },
}));

export type CardStatus = 'normal' | 'hidden' | 'disabled' | 'loading';

type Props = {
  title: React.ReactNode;
  status?: CardStatus;
};

const Card: React.FC<Props> = ({ title, children, status = 'normal' }) => {
  const classes = useStyles();

  return (
    <Box bgcolor="#FBFBFF" borderRadius={5} m={1} mt={3}>
      <Box bgcolor="#F7F7FF" borderRadius={'5px 5px 0 0'}>
        <div className={classes.header}>{title}</div>
      </Box>
      {status !== 'hidden' && (
        <Box className={classes.content} p={2}>
          {status === 'loading' ? <CircularProgress size={40} /> : children}
          {status === 'disabled' && <div className={classes.disabledOverlay} />}
        </Box>
      )}
    </Box>
  );
};

export default Card;
