import React from 'react';
import { makeStyles } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { TasksPerStore_tasks_tasks_media as Media } from '../../schema';
import { MediaType } from '../../lib/types';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

const useStyles = makeStyles({
  postImage: {
    width: '100%',
  },
  postImageContainer: {
    position: 'relative',
  },
  videoThumbnailCover: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > div': {
      textAlign: 'center',
    },
  },
  videoThumbnailIcon: {
    color: '#fff',
    fontSize: 80,
  },
});

const PostImage: React.FC<{
  image: Media | null;
  previewUrl: string | null;
}> = ({ image, previewUrl }) => {
  const classes = useStyles();
  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (previewUrl) {
      window.open(
        previewUrl,
        '_blank',
        `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,width=600,height=700`,
      ); // need to specify height too
    }
  };

  return (
    <>
      <a href="#" onClick={onClick}>
        {!image && (
          <img
            src={'https://via.placeholder.com/400x600?text=Generating'}
            alt="Post Image"
            className={classes.postImage}
          />
        )}
        {image && (
          <div className={classes.postImageContainer}>
            <LazyLoadImage
              src={image.url}
              alt="Post Image"
              className={classes.postImage}
            />
            {image.type == MediaType.VIDEO_THUMBNAIL && (
              <div className={classes.videoThumbnailCover}>
                <div>
                  <PlayCircleOutlineIcon
                    className={classes.videoThumbnailIcon}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </a>
    </>
  );
};

export default PostImage;
