import React, { ReactNode } from 'react';
import clsx from 'clsx';

import TextField, { FilledTextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      borderRadius: 5,
      width: '100%',
    },
    textFieldError: {
      borderColor: theme.palette.error.main,
    },
    root: {
      background: '#F8F8F8',
      borderRadius: 5,
    },
    input: {
      borderColor: '#2e4056',
      padding: '22px 14px 19px',
      color: '#2E4056',
    },
    error: {
      marginRight: 5,
    },
    inputLabel: {
      transform: 'translate(14px, 6px) scale(0.75) !important',
    },
    disabled: {
      background: '#F8F8F8 !important',
      cursor: 'not-allowed',
    },
    disabledLabel: {
      color: 'rgba(0, 0, 0, 0.54) !important',
    },
    multiLine: {
      '& .MuiInputBase-root': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
      },
    },
    startAdornment: {
      marginTop: '0 !important',
      marginRight: -8,
      fontSize: 17,
    },
  }),
);

type Props = Omit<FilledTextFieldProps, 'variant'> & {
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
};

const TextInput: React.FC<Props> = ({
  className,
  endAdornment,
  startAdornment,
  ...props
}) => {
  const classes = useStyles();
  return (
    <TextField
      className={clsx(
        classes.textField,
        className,
        props.multiline && classes.multiLine,
      )}
      margin="normal"
      variant="filled"
      {...props}
      InputProps={{
        disableUnderline: !props.error,
        classes: {
          root: classes.root,
          input: classes.input,
          disabled: classes.disabled,
        },
        startAdornment: startAdornment && (
          <InputAdornment
            position="start"
            component="span"
            disableTypography={true}
            className={classes.startAdornment}
          >
            {startAdornment && startAdornment}
          </InputAdornment>
        ),
        endAdornment: (endAdornment || props.error) && (
          <InputAdornment position="end">
            {props.error && (
              <ErrorIcon className={classes.error} color="error" />
            )}
            {endAdornment && endAdornment}
          </InputAdornment>
        ),
        ...props.InputProps,
      }}
      InputLabelProps={{
        classes: {
          shrink: classes.inputLabel,
          disabled: classes.disabledLabel,
        },
        ...props.InputLabelProps,
      }}
    />
  );
};

export default TextInput;
