import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import { DisableStore, DisableStoreVariables } from '../../../../schema';

const DISABLE_STORE = gql`
  mutation DisableStore($input: DisableStoresInput!) {
    disableStores(input: $input) {
      stores {
        id
      }
    }
  }
`;

export function useDisableStoreMutation() {
  return useDirectMutation<DisableStore, DisableStoreVariables>(DISABLE_STORE);
}
