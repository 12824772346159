import React, { useState, useContext } from 'react';
import { makeStyles, Button, Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LaunchIcon from '@material-ui/icons/Launch';
import UpdateIcon from '@material-ui/icons/Update';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import InteractiveButton from '../../components/InteractiveButton/InteractiveButton';
import { useGqlClient } from '../../lib/graphql/use-gql-client';
import { TasksPerStore_tasks_tasks_media as Media } from '../../schema';
import { useDropzone, DropEvent } from 'react-dropzone';
import { DROPZONE_MAX_FILE_SIZE_IN_KB } from '../../constants';
import { NotificationsContext } from '../../lib/use-notifications';

const useStyles = makeStyles(theme => ({
  pageImage: {
    width: '100%',
  },
  upload: {
    background: '#ddd',
    maxWidth: 300,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    borderRadius: 5,
    padding: '16px 8px',
    '&:focus': {
      outline: 'none',
    },
  },
}));

const FacebookPageImage: React.FC<{
  image: Media | null;
  previewUrl: string | null;
  canUpdate: boolean;
  taskId?: number;
}> = ({ image, previewUrl, canUpdate, taskId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { addNotification } = useContext(NotificationsContext);
  const gqlClient = useGqlClient();
  // for fb page tasks if locale file does not exist yet image has not been generated yet
  const [imageUrl, setImageUrl] = useState(
    image && image.isLocalFile ? image.url : null,
  );
  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (previewUrl) {
      window.open(previewUrl, '_blank');
    }
  };

  const [uploading, setUploading] = useState(false);

  const onDropRejected = (files: any[], event: DropEvent) => {
    const message = `The file is too large. Max size allowed is ${Math.round(
      DROPZONE_MAX_FILE_SIZE_IN_KB / 1024,
    )}MB`;
    addNotification({
      message: message,
      variant: 'error',
    });
  };

  const onDropAccepted = (files: File[]) => {
    if (files.length != 1) {
      addNotification({
        message: 'Please upload one file only',
        variant: 'error',
      });
      return;
    }
    const file = files[0];
    const reader = new FileReader();
    reader.onload = async () => {
      setUploading(true);
      const binaryStr = reader.result;
      if (typeof binaryStr === 'string') {
        const base64encoded = btoa(binaryStr);
        try {
          if (taskId) {
            // actually if this is executed taskId exists
            const res = await gqlClient.uploadFbPageTaskImage({
              input: {
                taskId,
                name: file.name,
                contents: base64encoded,
                mimeType: file.type,
              },
            });
            if (res.uploadFbPageTaskImage.media) {
              if (res.uploadFbPageTaskImage.media.isLocalFile) {
                setImageUrl(res.uploadFbPageTaskImage.media.url);
              }
            }
            addNotification({
              message: t('components.facebook-page-image.upload-success'),
              variant: 'success',
            });
          }
        } catch (e) {
          let message = 'Something went wrong';
          if (e instanceof Error) {
            message = e.message;
          }

          addNotification({
            variant: 'error',
            message,
          });
        }
      } else {
        console.error(
          `FileReader result typeis ${typeof binaryStr}, expected string`,
        );
      }
      setUploading(false);
    };

    reader.readAsBinaryString(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: 'image/jpeg, image/jpg, image/png, image/gif',
    maxSize: DROPZONE_MAX_FILE_SIZE_IN_KB * 1024,
  });

  return (
    <>
      <a href="#" onClick={onClick}>
        {!imageUrl && (
          <img
            src={'https://via.placeholder.com/1030x370?text=Generating'}
            alt="Facebook page"
            className={classes.pageImage}
          />
        )}
        {imageUrl && (
          <LazyLoadImage
            src={imageUrl}
            alt="Facebook page image"
            className={classes.pageImage}
          />
        )}
      </a>
      <Box alignItems="flex"></Box>
      <Button
        color="primary"
        onClick={onClick}
        endIcon={<LaunchIcon></LaunchIcon>}
      >
        {t('postPreview.viewOriginal')}
      </Button>
      {canUpdate && taskId && (
        <>
          <InteractiveButton
            label={t('postPreview.update')}
            color="primary"
            onClick={async () => {
              try {
                const res = await gqlClient.updateFbPageTaskImage({
                  taskId,
                });
                if (res.updateFbPageTaskImage.media) {
                  if (res.updateFbPageTaskImage.media.isLocalFile) {
                    setImageUrl(res.updateFbPageTaskImage.media.url);
                  }
                }
              } catch (e) {
                console.log(e);
              }
            }}
            endIcon={<UpdateIcon></UpdateIcon>}
          />
          <Box>
            <div {...getRootProps()} className={classes.upload}>
              <input {...getInputProps()} />
              <Box
                display="flex"
                width="50%"
                margin="0 auto"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
              >
                {uploading && (
                  <Typography>
                    {t('components.facebook-page-image.uploading')}
                  </Typography>
                )}
                {!uploading && (
                  <>
                    {isDragActive ? (
                      <Typography>Drop the files here ...</Typography>
                    ) : (
                      <Typography>
                        {t('components.facebook-page-image.upload')}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </div>
          </Box>
        </>
      )}
    </>
  );
};

export default FacebookPageImage;
