import { useDirectQuery } from '../../graphql';
import gql from 'graphql-tag';
import { TasksPerStore, TasksPerStoreVariables } from '../../../../schema';
import { useQuery } from 'react-apollo';

const GET_TASKS = gql`
  query TasksPerStore($input: TasksInput!) {
    tasks(input: $input) {
      tasks {
        id
        type
        status
        iterationId
        iteration {
          id
          storeId
          startsAt
          endsBefore
          store {
            id
            remoteId
            companyName
            operator {
              id
              email
            }
          }
        }
        createdAt
        targetCreatedAt
        updatedAt
        completedAt
        completer {
          id
          name
        }
        media {
          type
          url
          isLocalFile
        }
        previewUrl
        message
        isVendorPost
        data {
          post {
            compliancyIssues
            streams
          }
          page {
            coverImageNotInLine
            profileImageNotInLine
            callToActionNotInLine
          }
          gmbReview {
            isCompliant
          }
        }
        reviewDetails {
          comment
          reply: review_reply {
            comment
            update_time
          }
          reviewer {
            displayName: display_name
            photo: profile_photo_url
          }
          rating: star_rating
          createdAt: create_time
        }
        targetId
        flags
        permissions
        availableIssues {
          text
          image
        }
      }
      filteredCount
    }
  }
`;

export function useGetTasksPerStoreDirectQuery() {
  return useDirectQuery<TasksPerStore, TasksPerStoreVariables>(GET_TASKS);
}

export function useGetTasksPerStoreQuery(variables: TasksPerStoreVariables) {
  return useQuery<TasksPerStore, TasksPerStoreVariables>(GET_TASKS, {
    variables,
    fetchPolicy: 'network-only',
  });
}
