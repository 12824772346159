import React, { useState, useContext, useEffect } from 'react';
import InteractiveButton from '../../../../components/InteractiveButton/InteractiveButton';
import XLSX from 'xlsx';
import { NotificationsContext } from '../../../../lib/use-notifications';
import Alert from '@material-ui/lab/Alert';
import Dropzone from './Dropzone';
import { useGqlClient } from '../../../../lib/graphql/use-gql-client';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {
  UpsertStoreInput,
  EvaluateImport_evaluateImport as ImportInfo,
} from '../../../../schema';
import { useStoreInputValidate } from '../../../../lib/validators/upsert-store-input.validator';
import { ClientConfigContext } from '../../../../lib/client-config';
import fileDownload from 'js-file-download';

type InvalidRow = {
  row: number;
  invalidFields: string[];
};

const useStyles = makeStyles(theme => ({
  actionButton: {
    '& button': {
      textTransform: 'capitalize',
      boxShadow: 'none',
      height: 56,
      marginLeft: 10,
    },
  },
}));

const ImportFromFile: React.FC<{
  onClick: () => void;
  done: () => void;
}> = ({ onClick, done }) => {
  const { t } = useTranslation();
  const gqlClient = useGqlClient();
  const { addNotification } = useContext(NotificationsContext);
  const [fileImportedData, setFileImportedData] = useState<
    UpsertStoreInput[] | null
  >(null);
  const [importInfo, setImportInfo] = useState<ImportInfo | null>(null);
  const classes = useStyles();
  const [invalidRows, setInvalidRows] = useState<InvalidRow[] | null>(null);
  const {
    config: { editableStoreFields },
  } = useContext(ClientConfigContext);
  const validate = useStoreInputValidate();

  const handleDrop = async (files: Blob[]) => {
    try {
      const data = (
        await Promise.all(
          files.map(
            file =>
              new Promise<UpsertStoreInput[]>((resolve, reject) => {
                const reader = new FileReader();
                reader.onabort = () =>
                  reject(new Error('file reading was aborted'));
                reader.onerror = () =>
                  reject(new Error('file reading has failed'));
                reader.onload = ev => {
                  let fileData: UpsertStoreInput[] = [];
                  if (ev.target && ev.target.result) {
                    try {
                      const data = new Uint8Array(ev.target.result as any);
                      const wb = XLSX.read(data, { type: 'array' });
                      fileData = XLSX.utils.sheet_to_json(
                        wb.Sheets[wb.SheetNames[0]],
                      );
                    } catch (e) {
                      reject(e);
                    }
                  }
                  resolve(fileData);
                };
                reader.readAsArrayBuffer(file);
              }),
          ),
        )
      ).flatMap(d => d);
      data.forEach(d => {
        // force null values for empty cells (mandatory for backend validation to work as expected)
        const row = (d as unknown) as Record<string, unknown>;
        Object.keys(row).forEach(k => {
          const val = String(row[k]);
          if (val.length == 0) {
            row[k] = null;
          }
        });
      });
      // validate imported data
      const invalidImportedRows: InvalidRow[] = [];
      data.forEach((d, index) => {
        const rowValidation = validate(d);
        const rowInvalidFields: string[] = [];
        for (const field in rowValidation) {
          const fieldErrors = rowValidation[field as keyof UpsertStoreInput];
          if (fieldErrors != null && fieldErrors.length) {
            rowInvalidFields.push(`${field}: ${fieldErrors.join(', ')}`);
          }
        }
        if (rowInvalidFields.length) {
          invalidImportedRows.push({
            row: index + 1,
            invalidFields: rowInvalidFields,
          });
        }
      });
      setFileImportedData(data);
      setInvalidRows(invalidImportedRows);
    } catch (e) {
      setFileImportedData(null);
      setImportInfo(null);
      setInvalidRows(null);
      addNotification({
        message: e.message,
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (
      fileImportedData &&
      fileImportedData.length &&
      invalidRows &&
      invalidRows.length == 0
    ) {
      // evaluate import
      setImportInfo(null);
      gqlClient
        .evaluateImport({
          input: {
            stores: fileImportedData,
          },
        })
        .then(res => {
          setImportInfo(res.evaluateImport);
        })
        .catch(e => {
          setFileImportedData(null);
          setImportInfo(null);
          setInvalidRows(null);
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileImportedData, invalidRows]);

  return (
    <InteractiveButton
      className={classes.actionButton}
      label={t('components.importFromFile.buttonLabel')}
      variant="contained"
      color="secondary"
      size="large"
      confirm={{
        beforeDisplay: onClick,
        title: t('components.importFromFile.import'),
        content: (
          <>
            {/**
             * No file imported
             */}
            {fileImportedData == null && (
              <>
                <Dropzone handleDrop={handleDrop} />
                <Alert severity="info">
                  {t('components.importFromFile.instructions')}
                  <ul>
                    <li>
                      <b>remoteId</b>
                    </li>
                    <li>
                      <b>companyName</b>
                    </li>
                    <li>facebookPage</li>
                    <li>googleMybusinessLocation</li>
                    {editableStoreFields.map((field, index) => (
                      <li key={index}>
                        {field.required && <b>{field.name}</b>}
                        {!field.required && <>{field.name}</>}
                      </li>
                    ))}
                  </ul>
                </Alert>
              </>
            )}
            {/**
             * File imported
             */}
            {fileImportedData != null && (
              <>
                {/**
                 * no data to import found
                 */}
                {fileImportedData.length == 0 && (
                  <Alert severity="warning">
                    {t('components.importFromFile.noData')}
                  </Alert>
                )}
                {/**
                 * found at least one row to import
                 */}
                {fileImportedData.length > 0 && (
                  <>
                    {/**
                     * rows validated, at least one invalid row found
                     */}
                    {invalidRows != null && invalidRows.length > 0 && (
                      <>
                        <Alert severity="warning">
                          {t('components.importFromFile.invalidRowsWarning')}
                        </Alert>
                        <List>
                          {invalidRows.map((invalidRow, rowIndex) => (
                            <ListItem key={rowIndex}>
                              <ListItemText
                                primary={t(
                                  'components.importFromFile.rowNumber',
                                  {
                                    num: invalidRow.row,
                                  },
                                )}
                                secondary={
                                  <>
                                    {invalidRow.invalidFields.map(
                                      (invalidField, fieldIndex) => (
                                        <div key={fieldIndex}>
                                          {invalidField}
                                        </div>
                                      ),
                                    )}
                                  </>
                                }
                              ></ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </>
                    )}
                    {/**
                     * rows validated, all rows are valid
                     */}
                    {invalidRows != null && invalidRows.length == 0 && (
                      <>
                        {/**
                         * evaluating import
                         */}
                        {importInfo == null && (
                          <div style={{ textAlign: 'center' }}>
                            <CircularProgress size={60}></CircularProgress>
                            <div>
                              {t(
                                'components.importFromFile.evaluatingDataToImport',
                              )}
                            </div>
                          </div>
                        )}
                        {/**
                         * import evaluted
                         */}
                        {importInfo != null && (
                          <>
                            {/**
                             * stores to be updated with one or more channels changed
                             */}
                            {importInfo.channelsUpdated.length > 0 && (
                              <>
                                <Typography color="secondary" variant="h6">
                                  {t(
                                    'components.importFromFile.storesWithChannelsToBeUpdated',
                                  )}
                                </Typography>
                                <Alert severity="warning">
                                  {t(
                                    'components.importFromFile.storesWithChannelsToBeUpdatedDetailedInfo',
                                  )}
                                </Alert>
                                <List>
                                  {importInfo.channelsUpdated.map(
                                    (store, index) => (
                                      <ListItem key={index}>
                                        <ListItemText
                                          primary={store.remoteId}
                                          secondary={store.companyName}
                                        ></ListItemText>
                                      </ListItem>
                                    ),
                                  )}
                                </List>
                              </>
                            )}
                            {/**
                             * stores to be updated with no channel change
                             */}
                            {importInfo.created.length > 0 && (
                              <>
                                <Typography color="secondary" variant="h6">
                                  {t(
                                    'components.importFromFile.storesToBeCreated',
                                  )}
                                </Typography>
                                <List>
                                  {importInfo.created.map((store, index) => (
                                    <ListItem key={index}>
                                      <ListItemText
                                        primary={store.remoteId}
                                        secondary={store.companyName}
                                      ></ListItemText>
                                    </ListItem>
                                  ))}
                                </List>
                              </>
                            )}
                            {/**
                             * stores to be created
                             */}
                            {importInfo.detailsUpdated.length > 0 && (
                              <>
                                <Typography color="secondary" variant="h6">
                                  {t(
                                    'components.importFromFile.storesWithDetailsToBeUpdated',
                                  )}
                                </Typography>
                                <List>
                                  {importInfo.detailsUpdated.map(
                                    (store, index) => (
                                      <ListItem key={index}>
                                        <ListItemText
                                          primary={store.remoteId}
                                          secondary={store.companyName}
                                        ></ListItemText>
                                      </ListItem>
                                    ),
                                  )}
                                </List>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ),
        submit: {
          label: t('components.storeList.import'),
          disabled:
            fileImportedData == null ||
            fileImportedData.length == 0 ||
            invalidRows == null ||
            invalidRows.length > 0,
          onClick: async () => {
            console.log(fileImportedData);
            try {
              await gqlClient.importStores({
                input: {
                  stores: (fileImportedData as unknown) as UpsertStoreInput[],
                },
              });
              addNotification({
                message: t('components.importFromFile.importDone'),
                variant: 'success',
              });
              done();
            } catch (e) {
              console.log(e);
            } finally {
              // reset for next usage
              setFileImportedData(null);
              setImportInfo(null);
              setInvalidRows(null);
            }
          },
        },
        abort: {
          onClick: () => {
            // important: reset for next usage
            setFileImportedData(null);
            setImportInfo(null);
            setInvalidRows(null);
          },
        },
        extraActions: [
          {
            label: 'Sample XLS',
            onClick: () => {
              const cols = [
                'remoteId',
                'companyName',
                'facebookPage',
                'googleMybusinessLocation',
              ];
              editableStoreFields.forEach(field => cols.push(field.name));
              const ws = XLSX.utils.aoa_to_sheet([cols]);
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, 'Import Data');
              fileDownload(
                XLSX.write(wb, { type: 'array', bookType: 'xlsx' }),
                'sample.xlsx',
              );
            },
          },
        ],
      }}
    />
  );
};
export default ImportFromFile;
