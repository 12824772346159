import React, { ReactElement, useState, useEffect, useContext } from 'react';
import {
  TextField,
  Box,
  Button,
  Table,
  TableBody,
  TableHead,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { LIST_ITEMS_PER_PAGE } from '../../constants';
import FullscreenLoading from '../../views/Layout/FullscreenLoading';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import localCache from '../../lib/local-cache';
import { PaginationDisplay } from '../Pagination/PaginationDisplay';
import { Alert } from '@material-ui/lab';
import { AppTheme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { QueryResult } from 'react-apollo';
import { FullScreenError } from '../FullScreenError/FullScreenError';
import stableStringify from 'json-stable-stringify';
import { NotificationsContext } from '../../lib/use-notifications';

export const useStyles = (props: { containerMinHeight?: number }) =>
  makeStyles<AppTheme>(theme => ({
    container: {},
    actions: {
      background: 'white',
      padding: theme.spacing(2.5),
      display: 'flex',
      justifyContent: 'flex-start',
    },
    button: {
      textTransform: 'capitalize',
      padding: '10px 15px',
      margin: '0 ' + theme.spacing(1) + 'px',
      boxShadow: 'none',
      height: 56,
    },
    dataTableWrapper: {
      position: 'relative',
      minHeight: props.containerMinHeight || 600,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      background: '#fff',
    },
    table: {
      marginTop: theme.spacing(2),
    },
    tableHeader: {
      '& th': {
        position: 'sticky',
        background: theme.palette.highlight.main,
        color: theme.palette.highlight.contrastText,
        top: 0,
        zIndex: 1,
        fontWeight: 800,
      },
      '& .MuiIconButton-label': {
        color: '#fff',
      },
    },
  }));

type FilterState = {
  take: number;
  skip?: number;
  search?: string;
} & Record<string, unknown>;

export type StrictFilterState<T> = Omit<T, 'take' | 'skip' | 'search'>;

export type OnFilterChange<T> = (value: StrictFilterState<T>) => void;

// https://dev.to/janjakubnanista/a-peculiar-journey-to-a-generic-react-component-using-typescript-3cm8

type RemoteDataTableProps<T, V extends FilterState, K, S> = {
  cacheId: string;
  searchable: boolean;
  filter?: {
    emptyState: Record<keyof StrictFilterState<V>, '' | []>; // null values should not be used, using empty strings
    factory: (
      state: StrictFilterState<V>,
      onChange: OnFilterChange<V>,
    ) => ReactElement<{
      state: StrictFilterState<V>;
      onChange: OnFilterChange<V>;
    }>;
  };
  renderHeaderRow?: () => JSX.Element;
  renderDataRow: (t: T) => JSX.Element;
  useQuery: (variables: S) => QueryResult<K, S>;
  refreshKey?: string; // when need to refresh list from outside pass a random string to trigger refresh (couldn't find any better solution unless using useImperativeRef which is considered bad design)
  upperLeftHeader?: JSX.Element;
  upperRightHeader?: JSX.Element;
  parseData: (data: K) => T[];
  parseFilteredCount: (data: K) => number;
  onDataChanged?: (data: T[]) => void;
  externalFilter?: Record<keyof V, unknown>;
  externalQueryArgs?: Record<string, unknown>;
  pageSize?: number;
  overwriteCachedFilter?: (filter: Record<string, unknown>) => void;
  containerMinHeight?: number;
};

export default function RemoteDataTable<T, V extends FilterState, K, S>(
  props: RemoteDataTableProps<T, V, K, S>,
) {
  const { t } = useTranslation();
  const {
    cacheId,
    searchable,
    filter,
    renderHeaderRow,
    renderDataRow,
    refreshKey,
    upperLeftHeader,
    upperRightHeader,
    useQuery,
    parseData,
    parseFilteredCount,
    onDataChanged,
    externalFilter,
    externalQueryArgs,
    pageSize,
    overwriteCachedFilter,
    containerMinHeight,
  } = props;
  const classes = useStyles({ containerMinHeight })();
  const [skip, setSkip] = useState(0);
  const { addNotification } = useContext(NotificationsContext);
  const cachedFilterValue = localCache.getPersistentObject<V>(cacheId);
  if (cachedFilterValue && overwriteCachedFilter) {
    overwriteCachedFilter(cachedFilterValue);
  }
  const [filterValue, setFilterValue] = useState<V>({
    ...(filter
      ? cachedFilterValue
        ? cachedFilterValue
        : filter.emptyState
      : {}),
    skip: 0,
    take: pageSize || LIST_ITEMS_PER_PAGE,
  } as V);

  const removeEmptyKeys = (
    o: Record<string, unknown>,
  ): Record<string, unknown> => {
    const cleanValue: Record<string, unknown> = {};
    Object.entries(o).forEach(entry => {
      if (String(entry[1]).length) {
        cleanValue[entry[0]] = entry[1];
      }
    });
    return cleanValue;
  };

  const hasCachedFilter = () => {
    if (cachedFilterValue) {
      const cleanCachedFilter = removeEmptyKeys(cachedFilterValue);
      delete cleanCachedFilter['skip'];
      delete cleanCachedFilter['take'];
      delete cleanCachedFilter['search'];
      return Object.keys(cleanCachedFilter).length > 0;
    }
    return false;
  };

  const [searchValue, setSearchValue] = useState<string>(
    cachedFilterValue && cachedFilterValue.search
      ? cachedFilterValue.search
      : '',
  );
  const [filtersOpen, setFiltersOpen] = useState(hasCachedFilter());
  const { loading, data, error, refetch } = useQuery(({
    input: {
      ...removeEmptyKeys(filterValue),
      skip,
      take: pageSize || LIST_ITEMS_PER_PAGE,
      ...(externalFilter ? removeEmptyKeys(externalFilter) : undefined),
    },
    ...externalQueryArgs,
  } as unknown) as S);

  let searchTimeoutId: NodeJS.Timeout | undefined;
  const onSearchValueChanged = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newSerchValue = String(e.target.value);
    setSearchValue(newSerchValue);
    if (searchTimeoutId) {
      clearTimeout(searchTimeoutId);
    }
    const newFilterValue = {
      ...filterValue,
      search: newSerchValue,
    };
    localCache.persistObject<V>(cacheId, newFilterValue);
    searchTimeoutId = setTimeout(() => setFilterValue(newFilterValue), 800);
  };
  const resetFilter = () => {
    localCache.persistObject<V>(cacheId, null);
    setFilterValue({
      ...(filter ? filter.emptyState : {}),
      search: '', // reset search too
      take: pageSize || LIST_ITEMS_PER_PAGE,
      skip,
    } as V);
    setSearchValue(''); // reset on ui accordingly
  };

  useEffect(() => {
    localCache.persistObject<V>(cacheId, filterValue);
    setSkip(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stableStringify(filterValue)]); // need to stringify to correctly detect changes

  useEffect(() => {
    setSkip(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stableStringify(externalFilter)]); // need to stringify to correctly detect changes

  useEffect(() => {
    // when refreshing do NOT reset skip
    refetch().catch(e => {
      let message = 'Something went wrong';
      if (e instanceof Error) {
        message = e.message;
      }
      addNotification({ variant: 'error', message });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  useEffect(() => {
    refetch().catch(e => {
      let message = 'Something went wrong';
      if (e instanceof Error) {
        message = e.message;
      }
      addNotification({ variant: 'error', message });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  useEffect(() => {
    if (onDataChanged) {
      onDataChanged(data ? parseData(data) : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) {
    return <FullScreenError error={error} />;
  }

  return (
    <>
      <Grid container style={{ background: '#fff' }} justify={'space-between'}>
        {upperLeftHeader && (
          <Grid
            item
            xs={12}
            md={upperRightHeader ? 4 : 6}
            className={classes.actions}
          >
            {upperLeftHeader}
          </Grid>
        )}
        <Grid
          item
          className={classes.actions}
          xs={12}
          md={upperLeftHeader && upperRightHeader ? 4 : 6}
        >
          {searchable && (
            <TextField
              variant="outlined"
              label={'Search'}
              style={{
                minWidth: upperLeftHeader && upperRightHeader ? 100 : 400,
              }}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              value={searchValue}
              onChange={onSearchValueChanged}
            />
          )}
          {filter && (
            <>
              <Button
                className={classes.button}
                variant="outlined"
                size="large"
                startIcon={<FilterListIcon />}
                onClick={() => setFiltersOpen(!filtersOpen)}
              >
                {t('components.remoteDataTable.filter')}
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                size="large"
                color="primary"
                onClick={resetFilter}
              >
                {t('components.remoteDataTable.resetFilter')}
              </Button>
            </>
          )}
        </Grid>
        {upperRightHeader && (
          <Grid
            item
            xs={12}
            md={upperLeftHeader ? 4 : 6}
            className={classes.actions}
          >
            {upperRightHeader}
          </Grid>
        )}
      </Grid>
      {filtersOpen && filter && (
        <Grid item xs={12} className={classes.actions}>
          <Box borderTop="1px solid silver" flexGrow={1} pt={2}>
            {filter.factory(filterValue, val =>
              setFilterValue({
                ...filterValue,
                ...val,
              }),
            )}
          </Box>
        </Grid>
      )}

      <Box p={2.5} className={classes.dataTableWrapper}>
        {loading && <FullscreenLoading />}
        <Table className={classes.table} aria-label="data table">
          {renderHeaderRow && (
            <TableHead className={classes.tableHeader}>
              {renderHeaderRow()}
            </TableHead>
          )}
          {data && (
            <TableBody>
              {parseData(data).map(row => renderDataRow(row))}
            </TableBody>
          )}
        </Table>
        {data && parseData(data).length == 0 && !loading && (
          <Alert severity="warning">
            {t('components.remoteDataTable.noData')}
          </Alert>
        )}
        {data && parseData(data).length > 0 && (
          <PaginationDisplay
            currentPage={skip / (pageSize || LIST_ITEMS_PER_PAGE)}
            maxPerPage={pageSize || LIST_ITEMS_PER_PAGE}
            totalRecords={parseFilteredCount(data)}
            onChangePage={(page: number) => {
              setSkip(page * (pageSize || LIST_ITEMS_PER_PAGE));
            }}
          />
        )}
      </Box>
    </>
  );
}
