import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import { EvaluateImport, EvaluateImportVariables } from '../../../../schema';

// this should be a query since no changes are performed but declared as mutation for simplicity

const EVALUATE_IMPORT = gql`
  mutation EvaluateImport($input: ImportStoresInput!) {
    evaluateImport(input: $input) {
      created {
        remoteId
        companyName
      }
      detailsUpdated {
        remoteId
        companyName
      }
      channelsUpdated {
        remoteId
        companyName
      }
    }
  }
`;

export function useEvaluateImportMutation() {
  return useDirectMutation<EvaluateImport, EvaluateImportVariables>(
    EVALUATE_IMPORT,
  );
}
