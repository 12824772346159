export type ValidatorInput =
  | number
  | string
  | null
  | undefined
  | Record<string, unknown>;

export type Validator = (value: ValidatorInput) => string | undefined;

export function applyValidators(
  value: ValidatorInput,
  validators: Validator[],
): string[] | null {
  const validationErrors = [];
  for (const validate of validators) {
    const validationError = validate(value);
    if (validationError) {
      validationErrors.push(validationError);
    }
  }
  return validationErrors.length ? validationErrors : null;
}
