import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Button,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGqlClient } from '../../lib/graphql/use-gql-client';
import { NotificationsContext } from '../../lib/use-notifications';
import { FbIgDashboardSection } from './FbIgDashboardSection';
import { GmbDashboardSection } from './GmbDashboardSection';
import { ALL_AREAS_OPTION, ChartMetric, useDashboardReducer } from './state';
import { DashboardVariables } from '../../schema';
import { ScoreSection } from './ScoreSection';
import { downloadFile } from '../../lib/util';
import { useGetAreasQuery } from '../../lib/graphql/resolvers/queries/areas';
import { IterationPeriod } from './state';
import { ClientConfigContext } from '../../lib/client-config';
import { iterationsToOptions } from '../../lib/util';
import FullscreenLoading from '../Layout/FullscreenLoading';
import { Alert } from '@material-ui/lab';

enum DashboardExportKind {
  FULL_ITERATION = 'FULL_ITERATION',
  TASK = 'TASK',
  NON_COMPLIANT_REVIEWS = 'NON_COMPLIANT_REVIEWS',
}

export const DashboardView = (): JSX.Element => {
  const { t } = useTranslation();
  const gqlClient = useGqlClient();
  const { addNotification } = useContext(NotificationsContext);
  const [state, dispatch] = useDashboardReducer();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const getAreas = useGetAreasQuery();
  const {
    config: { iterationLabelFormat },
  } = useContext(ClientConfigContext);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    const fetchIterationList = async () => {
      const { iterationList } = await gqlClient.getYearMonthList({ input: {} });

      const options = iterationsToOptions(
        iterationList.iterations,
        iterationLabelFormat,
        t,
      ).map((d, index) => {
        return {
          label: d.label,
          startsAt: iterationList.iterations[index].startsAt,
        };
      });

      dispatch({
        type: 'SET_ITERATION_LIST',
        payload: options,
      });
    };

    fetchIterationList()
      .then(x => x)
      .catch(e => {
        let message = 'Something went wrong';
        if (e instanceof Error) {
          message = e.message;
        }
        addNotification({ variant: 'error', message });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchDashboard = async (input: DashboardVariables) => {
      const { dashboard } = await gqlClient.getDashboard(input);

      dispatch({
        type: 'SET_METRICS',
        payload: {
          isFrozen: dashboard.isFrozen,
          facebookMetrics: dashboard.facebook,
          instagramMetrics: dashboard.instagram,
          gmbMetrics: dashboard.gmb,
          scoresMetrics: dashboard.scores,
        },
      });
    };

    if (state.iterationAnalyzed && state.iterationCompared) {
      setLoading(true);
      fetchDashboard({
        input: {
          area: state.area !== ALL_AREAS_OPTION ? state.area : undefined,
          startsAt: state.iterationAnalyzed.startsAt,
          refStartsAt: state.iterationCompared.startsAt,
        },
      })
        .then(d => d)
        .catch(e => {
          let message = 'Something went wrong';
          if (e instanceof Error) {
            message = e.message;
          }
          addNotification({ variant: 'error', message });
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.area, state.iterationAnalyzed, state.iterationCompared]);

  const handleSetIterationAnalyzed = (iteration: IterationPeriod) => {
    dispatch({
      type: 'SET_ITERATION_ANALYZED',
      payload: iteration,
    });
  };

  const handleSetIterationCompared = (iteration: IterationPeriod) => {
    dispatch({
      type: 'SET_ITERATION_COMPARED',
      payload: iteration,
    });
  };

  const handleSelectMetricForIgFbChart = (
    metric: ChartMetric,
    channel: 'FACEBOOK' | 'INSTAGRAM',
  ) => {
    dispatch({
      type:
        channel === 'FACEBOOK'
          ? 'SET_FB_SELECTED_METRIC'
          : 'SET_IG_SELECTED_METRIC',
      payload: metric,
    });
  };

  const handleExportSection = async (
    startsAt: string,
    exportKind: DashboardExportKind,
  ) => {
    const path = `reports/dashboard/${startsAt}/${exportKind}`;

    try {
      await downloadFile(path);
    } catch (e) {
      let message = 'Something went wrong';

      if (e instanceof Error) {
        message = e.message;
      }

      addNotification({
        message,
        variant: 'error',
      });
    }
  };

  const areaLabelText = t('components.dashboard.area');
  const iterationAnalyzedLabelText = t(
    'components.dashboard.iteration-analyzed',
  );
  const iterationComparedLabelText = t(
    'components.dashboard.iteration-to-compare',
  );

  return (
    <Container maxWidth="xl">
      <Box display="flex" py={4} justifyContent="space-between">
        <Box width="40%" display="flex" mr={2}>
          <Box width="50%" mr={2}>
            <FormControl variant="outlined" fullWidth disabled={loading}>
              <InputLabel id="iteration-analyzed-label">
                {iterationAnalyzedLabelText}
              </InputLabel>
              <Select
                labelId="iteration-analyzed-label"
                id="iteration-analyzed-select"
                value={state.iterationAnalyzed?.startsAt || ''}
                label={iterationAnalyzedLabelText}
                onChange={e =>
                  handleSetIterationAnalyzed({
                    startsAt: String(e.target.value),
                    label: String(e.target.name),
                  })
                }
              >
                {state.iterationList.map((d, i) => (
                  <MenuItem
                    key={i}
                    value={d.startsAt}
                    disabled={d.startsAt === state.iterationCompared?.startsAt}
                  >
                    {d.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box width="50%">
            <FormControl variant="outlined" fullWidth disabled={loading}>
              <InputLabel id="iteration-compared-label">
                {iterationComparedLabelText}
              </InputLabel>
              <Select
                labelId="iteration-compared-label"
                id="iteration-compared-select"
                value={state.iterationCompared?.startsAt || ''}
                label={iterationComparedLabelText}
                onChange={e =>
                  handleSetIterationCompared({
                    startsAt: String(e.target.value),
                    label: String(e.target.name),
                  })
                }
              >
                {state.iterationList.map((d, i) => (
                  <MenuItem
                    key={i}
                    value={d.startsAt}
                    disabled={d.startsAt === state.iterationAnalyzed?.startsAt}
                  >
                    {d.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box width="50%" display="flex" justifyContent="space-between">
          <Box display="flex" width="70%">
            <Box width="100%" mr={2}>
              <FormControl variant="outlined" fullWidth disabled={loading}>
                <InputLabel id="area-label">{areaLabelText}</InputLabel>
                <Select
                  labelId="area-label"
                  id="area-select"
                  value={state.area}
                  label={areaLabelText}
                  onChange={e =>
                    dispatch({
                      type: 'SET_AREA',
                      payload: e.target.value as string,
                    })
                  }
                >
                  <MenuItem value={ALL_AREAS_OPTION}>
                    {t('components.dashboard.all-areas')}
                  </MenuItem>
                  {getAreas.data?.areas.map((d, i) => (
                    <MenuItem key={i} value={d}>
                      {d}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Button
              color="primary"
              variant="contained"
              onClick={() => dispatch({ type: 'RESET' })}
              disabled={loading}
            >
              {t('components.dashboard.reset')}
            </Button>
          </Box>
          <Button
            color="secondary"
            variant="contained"
            onClick={e => setAnchorEl(e.currentTarget)}
            disabled={!state.iterationAnalyzed || loading || exporting}
          >
            {!exporting && t('components.dashboard.export')}
            {exporting && <CircularProgress size={12}></CircularProgress>}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {Object.entries(DashboardExportKind).map(([key, value], i) => (
              <MenuItem
                disabled={loading || exporting}
                key={`${i}-${key}`}
                onClick={() => {
                  if (state.iterationAnalyzed) {
                    setExporting(true);
                    handleExportSection(state.iterationAnalyzed.startsAt, value)
                      .catch(e => {
                        let message = 'Something went wrong';
                        if (e instanceof Error) {
                          message = e.message;
                        }
                        addNotification({ variant: 'error', message });
                      })
                      .finally(() => setExporting(false));
                  } else {
                    addNotification({
                      message: 'Please select an iteration',
                      variant: 'error',
                    });
                  }
                  setAnchorEl(null);
                }}
              >
                {t(`enums.DashboardExportKind.${key}`)}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      {!state.iterationAnalyzed || !state.iterationCompared ? (
        <Box
          style={{
            textAlign: 'center',
            padding: 20,
            background: '#eee',
            borderRadius: 5,
          }}
        >
          {t('components.dashboard.no-iteration-selected')}
        </Box>
      ) : (
        <>
          {loading && <FullscreenLoading />}
          {!loading && (
            <>
              {!state.isFrozen && (
                <Alert severity="warning">
                  {t('components.dashboard.warning-live-data')}
                </Alert>
              )}
              {state.facebookMetrics && (
                <>
                  <FbIgDashboardSection
                    channel="FACEBOOK"
                    metric={state.fbSelectedMetric}
                    dashboardData={state.facebookMetrics}
                    setChartMetric={handleSelectMetricForIgFbChart}
                  />
                  <br />
                  <br />
                  <hr color="red" />
                  <br />
                  <br />
                </>
              )}
              {state.instagramMetrics && (
                <>
                  <FbIgDashboardSection
                    channel="INSTAGRAM"
                    metric={state.igSelectedMetric}
                    dashboardData={state.instagramMetrics}
                    setChartMetric={handleSelectMetricForIgFbChart}
                  />
                  <br />
                  <br />
                  <hr color="red" />
                  <br />
                  <br />
                </>
              )}
              {state.gmbMetrics && (
                <>
                  <GmbDashboardSection
                    metrics={state.gmbMetrics}
                    iterationStart={state.iterationAnalyzed.startsAt}
                    area={
                      state.area === ALL_AREAS_OPTION ? undefined : state.area
                    }
                  />
                  <br />
                  <br />
                  <hr color="red" />
                  <br />
                  <br />
                </>
              )}
              {state.scoresMetrics && (
                <ScoreSection metrics={state.scoresMetrics} />
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};
