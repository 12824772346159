import { Box } from '@material-ui/core';
import React from 'react';

type Props = {
  color: 'active' | 'white';
  text: string | number;
  width: number;
  height?: number;
};

export const DashboardSectionText: React.FC<Props> = ({
  text,
  color,
  width,
  height,
}) => {
  const bgColor = color === 'active' ? '#e60000' /*'#555759'*/ : 'white';
  const textColor = color === 'active' ? 'white' : '#555759';

  const commonStyle = { display: 'inline-block' };

  const styles =
    color === 'white'
      ? {
          ...commonStyle,
          borderRadius: '10px',
          border: '1px solid #aaa',
          boxShadow: '0 0 0 2px #fff, 0 0 0 3px #aaa',
        }
      : {
          ...commonStyle,
        };

  return (
    <Box
      m={1}
      p={1.5}
      borderRadius={4}
      bgcolor={bgColor}
      color={textColor}
      fontFamily="sans-serif"
      fontWeight="bold"
      width={width}
      style={styles}
      textAlign="center"
      height={height}
    >
      {text}
    </Box>
  );
};
