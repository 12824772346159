import { createContext, useState, useEffect, useContext } from 'react';
import { ClientConfigResponse } from './client-config-response';
import { NotificationsContext } from './use-notifications';

export type ClientConfig = {
  config: ClientConfigResponse;
  baseUri: string;
};

// default value for this context makes no sense, it should not be available (used) unless config is valid
export const ClientConfigContext = createContext<ClientConfig>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  {} as any,
);
ClientConfigContext.displayName = 'ClientConfigContext';

export function useClientConfigContextState() {
  const [loading, setLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<ClientConfig | undefined>(undefined);
  const { addNotification } = useContext(NotificationsContext);

  useEffect(() => {
    const backendUri =
      process.env.REACT_APP_BACKEND_URI || 'http://localhost:3001/';

    const backendRoot = new URL(backendUri, window.location.href).href;
    const configUri = new URL('/client', backendRoot);

    fetch(configUri.href)
      .then(res => res.json())
      .then((res: ClientConfigResponse) => {
        setConfig({
          config: res,
          baseUri: backendUri,
        });
        setLoading(false);
      })
      .catch(e => {
        let message = 'Something went wrong';
        if (e instanceof Error) {
          message = e.message;
        }
        addNotification({ variant: 'error', message });
      });
  }, [addNotification]);

  return {
    loading,
    config,
  };
}
