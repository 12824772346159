import { useDirectQuery } from '../../graphql';
import gql from 'graphql-tag';
import { Stores, StoresVariables } from '../../../../schema';
import { useQuery } from 'react-apollo';

// need to query all fields in case of edit
const GET_STORES = gql`
  query Stores($input: StoresInput!) {
    stores(input: $input) {
      stores {
        id
        status
        managerId
        remoteId
        contactName
        companyName
        area
        phone
        website
        manager {
          email
        }
        areaManager {
          email
        }
        salesDirector {
          email
        }
        salesAccount {
          email
        }
        digitalAccount {
          email
        }
        operator {
          email
        }
        gmbLocation {
          id
          gmbLocationId
          hasAccess
          startedTrackingAt
          startTrackingLastResponse
          hasAccessLastResponse
        }
        facebookPage {
          id
          facebookPageId
          hasAccess
          claimedAt
          startedTrackingAt
          instagramAccountId
          startTrackingLastResponse
          hasAccessLastResponse
        }
        locale
        country
        address
        zipCode
        city
        serviceStartedAt
        notes
        permissions
      }
      filteredCount
    }
    currentUser {
      id
      email
      role
      globalPermissions
    }
  }
`;

export function useGetStoresDirectQuery() {
  return useDirectQuery<Stores, StoresVariables>(GET_STORES);
}

export function useGetStoresQuery(variables: StoresVariables) {
  return useQuery<Stores, StoresVariables>(GET_STORES, {
    variables,
    fetchPolicy: 'network-only',
  });
}
