import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import {
  EditPageTaskIssues,
  EditPageTaskIssuesVariables,
} from '../../../../schema';

const EDIT_PAGE_TASK_ISSUES = gql`
  mutation EditPageTaskIssues($input: EditPageTaskIssuesInput!) {
    editPageTaskIssues(input: $input) {
      task {
        id
        permissions
      }
    }
  }
`;

export function useEditPageTaskIssuesMutation() {
  return useDirectMutation<EditPageTaskIssues, EditPageTaskIssuesVariables>(
    EDIT_PAGE_TASK_ISSUES,
  );
}
