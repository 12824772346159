import { Box, Container, Grid, makeStyles, Link } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { RequestReset } from './RequestReset';
import { ResetPassword } from './ResetPassword';

const useStyles = makeStyles(theme => ({
  heading: {
    textAlign: 'center',
    marginBottom: `${theme.spacing(3)}px`,
  },
  button: {
    minWidth: 200,
    height: '3rem',
  },
  input: {
    width: '100%',
    marginBottom: '1.5rem',
  },
}));

export const ResetPasswordView: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const reset = new URLSearchParams(location.search).get('reset');

  return (
    <Container>
      <Box mt={10}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={10} sm={6} md={5} lg={4}>
            {reset ? (
              <ResetPassword classes={classes} resetToken={reset} />
            ) : (
              <RequestReset classes={classes} />
            )}
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Box pt={5}>
            <Link href="/">
              {t('components.reset-password.go-back-to-login')}
            </Link>
          </Box>
        </Grid>
      </Box>
    </Container>
  );
};
