import { useState, useEffect, useRef } from 'react';

export default function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  const handler = useRef<any>();

  useEffect(() => {
    handler.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler.current);
  }, [value, delay]);

  return debouncedValue;
}
