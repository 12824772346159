import React, { useState, useContext, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Box,
  Select,
  MenuItem,
  Typography,
  FormControl,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import FullscreenLoading from '../../Layout/FullscreenLoading';
import { useGetStoreQuery } from '../../../lib/graphql/resolvers/queries/store';
import { useGetIterationQuery } from '../../../lib/graphql/resolvers/queries/iteration';
import {
  Store_store as Store,
  Store_currentUser as User,
  Iteration_iteration as FullPeriod,
} from '../../../schema';
import { CHANNEL_COLOURS } from '../../../lib/types';
import InteractiveButton from '../../../components/InteractiveButton/InteractiveButton';
import { useGqlClient } from '../../../lib/graphql/use-gql-client';
import { NotificationsContext } from '../../../lib/use-notifications';
import FbStatsBox from './components/FbStatsBox';
import GmbStatsBox from './components/GmbStatsBox';
import IgStatsBox from './components/IgStatsBox';
import { useTranslation } from 'react-i18next';
import { Permission } from '../../../lib/types';
import { downloadFile, iterationDatesToLabel } from '../../../lib/util';
import { ClientConfigContext } from '../../../lib/client-config';
import GenericBox from './components/GenericBox';

const useStyles = makeStyles(theme => ({
  actions: {
    textAlign: 'right',
  },
  statsContainer: {
    padding: 10,
  },
  fbPostTaskContainer: {
    position: 'relative',
    minHeight: 350,
  },
  gmbPostTaskContainer: {
    position: 'relative',
    minHeight: 250,
  },
  igPostTaskContainer: {
    position: 'relative',
    minHeight: 350,
  },
  reportButton: {
    textTransform: 'capitalize',
    boxShadow: 'none',
    marginRight: 10,
    height: 56,
    '& button': {
      textTransform: 'capitalize',
      boxShadow: 'none',
      height: 56,
    },
  },
  refreshStatsButton: {
    textTransform: 'capitalize',
    boxShadow: 'none',
    marginRight: 10,
    '& button': {
      textTransform: 'capitalize',
      boxShadow: 'none',
    },
  },
  storeName: {
    lineHeight: '56px',
  },
}));

type StoreViewProps = {
  store: Store;
  user: User;
};

type PreloadedIterationViewProps = {
  storeId: number;
  iterationId: number;
  setSelectedPeriodPermissions: React.Dispatch<
    React.SetStateAction<Permission[]>
  >;
};

type IterationViewProps = {
  iteration: FullPeriod;
};

const IterationView: React.FC<IterationViewProps> = ({ iteration }) => {
  const {
    config: { featureFlags },
  } = useContext(ClientConfigContext);

  const { t } = useTranslation();

  const hasRealTimeKpi = featureFlags.includes('HAS_REALTIME_KPI');

  return (
    <>
      {/** if iteration is not lifetime one and flag is enabled then display kpi */}
      {hasRealTimeKpi && iteration.id > 0 && (
        <Grid container>
          {/** strictly check for null, value 0 is a valid kpi */}
          {iteration.brandCommunicationQualityScore != null && (
            <Grid item md={3}>
              <GenericBox
                label={'Brand Communication Quality'}
                value={iteration.brandCommunicationQualityScore}
                color={'red'}
              />
            </Grid>
          )}
          {iteration.usageScore != null && (
            <Grid item md={3}>
              <GenericBox
                label={'Usage'}
                value={iteration.usageScore}
                color={'red'}
              />
            </Grid>
          )}
          {iteration.engagementScore != null && (
            <Grid item md={3}>
              <GenericBox
                label={'Engagement'}
                value={iteration.engagementScore}
                color={'red'}
              />
            </Grid>
          )}
          {iteration.responsivenessScore != null && (
            <Grid item md={3}>
              <GenericBox
                label={'Responsiveness'}
                value={iteration.responsivenessScore}
                color={'red'}
              />
            </Grid>
          )}
        </Grid>
      )}

      <Grid item xs={12}>
        {iteration.fbAggregatedStats && (
          <FbStatsBox
            title={t('components.storeView.facebookPage')}
            color={CHANNEL_COLOURS['FACEBOOK']}
            aggregatedStats={iteration.fbAggregatedStats}
            storeId={iteration.storeId}
            iterationId={iteration.id}
          />
        )}

        {iteration.igAggregatedStats && (
          <IgStatsBox
            title={t('components.storeView.igAccount')}
            color={CHANNEL_COLOURS['INSTAGRAM']}
            aggregatedStats={iteration.igAggregatedStats}
            storeId={iteration.storeId}
            iterationId={iteration.id}
          />
        )}

        {iteration.gmbAggregatedStats && (
          <GmbStatsBox
            title={t('components.storeView.gmbAccount')}
            color={CHANNEL_COLOURS['GOOGLE_MYBUSINESS']}
            reviews={
              iteration.statistics.gmb && iteration.statistics.gmb.reviews
                ? iteration.statistics.gmb.reviews
                : undefined
            }
            aggregatedStats={iteration.gmbAggregatedStats}
            storeId={iteration.storeId}
            iterationId={iteration.id}
          />
        )}
      </Grid>
    </>
  );
};

const PreloadedIterationView: React.FC<PreloadedIterationViewProps> = ({
  storeId,
  iterationId,
  setSelectedPeriodPermissions,
}) => {
  const { t } = useTranslation();
  const { loading, data, error } = useGetIterationQuery({
    storeId,
    id: iterationId,
  });

  if (loading) {
    return <FullscreenLoading />;
  }

  if (error) {
    return <h1>{String(error)}</h1>;
  }

  if (!data || !data.iteration) {
    return <h1>{t('components.iterationView.empty')}</h1>;
  }

  setSelectedPeriodPermissions(data.iteration.permissions);

  return <IterationView iteration={data.iteration} />;
};

const StoreView: React.FC<StoreViewProps> = ({ store, user }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    config: { iterationLabelFormat },
  } = useContext(ClientConfigContext);

  const [iterations, _] = useState(store.iterations);

  const gqlClient = useGqlClient();
  const { addNotification } = useContext(NotificationsContext);
  const iterationOptions = iterations.map(it => ({
    value: it.id,
    label: iterationDatesToLabel(it, iterationLabelFormat, t),
  }));
  const [selectedIteration, setSelectedIteration] = useState<
    number | undefined
  >(iterations.length ? iterations[0].id : undefined);
  useEffect(() => {
    setSelectedIterationPermissions([]); // reset while newly selected iteration is loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedIteration]);
  const [
    selectedIterationPermissions,
    setSelectedIterationPermissions,
  ] = useState<Permission[]>([]);
  const [
    iterationViewRefreshKey,
    setIterationViewRefreshKey,
  ] = useState<number>(Date.now());

  const handleSelectedPeriodChange = (
    e: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const val = e.target.value as number;
    setSelectedIteration(val);
  };

  const getReport = async () => {
    if (selectedIteration) {
      const path = `reports/iteration-report-pdf/${selectedIteration}`;
      try {
        await downloadFile(path);
      } catch (e) {
        console.log(e);
        addNotification({
          message: e.message,
          variant: 'error',
        });
      }
    }
  };

  const sendReport = async () => {
    if (selectedIteration) {
      try {
        await gqlClient.sendPdfReport({
          input: {
            iterations: [selectedIteration],
          },
        });
        addNotification({
          message: t('components.storeView.pdfReportSent'),
          variant: 'success',
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const [refreshStatisticsIteration, setRefreshStatisticsIteration] = useState<
    undefined | number
  >();

  return (
    <>
      <Grid container style={{ background: '#fff' }}>
        <Grid item xs={12}>
          <Box p={3}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6" className={classes.storeName}>
                {store.remoteId} - {store.companyName}
              </Typography>

              {iterationOptions.length > 0 && (
                <Box display="flex" justifyContent="space-between">
                  {selectedIteration && (
                    <>
                      {selectedIterationPermissions.includes(
                        Permission.ITERATION_GET_REPORT,
                      ) && (
                        <InteractiveButton
                          className={classes.reportButton}
                          variant="contained"
                          color="secondary"
                          onClick={getReport}
                          label={t('components.storeView.getPdf')}
                        />
                      )}

                      {selectedIterationPermissions.includes(
                        Permission.ITERATION_SEND_REPORT,
                      ) && (
                        <InteractiveButton
                          className={classes.reportButton}
                          variant="contained"
                          color="secondary"
                          label={t('components.storeView.sendPdf')}
                          confirm={{
                            title: t('components.storeView.sendPdfModalTitle'),
                            content: (
                              <Typography variant="body2">
                                {t('components.storeView.sendPdfModalContent', {
                                  period: iterationOptions.find(
                                    opt => opt.value == selectedIteration,
                                  )!.label,
                                })}
                              </Typography>
                            ),
                            submit: {
                              label: t('components.storeView.sendPdf'),
                              onClick: () =>
                                sendReport().catch(e => console.log(e)),
                            },
                          }}
                        />
                      )}
                    </>
                  )}
                  <FormControl variant="outlined" style={{ width: 250 }}>
                    <Select
                      value={selectedIteration ? selectedIteration : null}
                      onChange={handleSelectedPeriodChange}
                    >
                      {iterationOptions.map(opt => (
                        <MenuItem key={opt.value} value={opt.value}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        {store.permissions.includes(
          Permission.STORE_REFRESH_STATISTICS_FROM_UI,
        ) && (
          <Grid item xs={12}>
            <Box p={3}>
              <Select
                value={refreshStatisticsIteration}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setRefreshStatisticsIteration(e.target.value as number)
                }
              >
                {iterationOptions.map((opt, index) => (
                  <MenuItem key={index} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
              <InteractiveButton
                disabled={!refreshStatisticsIteration}
                className={classes.refreshStatsButton}
                variant="outlined"
                color="primary"
                label={t('components.storeView.refreshStats')}
                confirm={{
                  title: t('components.storeView.refreshStats'),
                  content: (
                    <Typography variant="body2">
                      {t('components.storeView.refreshStatsInfo')}
                    </Typography>
                  ),
                  submit: {
                    label: t('components.storeView.refreshStats'),
                    onClick: async () => {
                      if (!refreshStatisticsIteration) {
                        return;
                      }
                      try {
                        await gqlClient.refreshStoreStatistics({
                          input: {
                            id: refreshStatisticsIteration,
                          },
                        });
                        if (selectedIteration == refreshStatisticsIteration) {
                          // force refresh
                          setIterationViewRefreshKey(Date.now());
                        }
                        addNotification({
                          message: t('components.storeView.statsUpdated'),
                          variant: 'success',
                        });
                      } catch (e) {
                        console.log(e);
                      }
                    },
                  },
                }}
              />
            </Box>
          </Grid>
        )}

        {selectedIteration && (
          <PreloadedIterationView
            storeId={store.id}
            iterationId={selectedIteration}
            key={iterationViewRefreshKey}
            setSelectedPeriodPermissions={setSelectedIterationPermissions}
          />
        )}
      </Grid>
    </>
  );
};

const PreloadedStoreView: React.FC = () => {
  const { remoteId } = useParams<{ remoteId: string }>();
  const { t } = useTranslation();
  const { loading, data, error } = useGetStoreQuery({ remoteId });

  if (loading) {
    return <FullscreenLoading />;
  }

  if (error) {
    return <h1>{String(error)}</h1>;
  }

  if (!data || !data.store) {
    return <h1>{t('components.storeView.empty')}</h1>;
  }

  return <StoreView store={data.store} user={data.currentUser} />;
};

export default PreloadedStoreView;
