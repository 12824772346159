import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { TypeaheadFacebookPageWithToken } from '../../views/FbOnboarding/use-facebook';
import FacebookPageOption from './Option';

type Props = {
  className?: string;
  pages: TypeaheadFacebookPageWithToken[];
  value?: TypeaheadFacebookPageWithToken;
  setValue: (page: TypeaheadFacebookPageWithToken) => void;
};

const FacebokPageSelect: React.FC<Props> = ({
  className,
  pages,
  value,
  setValue,
}) => {
  const selectedId = value ? value.id : undefined;

  const select = (id: string) => {
    const page = pages.find(p => p.id === id);
    if (page) {
      setValue(page);
    }
  };

  return (
    <Select
      className={className}
      onChange={e => select(e.target.value as string)}
      value={selectedId}
    >
      {pages.map(p => (
        <MenuItem key={p.id} value={p.id}>
          <FacebookPageOption page={p} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default FacebokPageSelect;
