import gql from 'graphql-tag';
import { useDirectMutation } from '../../graphql';
import { LoginSso, LoginSsoVariables } from '../../../../schema';

const LOG_SSO = gql`
  mutation LoginSso($redirectUrl: String!, $type: SsoType!) {
    loginSso(input: { redirectUrl: $redirectUrl, type: $type }) {
      redirectUrl
    }
  }
`;

export function useLoginSsoMutation() {
  return useDirectMutation<LoginSso, LoginSsoVariables>(LOG_SSO);
}
