const localCache = {
  persistObject<T>(key: string, obj: T | null) {
    try {
      if (obj === null) {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, JSON.stringify(obj));
      }
    } catch (e) {
      console.log(e);
    }
  },

  getPersistentObject<T>(key: string): T | null {
    try {
      const stringifiedObj = window.localStorage.getItem(key);
      if (typeof stringifiedObj === 'string') {
        return JSON.parse(stringifiedObj);
      }
      return null;
    } catch (e) {
      return null;
    }
  },
};

export default localCache;
