import dayjs from 'dayjs';
import { DATE_FORMAT } from './constants';

export const formatDate = (date: string): string | null => {
  try {
    return dayjs(date).format(DATE_FORMAT);
  } catch (e) {
    return null;
  }
};
