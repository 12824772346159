import { useDirectQuery } from '../../graphql';
import gql from 'graphql-tag';
import { Iterations, IterationsVariables } from '../../../../schema';
import { useQuery } from 'react-apollo';

const GET_ITERATIONS = gql`
  query Iterations($input: PeriodsInput!) {
    iterations(input: $input) {
      iterations {
        id
        storeId
        startsAt
        endsBefore
        permissions
        usageScore
        brandCommunicationQualityScore
        engagementScore
        responsivenessScore
        overallScore
        reportId
        reportDate
        outdated
        store {
          id
          remoteId
          companyName
        }
      }
      filteredCount
    }
    currentUser {
      id
      email
      role
      globalPermissions
    }
  }
`;

export function useGetIterationsDirectQuery() {
  return useDirectQuery<Iterations, IterationsVariables>(GET_ITERATIONS);
}

export function useGetIterationsQuery(variables: IterationsVariables) {
  return useQuery<Iterations, IterationsVariables>(GET_ITERATIONS, {
    variables,
    fetchPolicy: 'network-only',
  });
}
