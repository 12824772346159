import React, { useState, useContext } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useGqlClient } from '../../../../lib/graphql/use-gql-client';
import TextInput from '../../../../components/TextInput/TextInput';
import {
  UpsertStoreInput,
  Operators_operators as Operator,
} from '../../../../schema';
import InteractiveButton from '../../../../components/InteractiveButton/InteractiveButton';
import { NotificationsContext } from '../../../../lib/use-notifications';
import { useStoreInputValidate } from '../../../../lib/validators/upsert-store-input.validator';
import Alert from '@material-ui/lab/Alert';
import { COUNTRIES } from '../../../../lib/countries';
import { LOCALES } from '../../../../lib/locales';
import { ClientConfigContext } from '../../../../lib/client-config';
import { ClientDependantEditableStoreField } from '../../../../lib/client-config-response';
import { DatePicker } from '@material-ui/pickers';

type FormError = { [key in keyof UpsertStoreInput]: string[] | null };

const hasErrors = (formError: FormError): boolean => {
  for (const key in formError) {
    if (Object.prototype.hasOwnProperty.call(formError, key)) {
      if ((formError as Record<string, unknown>)[key] != null) {
        return true;
      }
    }
  }
  return false;
};

const useStyles = makeStyles(theme => ({
  filterControl: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  textFieldError: {
    color: theme.palette.error.main,
    fontSize: 12,
  },
}));

interface Props {
  type: 'insert' | 'update';
  onSaved: () => Promise<void>;
  defaultFormState: UpsertStoreInput;
  open: boolean;
  onClose: () => void;
  operators: Operator[];
}

const defaultFormError = {
  remoteId: null,
  contactName: null,
  companyName: null,
  storeManagerEmail: null,
  phone: null,
  website: null,
  area: null,
  googleMybusinessLocation: null,
  areaManagerEmail: null,
  facebookPage: null,
  digitalAccountEmail: null,
  salesAccountEmail: null,
  salesDirectorEmail: null,
  operatorEmail: null,
  locale: null,
  country: null,
  address: null,
  zipCode: null,
  city: null,
  serviceStartedAt: null,
  notes: null,
};

const UpsertStore: React.FC<Props> = ({
  onSaved,
  type,
  defaultFormState,
  open,
  onClose,
  operators,
}) => {
  const classes = useStyles();
  const { addNotification } = useContext(NotificationsContext);
  const { t } = useTranslation();
  const {
    config: { storeLanguages, editableStoreFields },
  } = useContext(ClientConfigContext);

  const [formState, setFormState] = useState<UpsertStoreInput>(
    defaultFormState,
  );
  const [formError, setFormError] = useState<FormError>(defaultFormError);

  const validate = useStoreInputValidate();

  let onChangeValidationTimeout: NodeJS.Timeout;

  const onChange = (field: keyof UpsertStoreInput) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedFormState = {
        ...formState,
      };
      // force a null value for empty strings to allow backend validation to work correctly
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updatedFormState[field] = (String(e.target.value).length == 0
        ? null
        : e.target.value) as any;
      setFormState(updatedFormState);

      // use a timeout to limit the number of times validation is executed (to limit execution load)
      if (onChangeValidationTimeout) {
        clearTimeout(onChangeValidationTimeout);
      }
      onChangeValidationTimeout = setTimeout(() => {
        setFormError(validate(updatedFormState));
      }, 1000);
    };
  };

  const onBlur = (field: keyof UpsertStoreInput) => {
    return (e: React.FocusEvent<HTMLInputElement>) => {
      const updatedFormState = {
        ...formState,
      };
      updatedFormState[field] = e.target.value;
      setFormError(validate(updatedFormState));
    };
  };

  const [busy, setBusy] = useState(false);
  const gqlClient = useGqlClient();
  const useHandleSubmit = async () => {
    setBusy(true);
    try {
      if (type == 'insert') {
        await gqlClient.createStore({
          input: formState,
        });
      } else {
        await gqlClient.updateStore({
          input: formState,
        });
      }

      addNotification({
        message: t('components.upsertStoreModal.success'),
        variant: 'success',
      });
      await onSaved();
      setFormState(defaultFormState);
      setFormError(defaultFormError);
    } catch (e) {
      // nothing to do
    } finally {
      setBusy(false);
    }
  };

  const handleClose = () => {
    onClose();
    setFormState(defaultFormState);
    setFormError(defaultFormError);
  };

  const renderTextField = (
    fieldName: ClientDependantEditableStoreField,
    required: boolean,
    multiline: boolean,
  ) => (
    <FormControl component="div" className={classes.formControl}>
      <TextInput
        id={fieldName}
        label={t(`fields.store.${fieldName}`)}
        name={fieldName}
        error={Boolean(formError[fieldName])}
        helperText={formError[fieldName]}
        onChange={onChange(fieldName)}
        onBlur={onBlur(fieldName)}
        value={formState[fieldName]}
        required={required}
        multiline={multiline}
      />
    </FormControl>
  );

  const renderField = (
    fieldName: ClientDependantEditableStoreField,
    required: boolean,
  ): JSX.Element => {
    switch (fieldName) {
      case 'country':
        return (
          <FormControl variant="outlined" className={classes.filterControl}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('fields.store.country')}
            </Typography>
            <Select
              required={required}
              id="country"
              label={t('fields.store.country')}
              name="country"
              error={Boolean(formError.country)}
              onChange={e => {
                const updatedFormState = {
                  ...formState,
                  country: String(e.target.value),
                };
                setFormState(updatedFormState);
                setFormError(validate(updatedFormState));
              }}
              value={formState.country}
            >
              {COUNTRIES.map(country => (
                <MenuItem key={country.alpha3code} value={country.alpha3code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            {Boolean(formError.country) && (
              <Typography component="span" className={classes.textFieldError}>
                {formError.country}
              </Typography>
            )}
          </FormControl>
        );
      case 'locale':
        return (
          <FormControl variant="outlined" className={classes.filterControl}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('fields.store.locale')}
            </Typography>
            <Select
              required={required}
              id="locale"
              label={t('fields.store.locale')}
              name="locale"
              error={Boolean(formError.locale)}
              onChange={e => {
                const updatedFormState = {
                  ...formState,
                  locale: String(e.target.value),
                };
                setFormState(updatedFormState);
                setFormError(validate(updatedFormState));
              }}
              value={formState.locale}
            >
              {storeLanguages.map(language => (
                <MenuItem key={language.locale} value={language.locale}>
                  {LOCALES[language.locale]}
                </MenuItem>
              ))}
            </Select>
            {Boolean(formError.locale) && (
              <Typography component="span" className={classes.textFieldError}>
                {formError.locale}
              </Typography>
            )}
          </FormControl>
        );
      case 'operatorEmail':
        return (
          <>
            {operators.length > 0 && (
              <FormControl variant="outlined" className={classes.filterControl}>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('fields.store.operatorEmail')}
                </Typography>
                <Select
                  required={required}
                  id="operatorEmail"
                  label={t('fields.store.operatorEmail')}
                  name="operatorEmail"
                  error={Boolean(formError.operatorEmail)}
                  onChange={e => {
                    const updatedFormState = {
                      ...formState,
                      operatorEmail: String(e.target.value),
                    };
                    setFormState(updatedFormState);
                    setFormError(validate(updatedFormState));
                  }}
                  value={formState.operatorEmail}
                >
                  <MenuItem value={''}>&nbsp;</MenuItem>
                  {operators.map(operator => (
                    <MenuItem key={operator.id} value={String(operator.email)}>
                      {operator.email}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(formError.operatorEmail) && (
                  <Typography
                    component="span"
                    className={classes.textFieldError}
                  >
                    {formError.operatorEmail}
                  </Typography>
                )}
              </FormControl>
            )}
          </>
        );
      case 'serviceStartedAt':
        return (
          <FormControl>
            <DatePicker
              required={required}
              autoOk
              label={t('fields.store.serviceStartedAt')}
              clearable
              value={
                formState.serviceStartedAt
                  ? formState.serviceStartedAt
                  : null /**force null if undefined, otherwise current date is set */
              }
              defaultValue={null}
              onChange={date => {
                const updatedFormState = {
                  ...formState,
                  serviceStartedAt: date ? date.format() : null,
                };
                setFormState(updatedFormState);
                setFormError(validate(updatedFormState));
              }}
              format="YYYY-MM-DD"
              placeholder={'date'}
              error={Boolean(formError.serviceStartedAt)}
            />
            {Boolean(formError.serviceStartedAt) && (
              <Typography component="span" className={classes.textFieldError}>
                {formError.serviceStartedAt}
              </Typography>
            )}
          </FormControl>
        );
      case 'notes':
        return renderTextField(fieldName, required, true);
      default:
        return renderTextField(fieldName, required, false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {type == 'insert'
            ? t('components.upsertStoreModal.createStore')
            : t('components.upsertStoreModal.editStore')}
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <TextInput
              id="remoteId"
              label={t('fields.store.remoteId')}
              name="remoteId"
              autoComplete="123456789"
              autoFocus
              error={Boolean(formError.remoteId)}
              helperText={formError.remoteId}
              onChange={onChange('remoteId')}
              onBlur={onBlur('remoteId')}
              value={formState.remoteId}
              required={true}
              InputProps={{
                readOnly: type == 'insert' ? false : true,
              }}
            />

            <TextInput
              id="companyName"
              label={t('fields.store.companyName')}
              name="companyName"
              autoComplete="my company"
              error={Boolean(formError.companyName)}
              helperText={formError.companyName}
              onChange={onChange('companyName')}
              onBlur={onBlur('companyName')}
              value={formState.companyName}
              required={true}
            />

            <TextInput
              id="facebookPage"
              label={t('fields.store.facebookPage')}
              name="facebookPage"
              autoComplete="1234567890"
              error={Boolean(formError.facebookPage)}
              helperText={formError.facebookPage}
              onChange={onChange('facebookPage')}
              onBlur={onBlur('facebookPage')}
              value={formState.facebookPage}
            />

            <TextInput
              id="googleMybusinessLocation"
              label={t('fields.store.googleMybusinessLocation')}
              name="googleMybusinessLocation"
              autoComplete="1234567890"
              error={Boolean(formError.googleMybusinessLocation)}
              helperText={formError.googleMybusinessLocation}
              onChange={onChange('googleMybusinessLocation')}
              onBlur={onBlur('googleMybusinessLocation')}
              value={formState.googleMybusinessLocation}
            />

            {editableStoreFields.map(field =>
              renderField(field.name, field.required),
            )}
          </form>
          {type == 'update' &&
            (defaultFormState.facebookPage != formState.facebookPage ||
              defaultFormState.googleMybusinessLocation !=
                formState.googleMybusinessLocation) && (
              <Alert severity="warning">
                {t('components.upsertStoreModal.channelChangeInfo')}
              </Alert>
            )}
        </DialogContent>
        <DialogActions>
          <InteractiveButton
            disabled={hasErrors(formError)}
            onClick={useHandleSubmit}
            color="secondary"
            variant="contained"
            label={t('components.upsertStoreModal.save')}
          />
          <Button onClick={handleClose} color="primary" disabled={busy}>
            {t('components.upsertStoreModal.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default UpsertStore;
