import gql from 'graphql-tag';
import { useDirectMutation } from '../../graphql';
import { ChangePassword, ChangePasswordVariables } from '../../../../schema';

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      user {
        id
      }
    }
  }
`;

export function useChangePasswordMutation() {
  return useDirectMutation<ChangePassword, ChangePasswordVariables>(
    CHANGE_PASSWORD,
  );
}
