import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import { RedirectToUberall } from '../../../../schema';

const REDIRECT_TO_UBERALL = gql`
  mutation RedirectToUberall {
    redirectToUberall {
      redirectUrl
    }
  }
`;

export function useRedirectToUberallMutation() {
  return useDirectMutation<RedirectToUberall, unknown>(REDIRECT_TO_UBERALL);
}
