import React, { useState, useContext } from 'react';
import StatBox from './StatBox';
import ChannelsIcon from '../../../../components/ChannelsIcon/ChannelsIcon';
import SummaryInfoBox from '../../../../components/SummaryInfoBox/SummaryInfoBox';
import * as _ from 'lodash';
import Post from '../../../../components/Post/Post';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Grid,
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';
import {
  Iteration_iteration_gmbAggregatedStats as GmbAggregatedStats,
  VisibleGmbPosts_visibleGmbPosts as GmbPost,
  Iteration_iteration_statistics_gmb_reviews as GmbReview,
} from '../../../../schema';
import { ClientConfigContext } from '../../../../lib/client-config';
import FullscreenLoading from '../../../Layout/FullscreenLoading';
import { useGetVisibleGmbPostsQuery } from '../../../../lib/graphql/resolvers/queries/visible-gmb-posts';
import { Review } from '../../../../components/Review/Review';

const zeroIfNull = (val: unknown): number =>
  typeof val === 'number' ? val : 0;

const useStyles = makeStyles(theme => ({
  actions: {
    textAlign: 'right',
  },
  statsContainer: {
    padding: 10,
  },
  seeSinglePosts: {
    boxShadow: 'none',
    textTransform: 'capitalize',
  },
  reviewReply: {
    marginTop: 10,
    marginLeft: 50,
    borderLeft: '3px solid #ccc',
    padding: 10,
  },
}));

const PreloadedPostListView: React.FC<{
  storeId: number;
  iterationId: number;
  color: string;
}> = ({ storeId, iterationId, color }) => {
  const { t } = useTranslation();
  const { loading, data, error } = useGetVisibleGmbPostsQuery({
    storeId,
    iterationId,
  });

  if (loading) {
    return <FullscreenLoading />;
  }

  if (error) {
    return <h1>{String(error)}</h1>;
  }

  if (!data || !data.visibleGmbPosts) {
    return <h1>{t('components.postListView.empty')}</h1>;
  }

  return <PostListView posts={data.visibleGmbPosts} color={color} />;
};

const PostListView: React.FC<{ posts: GmbPost[]; color: string }> = ({
  posts,
  color,
}) => {
  return (
    <List>
      {posts.map(post => (
        <>
          <ListItem key={post.name}>
            <Grid container>
              <Grid item xs={4}>
                <Post
                  message={post.details.message}
                  image={post.details.media}
                  previewUrl={post.details.previewUrl}
                />
              </Grid>
              <Grid item xs={8}>
                {/* since Feb, 2023 gmb post stats are deprecated */}
              </Grid>
            </Grid>
          </ListItem>
          <div style={{ height: 10 }} />
          <Divider />
        </>
      ))}
    </List>
  );
};

const GmbStatsBox: React.FC<{
  title: string;
  color: string;
  storeId: number;
  iterationId: number;
  aggregatedStats: GmbAggregatedStats;
  reviews?: GmbReview[];
}> = ({ title, color, aggregatedStats, reviews, storeId, iterationId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [postsDialogOpen, setPostsDialogOpen] = useState(false);
  const handlePostsDialogClose = () => {
    setPostsDialogOpen(false);
  };
  const {
    config: {
      sortedVisibleAggregatedStats,
      featureFlags,
      gmbReviewDisplayFormat,
    },
  } = useContext(ClientConfigContext);

  const canViewSinglePosts =
    featureFlags.includes('CAN_VIEW_SINGLE_POSTS') && aggregatedStats.posts > 0;

  return (
    <>
      <Box p={1}>
        <SummaryInfoBox
          renderTitle={() => (
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" color={color}>
                <ChannelsIcon channel="GOOGLE_MYBUSINESS" />
                <Typography variant="body1">{title}</Typography>
              </Box>
              {canViewSinglePosts && (
                <Button
                  className={classes.seeSinglePosts}
                  onClick={e => setPostsDialogOpen(true)}
                  variant="contained"
                  color="secondary"
                >
                  {t('components.storeView.seeSinglePosts')}
                </Button>
              )}
            </Box>
          )}
        >
          <Grid container>
            {sortedVisibleAggregatedStats.gmb.map(statKey => (
              <>
                {aggregatedStats[statKey] !== undefined && (
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(`components.statBox.gmb.page.${statKey}`)}
                      value={zeroIfNull(aggregatedStats[statKey])}
                    />
                  </Grid>
                )}
              </>
            ))}
          </Grid>

          {featureFlags.includes('CAN_VIEW_GMB_REVIEWS') && (
            <>
              <Divider></Divider>
              {gmbReviewDisplayFormat == 'grouped' && (
                <Grid container>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_one_or_two_with_answer',
                      )}
                      value={aggregatedStats.reviews.rated_one_and_two.answered}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_one_or_two_without_answer',
                      )}
                      value={
                        aggregatedStats.reviews.rated_one_and_two.unanswered
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_three_and_four_with_answer',
                      )}
                      value={
                        aggregatedStats.reviews.rated_three_and_four.answered
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_three_and_four_without_answer',
                      )}
                      value={
                        aggregatedStats.reviews.rated_three_and_four.unanswered
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_five_with_answer',
                      )}
                      value={aggregatedStats.reviews.rated_five.answered}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_five_without_answer',
                      )}
                      value={aggregatedStats.reviews.rated_five.unanswered}
                    />
                  </Grid>
                </Grid>
              )}

              {gmbReviewDisplayFormat == 'standard' && (
                <Grid container>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_one_with_answer',
                      )}
                      value={aggregatedStats.reviews.rated_one.answered}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_one_without_answer',
                      )}
                      value={aggregatedStats.reviews.rated_one.unanswered}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_two_with_answer',
                      )}
                      value={aggregatedStats.reviews.rated_two.answered}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_two_without_answer',
                      )}
                      value={aggregatedStats.reviews.rated_two.unanswered}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_three_with_answer',
                      )}
                      value={aggregatedStats.reviews.rated_three.answered}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_three_without_answer',
                      )}
                      value={aggregatedStats.reviews.rated_three.unanswered}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_four_with_answer',
                      )}
                      value={aggregatedStats.reviews.rated_four.answered}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_four_without_answer',
                      )}
                      value={aggregatedStats.reviews.rated_four.unanswered}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_five_with_answer',
                      )}
                      value={aggregatedStats.reviews.rated_five.answered}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        'components.statBox.gmb.page.reviews_rated_five_without_answer',
                      )}
                      value={aggregatedStats.reviews.rated_five.unanswered}
                    />
                  </Grid>
                </Grid>
              )}

              <List>
                {reviews &&
                  reviews
                    .sort((a, b) => {
                      if (a.create_time == null) {
                        return 1;
                      }
                      if (b.create_time == null) {
                        return 1;
                      }
                      if (a.create_time < b.create_time) {
                        return 1;
                      }
                      return a.create_time > b.create_time ? -1 : 0;
                    })
                    .map(review => (
                      <>
                        <Review
                          createdAt={review.create_time}
                          comment={review.comment}
                          key={review.review_id}
                          rating={review.star_rating}
                          reply={review.review_reply}
                          reviewer={{
                            displayName: review.reviewer?.display_name,
                            photoUrl: review.reviewer?.profile_photo_url,
                          }}
                        />
                        <Divider variant="inset" component="li" />
                      </>
                    ))}
              </List>
            </>
          )}
        </SummaryInfoBox>
      </Box>
      {canViewSinglePosts && (
        <Dialog
          maxWidth="lg"
          fullWidth
          open={postsDialogOpen}
          onClose={handlePostsDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {t('components.storeView.posts')}
          </DialogTitle>
          <DialogContent>
            {postsDialogOpen && (
              <PreloadedPostListView
                storeId={storeId}
                iterationId={iterationId}
                color={color}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePostsDialogClose} color="primary">
              {t('components.storeView.close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default GmbStatsBox;
