import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../../components/TextInput/TextInput';
import { useIsPasswordPwned } from '../../lib/use-is-password-pwned';
import { NotificationsContext } from '../../lib/use-notifications';
import { PasswordBreachedWarning } from '../ChangePassword/PasswordBreachedWarning';
import usePasswordResetReducer from './state';
import { useGqlClient } from '../../lib/graphql/use-gql-client';

type Props = {
  classes: ClassNameMap<'heading' | 'button' | 'input'>;
  resetToken: string;
};

export const ResetPassword: React.FC<Props> = ({ resetToken, classes }) => {
  const { t } = useTranslation();
  const [state, dispatch] = usePasswordResetReducer();
  const [loading, setLoading] = useState(false);
  const { addNotification } = useContext(NotificationsContext);
  const { timesSeen, fetching } = useIsPasswordPwned(state.password);
  const gqlClient = useGqlClient();

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    setLoading(true);
    try {
      await gqlClient.resetPassword({
        input: {
          password: state.password,
          passwordConfirmation: state.passwordConfirmation,
          resetToken,
        },
      });

      dispatch({
        type: 'RESET_INPUT',
      });

      addNotification({
        message: t('components.reset-password.reset-success'),
        variant: 'success',
      });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <>
      <Box mb={5}>
        <Typography component="h1" variant="h4" className={classes.heading}>
          {t('components.reset-password.reset-title')}
        </Typography>
      </Box>
      <form noValidate onSubmit={handleSubmit}>
        <TextInput
          label={t('components.reset-password.labels.password')}
          type="password"
          value={state.password}
          error={!!state.passwordError}
          helperText={state.passwordError}
          onChange={e =>
            dispatch({
              type: 'SET_PASSWORD',
              password: e.target.value,
            })
          }
          onBlur={e =>
            dispatch({
              type: 'SET_PASSWORD',
              password: e.target.value,
            })
          }
        />
        <TextInput
          label={t('components.reset-password.labels.password-confirmation')}
          type="password"
          className={classes.input}
          value={state.passwordConfirmation}
          error={!!state.passwordConfirmationError}
          helperText={state.passwordConfirmationError}
          onChange={e =>
            dispatch({
              type: 'SET_PASSWORD_CONFIRMATION',
              passwordConfirmation: e.target.value,
            })
          }
          onBlur={e =>
            dispatch({
              type: 'SET_PASSWORD_CONFIRMATION',
              passwordConfirmation: e.target.value,
            })
          }
        />
        {!fetching && timesSeen && !state.passwordError ? (
          <PasswordBreachedWarning found={timesSeen} />
        ) : null}
        <Button
          variant="contained"
          type="submit"
          className={classes.button}
          color="secondary"
          disabled={
            !state.password ||
            !state.passwordConfirmation ||
            Boolean(state.passwordError) ||
            Boolean(state.passwordConfirmationError)
          }
          fullWidth
        >
          {loading ? (
            <CircularProgress color="inherit" />
          ) : (
            t('components.reset-password.submit')
          )}
        </Button>
      </form>
    </>
  );
};
