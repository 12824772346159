import { Box, makeStyles } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import React from 'react';

type Props = {
  items: Array<{ label: string; change: number }>;
};

const useStyles = makeStyles({
  outerContainer: {
    border: '1px solid grey',
    borderRadius: '4px',
    width: 200,
    textAlign: 'left',
    marginTop: 10,
    marginBottom: 10,
    display: 'inline-block',
    fontSize: 11,
  },
  container: {
    width: '100%',
    border: 'none',
    '& td': {
      textAlign: 'left !important' as any,
      verticalAlign: 'top !important' as any,
      overflowWrap: 'break-word',
      fontSize: 11,
      padding: 5,
    },
  },
});

export const DashboardStoreList: React.FC<Props> = ({ items }: Props) => {
  const classes = useStyles();

  if (items.length === 0) {
    return <BlockIcon></BlockIcon>;
  }

  return (
    <Box className={classes.outerContainer}>
      <table className={classes.container} cellSpacing="0" cellPadding="0">
        {items.map((e, idx) => {
          const isPositive = e.change >= 0;
          return (
            <tr key={idx}>
              <td style={{ width: '10%' }}>
                <span>{idx + 1}</span>
              </td>
              <td>{e.label}</td>
              <td>
                <span
                  style={{
                    width: '30%',
                    fontWeight: 700,
                    color: isPositive ? '#439000' : '#e60000',
                  }}
                >
                  {isPositive ? `+${e.change}` : e.change}
                </span>
              </td>
            </tr>
          );
        })}
      </table>
    </Box>
  );
};
