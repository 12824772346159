import React from 'react';
import { makeStyles } from '@material-ui/core';
import { dashboardFormatNumber } from '../../lib/dashboard';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    height: 40,
    display: 'inline-block',
    left: -25,
  },
  value: {
    width: '100%',
    textAlign: 'center',
    lineHeight: '40px',
    height: 40,
    borderRadius: 5,
    border: '1px solid #aaa',
    // use shadows to mimic the double border
    boxShadow: '0 0 0 2px #fff, 0 0 0 3px #aaa',
    fontWeight: 700,
    background: '#fff',
  },
  change: {
    fontWeight: 700,
    position: 'absolute',
    right: '-55px',
    top: '-15px',
    borderRadius: '50%',
    width: 70,
    height: 70,
    lineHeight: '70px',
    textAlign: 'center',
    border: '1px solid #aaa',
    background: '#f2f2f2',
    zIndex: 10,
    boxShadow: '3px 3px 3px #aaa',
  },
}));

const DashboardMetricBox: React.FC<{
  value: number;
  valueSuffix?: string;
  change?: string;
  width: number;
}> = ({ value, change, width, valueSuffix }) => {
  const classes = useStyles();

  const colorMap = (value: number): string => {
    if (value == 0) {
      return '#ed9942';
    }
    if (value > 0) {
      return '#439000';
    }
    return '#E60000';
  };

  return (
    <div className={classes.container} style={{ width }}>
      <div className={classes.value}>
        {dashboardFormatNumber(value)}
        {valueSuffix || ''}
      </div>
      {typeof change !== 'undefined' && (
        <div
          className={classes.change}
          style={{ color: colorMap(parseFloat(change || '0')) }}
        >
          {!isNaN(Number(change || '-')) &&
            dashboardFormatNumber(100 * parseFloat(change), {
              showSignAlways: true,
              decimalPlaces: 1,
            }) + '%'}
          {isNaN(Number(change || '-')) && '-'}
        </div>
      )}
    </div>
  );
};

export default DashboardMetricBox;
