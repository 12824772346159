import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import {
  UploadFbPageTaskImage,
  UploadFbPageTaskImageVariables,
} from '../../../../schema';

const UPLOAD_FB_PAGE_TASK_IMAGE = gql`
  mutation UploadFbPageTaskImage($input: UploadFbPageTaskImageInput!) {
    uploadFbPageTaskImage(input: $input) {
      id
      media {
        type
        url
        isLocalFile
      }
    }
  }
`;

export function useUploadFbPageTaskImageMutation() {
  return useDirectMutation<
    UploadFbPageTaskImage,
    UploadFbPageTaskImageVariables
  >(UPLOAD_FB_PAGE_TASK_IMAGE);
}
