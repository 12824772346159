import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import {
  EditPostTaskIssues,
  EditPostTaskIssuesVariables,
} from '../../../../schema';

const EDIT_POST_TASK_ISSUES = gql`
  mutation EditPostTaskIssues($input: EditPostTaskIssuesInput!) {
    editPostTaskIssues(input: $input) {
      task {
        id
        permissions
      }
    }
  }
`;

export function useEditPostTaskIssuesMutation() {
  return useDirectMutation<EditPostTaskIssues, EditPostTaskIssuesVariables>(
    EDIT_POST_TASK_ISSUES,
  );
}
