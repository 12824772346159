import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Box,
  Typography,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import React, { ReactElement, ReactFragment } from 'react';
import {
  GmbStarRating,
  TasksPerStore_tasks_tasks_reviewDetails_reply as Reply,
} from '../../schema';
import { formatDate } from '../../format-date';

type Props = {
  comment?: string | null;
  reviewer?: {
    displayName?: string | null;
    photoUrl?: string | null;
  } | null;
  rating?: GmbStarRating | null;
  reply?: Reply | null;
  createdAt?: string | null;
};

const starRatingMap: Record<GmbStarRating, ReactFragment> = {
  ONE: (
    <React.Fragment>
      <StarIcon />
      <StarOutlineIcon />
      <StarOutlineIcon />
      <StarOutlineIcon />
      <StarOutlineIcon />
    </React.Fragment>
  ),
  TWO: (
    <React.Fragment>
      <StarIcon />
      <StarIcon />
      <StarOutlineIcon />
      <StarOutlineIcon />
      <StarOutlineIcon />
    </React.Fragment>
  ),
  THREE: (
    <React.Fragment>
      <StarIcon />
      <StarIcon />
      <StarIcon />
      <StarOutlineIcon />
      <StarOutlineIcon />
    </React.Fragment>
  ),
  FOUR: (
    <React.Fragment>
      <StarIcon />
      <StarIcon />
      <StarIcon />
      <StarIcon />
      <StarOutlineIcon />
    </React.Fragment>
  ),
  FIVE: (
    <React.Fragment>
      <StarIcon />
      <StarIcon />
      <StarIcon />
      <StarIcon />
      <StarIcon />
    </React.Fragment>
  ),
};

const originalDelimiter = '(Original)';
const translatedDelimiter = '(Translated by Google)';

// https://github.com/gotu-io/local-marketing-data-services/issues/553
// a different style is required for the translated and for the original
// part of the review (both message and reply) as google returns review
// content with both original and translated like:
// (Translated by Google) All very good (Original) Tutti molto bravi
const extractText = (
  text: string,
):
  | {
      original: string;
      translated: string;
      raw: undefined;
    }
  | { raw: string; original: undefined; translated: undefined } => {
  // not 100% sure translated message is always before original, check any scenario
  if (text.startsWith(originalDelimiter)) {
    const fragments = text.split(translatedDelimiter);
    if (fragments.length === 2) {
      return {
        original: fragments[0].replace(originalDelimiter, ''),
        translated: fragments[1],
        raw: undefined,
      };
    }
    return { raw: text, original: undefined, translated: undefined };
  } else if (text.startsWith(translatedDelimiter)) {
    const fragments = text.split(originalDelimiter);
    if (fragments.length === 2) {
      return {
        original: fragments[1],
        translated: fragments[0].replace(translatedDelimiter, ''),
        raw: undefined,
      };
    }
    return { raw: text, original: undefined, translated: undefined };
  }
  return { raw: text, original: undefined, translated: undefined };
};

const ReviewText = ({ text }: { text: string }): ReactElement => {
  const extractedText = extractText(text || '');
  return (
    <Typography component="div" variant="body2" color="textPrimary">
      {extractedText.raw !== undefined && extractedText.raw}
      {extractedText.raw === undefined && (
        <>
          <div>
            <b>
              {originalDelimiter}&nbsp;{extractedText.original}
            </b>
          </div>
          <div>
            {translatedDelimiter}&nbsp;{extractedText.translated}
          </div>
        </>
      )}
    </Typography>
  );
};

export const Review = ({
  comment,
  reviewer,
  rating,
  reply,
  createdAt,
}: Props): ReactElement => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          alt="Review author"
          src={
            reviewer && reviewer.photoUrl
              ? reviewer.photoUrl
              : 'http://via.placeholder.com/50x50'
          }
        />
      </ListItemAvatar>
      <ListItemText
        primary={reviewer && reviewer.displayName ? reviewer.displayName : null}
        secondary={
          comment || rating || reply ? (
            <React.Fragment>
              {rating && <Box>{starRatingMap[rating]}</Box>}
              {createdAt && <Box>{formatDate(createdAt)}</Box>}
              {!!comment && <ReviewText text={comment} />}
              {reply && (
                <Box mt={1} ml={5} borderLeft="3px solid #e60000" p={1}>
                  {reply.update_time && (
                    <Box>{formatDate(reply.update_time)}</Box>
                  )}
                  {!!reply.comment && <ReviewText text={reply.comment} />}
                </Box>
              )}
            </React.Fragment>
          ) : null
        }
      />
    </ListItem>
  );
};
