import { useDirectQuery } from '../../graphql';
import gql from 'graphql-tag';
import { Tasks, TasksVariables } from '../../../../schema';
import { useQuery } from 'react-apollo';

const GET_TASKS = gql`
  query Tasks($input: TasksInput!) {
    tasks(input: $input) {
      tasks {
        id
        type
        status
        iterationId
        iteration {
          id
          storeId
          startsAt
          endsBefore
          store {
            id
            remoteId
            companyName
            operator {
              id
              email
            }
          }
        }
        createdAt
        targetCreatedAt
        completedAt
        permissions
      }
      filteredCount
    }
  }
`;

export function useGetTasksDirectQuery() {
  return useDirectQuery<Tasks, TasksVariables>(GET_TASKS);
}

export function useGetTasksQuery(variables: TasksVariables) {
  return useQuery<Tasks, TasksVariables>(GET_TASKS, {
    variables,
    fetchPolicy: 'network-only',
  });
}
