import gql from 'graphql-tag';
import { useDirectMutation } from '../../graphql';
import { ResetPassword, ResetPasswordVariables } from '../../../../schema';

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
    }
  }
`;

export function useResetPasswordMutation() {
  return useDirectMutation<ResetPassword, ResetPasswordVariables>(
    RESET_PASSWORD,
  );
}
