import gql from 'graphql-tag';
import { useDirectMutation } from '../../graphql';
import {
  EditReviewTaskCompliancy,
  EditReviewTaskCompliancyVariables,
} from '../../../../schema';

const EDIT_REVIEW_COMPLIANCY = gql`
  mutation EditReviewTaskCompliancy($input: EditReviewTaskCompliancyInput!) {
    editReviewTaskCompliancy(input: $input) {
      task {
        id
        permissions
      }
    }
  }
`;

export function useEditReviewCompliancyMutation() {
  return useDirectMutation<
    EditReviewTaskCompliancy,
    EditReviewTaskCompliancyVariables
  >(EDIT_REVIEW_COMPLIANCY);
}
