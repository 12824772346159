import React, { useState } from 'react';
import {
  Button,
  IconButton,
  ButtonProps,
  IconButtonProps,
  makeStyles,
  CircularProgress,
  Box,
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';

/**
 * a wrapper for material-ui button with commonly used features:
 * - label and/or icon
 * - spinner while busy
 * - confirmation modal before executing handler
 */

export const useStyles = makeStyles(theme => ({
  button: {
    height: 38,
    fontSize: 14,
  },
  buttonWrapper: {
    position: 'relative',
    display: 'inline-block',
    '& button': {
      height: 38,
      lineHeight: 0,
      fontSize: 14,
    },
  },
  fullWidthButtonWrapper: {
    position: 'relative',
    display: 'block',
    '& button': {
      height: 38,
      lineHeight: 0,
      fontSize: 14,
    },
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export type Handler = (
  e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
) => Promise<void> | void;

export type Confirm = {
  title: string;
  content: JSX.Element;
  submit: {
    disabled?: boolean;
    label?: string;
    onClick: Handler;
  };
  abort?: {
    label?: string;
    onClick?: Handler;
  };
  extraActions?: Array<{
    label: string;
    onClick: Handler;
  }>;
  beforeDisplay?: () => void | Promise<void>;
};

type CommonProps = {
  onClick?: Handler;
  confirm?: Confirm;
  label?: string;
  icon?: JSX.Element;
};

export type InteractiveButtonProps = CommonProps &
  Exclude<ButtonProps, 'onClick'>;
export type InteractiveIconButtonProps = CommonProps &
  Exclude<IconButtonProps, 'onClick'>;

const InteractiveButton: React.FC<
  InteractiveButtonProps | InteractiveIconButtonProps
> = ({ onClick, confirm, label, icon, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [busy, setBusy] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleClick = async (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.FormEvent<HTMLFormElement>,
  ) => {
    if (onClick) {
      setBusy(true);
      try {
        await onClick(event);
      } finally {
        setBusy(false);
      }
    } else if (confirm) {
      if (confirm.beforeDisplay) {
        await confirm.beforeDisplay();
      }
      setConfirmationDialogOpen(true);
    }
  };

  const handleConfirmationDialogOk = async (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.FormEvent<HTMLFormElement>,
  ) => {
    setConfirmationDialogOpen(false);
    if (confirm) {
      setBusy(true);
      try {
        await confirm.submit.onClick(event);
        setBusy(false);
      } catch (e) {
        setBusy(false);
        console.log(e);
        throw e;
      }
    }
  };

  const handleConfirmationDialogCancel = async (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.FormEvent<HTMLFormElement>,
  ) => {
    setConfirmationDialogOpen(false);
    if (confirm && confirm.abort && confirm.abort.onClick) {
      setBusy(true);
      try {
        await confirm.abort.onClick(event);
        setBusy(false);
      } catch (e) {
        setBusy(false);
        console.log(e);
        throw e;
      }
    }
  };

  const disabled = busy || props.disabled;

  return (
    <>
      <div
        className={
          (props as ButtonProps).fullWidth
            ? `${classes.fullWidthButtonWrapper} ${props.className}`
            : `${classes.buttonWrapper} ${props.className}`
        }
      >
        {label && (
          <Button
            {...(props as ButtonProps)}
            disabled={disabled}
            className={`${classes.button} ${props.className}`}
            onClick={handleClick}
          >
            {label}
          </Button>
        )}
        {!label && icon && (
          <IconButton
            {...(props as IconButtonProps)}
            disabled={disabled}
            className={`${classes.button} ${props.className}`}
            onClick={handleClick}
          >
            {icon}
          </IconButton>
        )}
        {busy && <CircularProgress size={24} className={classes.progress} />}
      </div>
      {confirm && (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="sm"
          fullWidth
          aria-labelledby="confirmation-dialog-title"
          open={confirmationDialogOpen}
        >
          <DialogTitle id="confirmation-dialog-title">
            {confirm.title}
          </DialogTitle>
          <DialogContent dividers>{confirm.content}</DialogContent>
          <DialogActions>
            {confirm.extraActions !== undefined && (
              <Box flexGrow={1}>
                {confirm.extraActions.map((action, index) => (
                  <Button
                    color="primary"
                    key={index}
                    onClick={e => action.onClick(e)}
                  >
                    {action.label}
                  </Button>
                ))}
              </Box>
            )}
            <Box>
              <Button
                onClick={handleConfirmationDialogOk}
                color="secondary"
                variant="contained"
                disabled={confirm.submit.disabled}
              >
                {confirm.submit.label ? confirm.submit.label : 'Ok'}
              </Button>
              <Button
                autoFocus
                color="primary"
                onClick={handleConfirmationDialogCancel}
              >
                {confirm.abort && confirm.abort.label
                  ? confirm.abort.label
                  : t('components.interactiveButton.abort')}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default InteractiveButton;
