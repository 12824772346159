import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import { ReactivateStore, ReactivateStoreVariables } from '../../../../schema';

const REACTIVATE_STORE = gql`
  mutation ReactivateStore($input: ReactivateStoresInput!) {
    reactivateStores(input: $input) {
      stores {
        id
      }
    }
  }
`;

export function useReactivateStoreMutation() {
  return useDirectMutation<ReactivateStore, ReactivateStoreVariables>(
    REACTIVATE_STORE,
  );
}
