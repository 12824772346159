import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FacebookPageSelect from '../../../components/FacebookPageAutocomplete/FacbookPageSelect';
import { TypeaheadFacebookPageWithToken } from '../use-facebook';
import Card, { CardStatus } from './Card';

const useStyles = makeStyles({
  select: {
    width: '100%',
  },
});

type Props = {
  disabled?: boolean;
  hidden?: boolean;
  pages?: TypeaheadFacebookPageWithToken[];
  value?: TypeaheadFacebookPageWithToken;
  onChange: (page: TypeaheadFacebookPageWithToken) => void;
  status: CardStatus;
};

const Page: React.FC<Props> = ({ pages = [], value, onChange, status }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card title={t('components.fb-onboarding.page-step-title')} status={status}>
      <FacebookPageSelect
        className={classes.select}
        pages={pages}
        value={value}
        setValue={onChange}
      />
    </Card>
  );
};

export default Page;
