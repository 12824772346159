import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import {
  NonCompliantReviews,
  NonCompliantReviewsVariables,
} from '../../../../schema';

const GET_NON_COMPLIANT_REVIEWS = gql`
  query NonCompliantReviews($input: NonCompliantReviewsListInput!) {
    nonCompliantReviews(input: $input) {
      reviews {
        id
        star_rating
        reviewer {
          profile_photo_url
          display_name
        }
        create_time
        comment
        review_reply {
          comment
          update_time
        }
        store {
          remoteId
          companyName
          address
          zipCode
          city
          area
        }
      }
      filteredCount
    }
  }
`;

export function useGetNonCompliantReviewsQuery(
  variables: NonCompliantReviewsVariables,
) {
  return useQuery<NonCompliantReviews, NonCompliantReviewsVariables>(
    GET_NON_COMPLIANT_REVIEWS,
    {
      variables,
      fetchPolicy: 'network-only',
    },
  );
}
