import {
  createMuiTheme,
  lighten,
  darken,
  Theme,
  SimplePaletteColorOptions,
} from '@material-ui/core/styles';
// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@material-ui/core';
import { Color } from '@material-ui/core';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import { ClientConfig } from './lib/client-config';

function makeColor(
  mainColor: string,
  textColor: string,
): SimplePaletteColorOptions | Partial<Color> {
  return {
    main: mainColor,
    light: lighten(mainColor, 0.25),
    dark: darken(mainColor, 0.25),
    contrastText: textColor,
  };
}

export type AppTheme = Theme & {
  palette: Theme['palette'] & {
    highlight: PaletteColor;
  };
  assets: {
    logo: string;
    favicon: string;
  };
};

type AppThemeOptions = ThemeOptions & {
  palette: ThemeOptions['palette'] & {
    highlight?: SimplePaletteColorOptions | Partial<Color> | undefined;
  };
  assets: {
    logo: string;
    favicon: string;
  };
};

const defaultPalette: AppThemeOptions['palette'] = {
  primary: makeColor('#2e4056', '#ffffff'),
  secondary: makeColor('#000000', '#ffffff'),
  highlight: makeColor('#3dc58e', '#000000'),
  error: makeColor('#B0001F', '#ffffff'),
};

function resolveAssetPath(path: string, baseUri: string): string {
  const assetsBase = new URL(baseUri, window.location.href).href;
  const assetsPathBase = new URL('client/assets/', assetsBase).href;
  return new URL(path, assetsPathBase).href;
}

const defaultThemeOptions: AppThemeOptions = {
  palette: defaultPalette,
  typography: {
    fontFamily: ['"Open Sans"'].join(','),
  },
  assets: {
    favicon: 'https://via.placeholder.com/50',
    logo: 'https://via.placeholder.com/2400x800',
  },
};

export function createTheme(
  config?: ClientConfig['config']['theme'],
  baseUri?: string,
): AppTheme {
  const options: AppThemeOptions = {
    ...defaultThemeOptions,
  };

  if (config) {
    options.palette = {
      primary: makeColor(
        config.colors.primary.main,
        config.colors.primary.text,
      ),
      secondary: makeColor(
        config.colors.secondary.main,
        config.colors.secondary.text,
      ),
      highlight: makeColor(
        config.colors.highlight.main,
        config.colors.highlight.text,
      ),
      error: makeColor(config.colors.error.main, config.colors.error.text),
    };

    if (baseUri) {
      options.assets = {
        logo: resolveAssetPath(config.logo, baseUri),
        favicon: resolveAssetPath(config.favicon, baseUri),
      };
    }
  }

  return createMuiTheme(options) as AppTheme;
}

export function useAppTheme() {
  return useTheme<AppTheme>();
}
