import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import {
  UpdateFbPageTaskImage,
  UpdateFbPageTaskImageVariables,
} from '../../../../schema';

const UPDATE_FB_PAGE_TASK_IMAGE = gql`
  mutation UpdateFbPageTaskImage($taskId: Int!) {
    updateFbPageTaskImage(taskId: $taskId) {
      id
      media {
        type
        url
        isLocalFile
      }
    }
  }
`;

export function useUpdateFbPageTaskImageMutation() {
  return useDirectMutation<
    UpdateFbPageTaskImage,
    UpdateFbPageTaskImageVariables
  >(UPDATE_FB_PAGE_TASK_IMAGE);
}
