import { TFunction } from 'i18next';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import UnreachableCaseError from '../../lib/unreachable-case.error';

type SetOldPassword = {
  type: 'SET_OLD_PASSWORD';
  value: string;
};
type SetNewPassword = {
  type: 'SET_NEW_PASSWORD';
  value: string;
};
type SetPasswordConfirmation = {
  type: 'SET_PASSWORD_CONFIRMATION ';
  value: string;
};
type ResetInput = {
  type: 'RESET_INPUT';
};

type Action =
  | SetOldPassword
  | SetNewPassword
  | SetPasswordConfirmation
  | ResetInput;

type PasswordInput = {
  value: string;
  error: string | undefined;
};

type State = {
  oldPassword: PasswordInput;
  newPassword: PasswordInput;
  passwordConfirmation: PasswordInput;
};

const initialState: State = {
  oldPassword: {
    value: '',
    error: undefined,
  },
  newPassword: {
    value: '',
    error: undefined,
  },
  passwordConfirmation: {
    value: '',
    error: undefined,
  },
};

function validateOldPassword(
  t: TFunction,
  password: string,
): string | undefined {
  if (!password) {
    return t('components.change-password.validations.no-old-password');
  }

  return undefined;
}
const MIN_PASSWORD_LENGTH = 10;
export function validatePassword(
  t: TFunction,
  password: string,
): string | undefined {
  if (!password) {
    return t('components.change-password.validations.no-new-password');
  } else if (password.length < MIN_PASSWORD_LENGTH) {
    return t('components.change-password.validations.password-too-short', {
      min: MIN_PASSWORD_LENGTH,
    });
  }

  return undefined;
}

export function validatePasswordConfirmation(
  t: TFunction,
  password: string,
  original: string,
): string | undefined {
  if (password !== original) {
    return t('components.change-password.validations.password-confirmation');
  }

  return undefined;
}

function buildPasswordReducer(t: TFunction) {
  return function passwordReducer(state: State, action: Action): State {
    switch (action.type) {
      case 'SET_OLD_PASSWORD': {
        const error = validateOldPassword(t, action.value);

        return {
          ...state,
          oldPassword: { value: action.value, error },
        };
      }
      case 'SET_NEW_PASSWORD': {
        const error = validatePassword(t, action.value);

        return {
          ...state,
          newPassword: { value: action.value, error },
        };
      }
      case 'SET_PASSWORD_CONFIRMATION ': {
        const error = validatePasswordConfirmation(
          t,
          action.value,
          state.newPassword.value,
        );

        return {
          ...state,
          passwordConfirmation: {
            value: action.value,
            error,
          },
        };
      }
      case 'RESET_INPUT':
        return {
          ...initialState,
        };
      default:
        throw new UnreachableCaseError(action);
    }
  };
}

export default function usePasswordChangeReducer() {
  const { t } = useTranslation();
  const reducer = buildPasswordReducer(t);
  return useReducer(reducer, initialState);
}
