import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import {
  SendInvitationEmail,
  SendInvitationEmailVariables,
} from '../../../../schema';

const SEND_INVITATION_EMAIL = gql`
  mutation SendInvitationEmail($input: SendInvitationEmailInput!) {
    sendInvitationEmail(input: $input) {
      message
    }
  }
`;

export function useSendInvitationEmailMutation() {
  return useDirectMutation<SendInvitationEmail, SendInvitationEmailVariables>(
    SEND_INVITATION_EMAIL,
  );
}
