import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ChannelsIcon from '../../components/ChannelsIcon/ChannelsIcon';
import DashboardMetricBox from '../../components/DashboardMetrixBox/DashboardMetricBox';
import { DashboardSectionText } from '../../components/DashboardSectionText/DashboardSectionText';
import { Dashboard_dashboard_gmb as GmbMetrics } from '../../schema';
import { useGetNonCompliantReviewsQuery } from '../../lib/graphql/resolvers/queries/non-compliant-reviews-list';
import RemoteDataTable from '../../components/RemoteDataTable/RemoteDataTable';
import {
  NonCompliantReviews,
  NonCompliantReviews_nonCompliantReviews_reviews as RemoteReview,
} from '../../schema';
import { Review } from '../../components/Review/Review';

type Props = {
  metrics: GmbMetrics;
  iterationStart: string;
  area?: string;
};

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    border: 'none',
    '& td': {
      verticalAlign: 'middle',
      textAlign: 'center',
      paddingTop: 15,
      paddingBottom: 15,
    },
    '& tr:nth-last-child(2) > td': {
      borderTop: '1px solid red',
    },
  },
}));

export const GmbDashboardSection = ({
  metrics,
  iterationStart,
  area,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  const metricBoxWidth = 100;
  const textBoxWidth = 140;

  return (
    <>
      {/* let's use a raw html table, the easiest way to properly align and arrange items as requested*/}
      <table className={classes.container} cellSpacing="0" cellPadding="0">
        <tr>
          <td rowSpan={6}>
            <ChannelsIcon channel="GOOGLE_MYBUSINESS" size={50} />
          </td>
          <td></td>
          <td>
            <DashboardSectionText
              color="active"
              text={String(t('components.dashboard.review-received'))}
              width={textBoxWidth}
            />
          </td>
          <td>
            <DashboardSectionText
              color="active"
              text={String(t('components.dashboard.review-managed'))}
              width={textBoxWidth}
            />
          </td>
          <td>
            <DashboardSectionText
              color="active"
              text={String(t('components.dashboard.response-rate'))}
              width={textBoxWidth}
            />
          </td>
          <td>
            <DashboardSectionText
              color="active"
              text={String(t('components.dashboard.compliant'))}
              width={textBoxWidth}
            />
          </td>
          <td>
            <DashboardSectionText
              color="active"
              text={String(t('components.dashboard.not-compliant'))}
              width={textBoxWidth}
            />
          </td>
        </tr>
        <tr>
          <td>
            <DashboardSectionText
              color="active"
              text={'1-2 ☆'}
              width={metricBoxWidth}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.oneAndTwo.received.num}
              width={metricBoxWidth}
              change={metrics.oneAndTwo.received.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.oneAndTwo.managed.num}
              width={metricBoxWidth}
              change={metrics.oneAndTwo.managed.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={Math.round(metrics.oneAndTwo.responseRate.num)}
              valueSuffix="%"
              width={metricBoxWidth}
              change={metrics.oneAndTwo.responseRate.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.oneAndTwo.compliant.num}
              width={metricBoxWidth}
              change={metrics.oneAndTwo.compliant.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.oneAndTwo.notCompliant.num}
              width={metricBoxWidth}
              change={metrics.oneAndTwo.notCompliant.change}
            />
          </td>
        </tr>
        <tr>
          <td>
            <DashboardSectionText
              color="active"
              text={'3-4 ☆'}
              width={metricBoxWidth}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.threeAndFour.received.num}
              width={metricBoxWidth}
              change={metrics.threeAndFour.received.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.threeAndFour.managed.num}
              width={metricBoxWidth}
              change={metrics.threeAndFour.managed.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={Math.round(metrics.threeAndFour.responseRate.num)}
              valueSuffix="%"
              width={metricBoxWidth}
              change={metrics.threeAndFour.responseRate.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.threeAndFour.compliant.num}
              width={metricBoxWidth}
              change={metrics.threeAndFour.compliant.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.threeAndFour.notCompliant.num}
              width={metricBoxWidth}
              change={metrics.threeAndFour.notCompliant.change}
            />
          </td>
        </tr>
        <tr>
          <td>
            <DashboardSectionText
              color="active"
              text={'5 ☆'}
              width={metricBoxWidth}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.five.received.num}
              width={metricBoxWidth}
              change={metrics.five.received.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.five.managed.num}
              width={metricBoxWidth}
              change={metrics.five.managed.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={Math.round(metrics.five.responseRate.num)}
              valueSuffix="%"
              width={metricBoxWidth}
              change={metrics.five.responseRate.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.five.compliant.num}
              width={metricBoxWidth}
              change={metrics.five.compliant.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.five.notCompliant.num}
              width={metricBoxWidth}
              change={metrics.five.notCompliant.change}
            />
          </td>
        </tr>
        <tr>
          <td>
            <DashboardSectionText
              color="active"
              text={'Overall ☆'}
              width={metricBoxWidth}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.total.received.num}
              width={metricBoxWidth}
              change={metrics.total.received.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.total.managed.num}
              width={metricBoxWidth}
              change={metrics.total.managed.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={Math.round(metrics.total.responseRate.num)}
              valueSuffix="%"
              width={metricBoxWidth}
              change={metrics.total.responseRate.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.total.compliant.num}
              width={metricBoxWidth}
              change={metrics.total.compliant.change}
            />
          </td>
          <td>
            <DashboardMetricBox
              value={metrics.total.notCompliant.num}
              width={metricBoxWidth}
              change={metrics.five.notCompliant.change}
            />
          </td>
        </tr>
        <tr>
          <td colSpan={6} style={{ fontSize: 10 }}>
            {t(`components.dashboard.comparison-disclaimer`)}
          </td>
        </tr>
      </table>
      <br />
      <br />
      <hr color="red" />
      <br />
      <br />
      <DashboardSectionText
        color="active"
        text={t('components.dashboard.non-compliant-reviews').toString()}
        width={200}
      />

      <RemoteDataTable
        pageSize={5}
        containerMinHeight={200}
        cacheId="non-compliant-reviews-list"
        searchable={false}
        renderDataRow={(review: RemoteReview) => {
          return (
            <>
              <TableRow key={review.id}>
                <TableCell style={{ background: '#eee' }}>
                  {review.store.remoteId}
                </TableCell>
                <TableCell style={{ background: '#eee' }}>
                  {review.store.companyName}
                </TableCell>
                <TableCell style={{ background: '#eee' }}>
                  {review.store.address}
                </TableCell>
                <TableCell style={{ background: '#eee' }}>
                  {review.store.city}
                </TableCell>
                <TableCell style={{ background: '#eee' }}>
                  {review.store.area}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5}>
                  <Review
                    reviewer={{
                      displayName: review.reviewer?.display_name,
                      photoUrl: review.reviewer?.profile_photo_url,
                    }}
                    comment={review.comment}
                    rating={review.star_rating}
                    reply={review.review_reply}
                    createdAt={review.create_time}
                  />
                </TableCell>
              </TableRow>
            </>
          );
        }}
        renderHeaderRow={() => {
          return (
            <TableRow>
              <TableCell>{t(`components.dashboard.store-id`)}</TableCell>
              <TableCell>{t(`components.dashboard.store-name`)}</TableCell>
              <TableCell>{t(`components.dashboard.address`)}</TableCell>
              <TableCell>{t(`components.dashboard.city`)}</TableCell>
              <TableCell>{t(`components.dashboard.area`)}</TableCell>
            </TableRow>
          );
        }}
        useQuery={useGetNonCompliantReviewsQuery}
        parseData={(data: NonCompliantReviews) =>
          data.nonCompliantReviews.reviews
        }
        parseFilteredCount={(data: NonCompliantReviews) =>
          data.nonCompliantReviews.filteredCount
        }
        externalFilter={{
          startsAt: iterationStart,
          area,
        }}
      />
    </>
  );
};
