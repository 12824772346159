import React, { useState, useContext } from 'react';
import StatBox from './StatBox';
import ChannelsIcon from '../../../../components/ChannelsIcon/ChannelsIcon';
import SummaryInfoBox from '../../../../components/SummaryInfoBox/SummaryInfoBox';
import * as _ from 'lodash';
import Post from '../../../../components/Post/Post';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Grid,
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';
import {
  Iteration_iteration_fbAggregatedStats as FbAggregatedStats,
  VisibleFbPosts_visibleFbPosts as FbPost,
} from '../../../../schema';
import { ClientConfigContext } from '../../../../lib/client-config';
import FullscreenLoading from '../../../Layout/FullscreenLoading';
import { useGetVisibleFbPostsQuery } from '../../../../lib/graphql/resolvers/queries/visible-fb-posts';

const useStyles = makeStyles(theme => ({
  actions: {
    textAlign: 'right',
  },
  statsContainer: {
    padding: 10,
  },
  seeSinglePosts: {
    boxShadow: 'none',
    textTransform: 'capitalize',
  },
}));

const PreloadedPostListView: React.FC<{
  storeId: number;
  iterationId: number;
  color: string;
}> = ({ storeId, iterationId, color }) => {
  const { t } = useTranslation();
  const { loading, data, error } = useGetVisibleFbPostsQuery({
    storeId,
    iterationId,
  });

  if (loading) {
    return <FullscreenLoading />;
  }

  if (error) {
    return <h1>{String(error)}</h1>;
  }

  if (!data || !data.visibleFbPosts) {
    return <h1>{t('components.postListView.empty')}</h1>;
  }

  return <PostListView posts={data.visibleFbPosts} color={color} />;
};

const PostListView: React.FC<{ posts: FbPost[]; color: string }> = ({
  posts,
  color,
}) => {
  const { t } = useTranslation();
  const {
    config: { sortedVisiblePostStats, name: clientName },
  } = useContext(ClientConfigContext);

  return (
    <List>
      {posts.map(post => (
        <>
          <ListItem key={post.post_info.id}>
            <Grid container>
              <Grid item xs={4}>
                <Post
                  message={post.details.message}
                  image={post.details.media}
                  previewUrl={post.details.previewUrl}
                />
              </Grid>
              <Grid item xs={8}>
                {post.stats && (
                  <Grid container>
                    {sortedVisiblePostStats.fb.map(statKey => (
                      <>
                        {post.stats && post.stats[statKey] !== undefined && (
                          <Grid item xs={4}>
                            <StatBox
                              color={color}
                              label={t(
                                `components.statBox.fb.post.${
                                  statKey === 'likes'
                                    ? statKey + '.' + clientName
                                    : statKey
                                }`,
                              )}
                              value={post.stats[statKey]}
                            />
                          </Grid>
                        )}
                      </>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </ListItem>
          <div style={{ height: 10 }} />
          <Divider />
        </>
      ))}
    </List>
  );
};

const FbStatsBox: React.FC<{
  title: string;
  color: string;
  storeId: number;
  iterationId: number;
  aggregatedStats: FbAggregatedStats;
}> = ({ title, color, aggregatedStats, storeId, iterationId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [postsDialogOpen, setPostsDialogOpen] = useState(false);
  const handlePostsDialogClose = () => {
    setPostsDialogOpen(false);
  };
  const {
    config: { sortedVisibleAggregatedStats, featureFlags, name: clientName },
  } = useContext(ClientConfigContext);

  const canViewSinglePosts =
    featureFlags.includes('CAN_VIEW_SINGLE_POSTS') && aggregatedStats.posts > 0;

  return (
    <>
      <Box p={1}>
        <SummaryInfoBox
          renderTitle={() => (
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" color={color}>
                <ChannelsIcon channel="FACEBOOK" />
                <Typography variant="body1">{title}</Typography>
              </Box>
              {canViewSinglePosts && (
                <Button
                  className={classes.seeSinglePosts}
                  onClick={e => setPostsDialogOpen(true)}
                  variant="contained"
                  color="secondary"
                >
                  {t('components.storeView.seeSinglePosts')}
                </Button>
              )}
            </Box>
          )}
        >
          <Grid container>
            {sortedVisibleAggregatedStats.fb.map(statKey => (
              <>
                {aggregatedStats[statKey] !== undefined && (
                  <Grid item xs={3}>
                    <StatBox
                      color={color}
                      label={t(
                        `components.statBox.fb.page.${
                          statKey === 'likes'
                            ? statKey + '.' + clientName
                            : statKey
                        }`,
                      )}
                      value={aggregatedStats[statKey]}
                    />
                  </Grid>
                )}
              </>
            ))}
          </Grid>
        </SummaryInfoBox>
      </Box>
      {canViewSinglePosts && (
        <Dialog
          maxWidth="lg"
          fullWidth
          open={postsDialogOpen}
          onClose={handlePostsDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {t('components.storeView.posts')}
          </DialogTitle>
          <DialogContent>
            {postsDialogOpen && (
              <PreloadedPostListView
                storeId={storeId}
                iterationId={iterationId}
                color={color}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePostsDialogClose} color="primary">
              {t('components.storeView.close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default FbStatsBox;
