import {
  Box,
  Typography,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import {
  ErrorOutline,
  ExpandMore as ExpandMoreIcon,
  WarningOutlined,
} from '@material-ui/icons';
import { fromError } from '../../lib/pretty-error';

interface Props {
  error: Error;
}

const useStyles = makeStyles({
  errorAlert: {
    backgroundColor: '#f44336 !important',
    color: 'white',
  },
  warningIcon: {
    paddingRight: '72px',
    fontSize: '160px',
    color: 'lightgrey',
    textAlign: 'center',
  },
  white: {
    color: 'white',
  },
  boldFont: {
    fontWeight: 600,
  },
  detailsPanel: {
    overflowX: 'auto',
    opacity: 0.3,
  },
});

export const FullScreenError: React.FC<Props> = ({ error }) => {
  // CANNOT USE useTranslation() (and i18n in general), as this component can be rendered even if client config load fails
  const classes = useStyles();

  const pretty = fromError(error);

  const [expanded, setExpanded] = useState(false);
  const handleToggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Container>
      <Box width="75%" margin="150px auto">
        <Box margin="0 0 20px" className={classes.warningIcon}>
          <WarningOutlined fontSize="inherit" />
        </Box>
        {pretty.map((e, i) => (
          <div key={i}>
            <Box margin="20px 0">
              <ExpansionPanel
                defaultExpanded={expanded}
                onChange={handleToggleExpanded}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon className={classes.white} />}
                  aria-label="graphql-errors-expansion"
                  aria-controls="graphql-errors-content"
                  id="graphql-errors-header"
                  className={classes.errorAlert}
                >
                  <Box display="flex" alignItems="center">
                    <ErrorOutline fontSize="large" />
                    <Box ml={2}>
                      <Typography className={classes.boldFont}>
                        {e.message}
                      </Typography>
                    </Box>
                  </Box>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.detailsPanel}>
                  <pre>{e.details}</pre>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Box>
          </div>
        ))}
      </Box>
    </Container>
  );
};
