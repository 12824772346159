import TextInput from '../components/TextInput/TextInput';

type TextInputProps = Parameters<typeof TextInput>[0];

type Ret = Pick<
  TextInputProps,
  'value' | 'error' | 'helperText' | 'onChange' | 'onBlur'
>;

export default function useFormField(
  value: string,
  error: string | undefined,
  onChange: (newValue: string) => void,
): Ret {
  return {
    value,
    error: !!error,
    helperText: error,
    onChange: e => onChange(e.target.value),
    onBlur: e => onChange(e.target.value),
  };
}
