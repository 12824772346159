import React, { useContext } from 'react';
import { StoresInput } from '../../../../schema';
import {
  makeStyles,
  Grid,
  FormControl,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import { StoreStatus } from '../../../../lib/types';
import { useTranslation } from 'react-i18next';
import BootstrapInput from '../../../../components/BootstrapInput/BootstrapInput';
import { StrictFilterState } from '../../../../components/RemoteDataTable/RemoteDataTable';
import { ChannelStatus } from '../../../../lib/types';
import { ClientConfigContext } from '../../../../lib/client-config';

const useStyles = makeStyles(theme => ({
  filterControl: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

const StoreFilter: React.FC<{
  state: StrictFilterState<StoresInput>;
  onChange: (value: StrictFilterState<StoresInput>) => void;
  areas: string[];
}> = ({ state, onChange, areas }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    config: { sortedVisibleStoreListColumns },
  } = useContext(ClientConfigContext);

  return (
    <Grid container>
      {sortedVisibleStoreListColumns.includes('STATUS') && (
        <Grid item xs={6} sm={4} md={3}>
          <FormControl variant="outlined" className={classes.filterControl}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('fields.store.status')}
            </Typography>
            <Select
              input={<BootstrapInput />}
              value={state.status}
              onChange={e =>
                onChange({
                  ...state,
                  status: e.target.value as StoreStatus,
                })
              }
            >
              {Object.keys(StoreStatus).map(status => (
                <MenuItem key={status} value={status}>
                  {t(`enums.StoreStatus.${status}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {sortedVisibleStoreListColumns.includes('AREA') && areas.length > 0 && (
        <Grid item xs={6} sm={4} md={3}>
          <FormControl variant="outlined" className={classes.filterControl}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('fields.store.area')}
            </Typography>
            <Select
              input={<BootstrapInput />}
              value={state.area}
              onChange={e =>
                onChange({
                  ...state,
                  area: String(e.target.value),
                })
              }
            >
              {areas.map(area => (
                <MenuItem key={area} value={area}>
                  {area}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {sortedVisibleStoreListColumns.includes('CHANNELS') && (
        <Grid item xs={6} sm={4} md={3}>
          <FormControl variant="outlined" className={classes.filterControl}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('fields.store.channel')}
            </Typography>
            <Select
              input={<BootstrapInput />}
              value={state.channels ? state.channels : null}
              onChange={e =>
                onChange({
                  ...state,
                  channels: e.target.value as ChannelStatus,
                })
              }
            >
              {Object.keys(ChannelStatus).map(channel => (
                <MenuItem key={channel} value={channel}>
                  {t(`enums.ChannelStatus.${channel}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {sortedVisibleStoreListColumns.includes('NOTES') && (
        <Grid item xs={6} sm={4} md={3}>
          <FormControl variant="outlined" className={classes.filterControl}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('fields.store.notes')}
            </Typography>
            <Select
              input={<BootstrapInput />}
              value={
                state.notes !== undefined && state.notes != null
                  ? state.notes
                    ? 'yes'
                    : 'no'
                  : null
              }
              onChange={e =>
                onChange({
                  ...state,
                  notes: e.target.value == 'yes' ? true : false,
                })
              }
            >
              <MenuItem value={'yes'}>{t('fields.store.yes')}</MenuItem>

              <MenuItem value={'no'}>{t('fields.store.no')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};
export default StoreFilter;
