import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

type InterfaceLanguageLocale = 'en-GB' | 'it-IT';

type InterfaceLanguage = {
  locale: InterfaceLanguageLocale;
};

const languages: InterfaceLanguage[] = [
  {
    // en-GB must be first in this list
    locale: 'en-GB',
  },
  {
    locale: 'it-IT',
  },
];

const defaultLanguage = languages[0];

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLanguage.locale,
    lng: defaultLanguage.locale,
    supportedLngs: languages.map(l => l.locale),
    load: 'currentOnly',
    interpolation: {
      escapeValue: false, // not needed for react
    },
    backend: {
      loadPath: '/locales/{{lng}}.json', // also has the {{ns}} param, but we don't use namespaces
    },
  })
  .catch(console.error);

export default i18n;
