import React from 'react';
import * as d3 from 'd3';
import ReactFauxDOM from 'react-faux-dom';
import { ChartData } from '../../views/DashboardView/FbIgDashboardSection';
import { Box, makeStyles } from '@material-ui/core';

type Props = {
  data: ChartData[];
};

const useStyles = makeStyles({
  lineChart: {
    '& .axis-grid line': {
      stroke: '#929292',
      strokeDasharray: '2 2',
    },
    '& .axis-grid-top': {
      fontWeight: 700,
      fontSize: '10px',
    },
    '& .axis-grid text, .axis-grid-2 text': {
      fontSize: '8px',
    },
    '& .axis-grid-top-2 text': {
      fontWeight: 700,
      fontSize: '10px',
    },
    '& .axis-grid-top-2 text.tick_yellow': {
      color: '#EB9700',
    },
    '& .axis-grid-top-2 text.tick_green': {
      color: '#439000',
    },
    '& .axis-grid-top-2 text.tick_red': {
      color: '#E60000',
    },
  },
});

// same plot function in backend/src/public/js/vodafone-2023-report/script.js
// in order to have same style for charts as in the pdf report
export const DashboardLineChart: React.FC<Props> = ({ data }) => {
  const faux = ReactFauxDOM.createElement('div');
  const graphWidth = 600;
  const graphHeight = 200;

  const classes = useStyles();

  // set the dimensions and margins of the graph
  const margin = { top: 50, right: 40, bottom: 30, left: 10 },
    width = graphWidth - margin.left - margin.right,
    height = graphHeight - margin.top - margin.bottom;

  // append the svg object to the body of the page
  const svg = d3
    .select(faux)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', graphHeight - 20)
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + 30 + ')');

  //separete array for each values on axis
  const monthArray: string[] = [];
  const yearArray: string[] = [];
  const scoreArray: number[] = [];
  const diffArray: number[] = [];

  data.map(item => {
    //item.date = new Date(item.date);
    monthArray.push(item.month);
    yearArray.push(item.year);
    scoreArray.push(item.value);
    diffArray.push(item.change);
  });

  // Add X axis
  const x = d3.scaleLinear().domain([0, 12]).range([0, width]);

  // tickFormat add month below x axis
  svg
    .append('g')
    .attr('class', 'x axis-grid')
    .attr('transform', 'translate(0,' + 5 + ')')
    .call(
      d3
        .axisBottom(x)
        .tickSize(height)
        .tickFormat((d, i) => monthArray[i])
        .ticks(13),
    )
    .select('.domain')
    .remove();

  // tickFormat add year below x axis
  svg
    .append('g')
    .attr('class', 'x axis-grid-2')
    .attr('transform', 'translate(0,' + (height + 15) + ')')
    .call(
      d3
        .axisBottom(x)
        .tickSize(0)
        .tickFormat((d, i) => yearArray[i])
        .ticks(13),
    )
    .select('.domain')
    .remove();

  // tickFormat add score above top axis
  svg
    .append('g')
    .attr('class', 'x axis-grid-top')
    .attr('transform', `translate(0,-12)`)
    .call(
      d3
        .axisTop(x)
        .tickSize(0)
        .tickFormat((d, i) => {
          if (scoreArray[i] === 0) {
            return '0';
          }
          return (
            new Intl.NumberFormat('it-IT', {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(scoreArray[i] / 1000) + 'K'
          );
        }),
    )
    .select('.domain')
    .remove();

  // tickFormat add diff % above top axis
  svg
    .append('g')
    .attr('class', 'x axis-grid-top-2')
    .attr('transform', `translate(0,-2)`)
    .call(
      d3
        .axisTop(x)
        .tickSize(0)
        .tickFormat((d, i) => diffTickFormat(d, i)),
    )
    .select('.domain')
    .remove();

  function diffTickFormat(d: unknown, i: number) {
    const change = diffArray[i];
    if (isNaN(Number(change))) {
      return '-';
    }
    if (i === 0) {
      return '-';
    }
    if (i > 0 && scoreArray[i - 1] === 0) {
      return '-';
    }
    return (
      new Intl.NumberFormat('it-IT', {
        signDisplay: 'always',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(100 * change) + '%'
    );
  }

  // find min and max value for Y axis
  const sorted = scoreArray.slice().sort(function (a, b) {
    return a - b;
  });
  const max = sorted[sorted.length - 1];
  const min = sorted[0];

  // Add Y axis
  const y = d3
    .scaleLinear()
    .domain([min - 1, max + 1])
    .range([height, 0]);
  svg
    .append('g')
    .call(
      d3
        .axisLeft(y)
        .tickSize(0)
        .tickFormat('' as any),
    )
    .select('.domain')
    .remove();

  // Align All ticks to right
  svg
    .selectAll(
      'g.x.axis-grid g.tick text, g.x.axis-grid-2 g.tick text, g.x.axis-grid-top g.tick text, g.x.axis-grid-top-2 g.tick text',
    )
    .style('text-anchor', 'start');

  // Add class for % coloring
  const ticks = svg.selectAll('.axis-grid-top-2 text');

  ticks.attr('class', function (d, i) {
    const change = diffArray[i];
    if (isNaN(change) || change == 0) {
      return 'tick_yellow';
    }
    return change > 0 ? 'tick_green' : 'tick_red';
  });

  // Add the line
  svg
    .append('path')
    .datum(data)
    .attr('fill', 'none')
    .attr('stroke', 'black')
    .attr('stroke-width', 1.5)
    .attr(
      'd',
      d3
        .line<any>()
        .x(function (_, i) {
          return x(i);
        })
        .y(function (d) {
          return y(d.value);
        }),
    );

  // Add the points
  svg
    .append('g')
    .selectAll('dot')
    .data(data)
    .enter()
    .append('circle')
    .attr('cx', function (_, i) {
      return x(i);
    })
    .attr('cy', function (d) {
      return y(d.value);
    })
    .attr('r', 5)
    .attr('fill', '#E60000');

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      style={{ background: '#fff' }}
      className={classes.lineChart}
    >
      {faux.toReact()}
    </Box>
  );
};
