import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Iteration, IterationVariables } from '../../../../schema';

const ITERATION = gql`
  query Iteration($storeId: Int!, $id: Int!) {
    iteration(storeId: $storeId, id: $id) {
      id
      permissions
      storeId
      startsAt
      endsBefore
      engagementScore
      usageScore
      responsivenessScore
      brandCommunicationQualityScore
      overallScore
      fbAggregatedStats {
        reach
        clicks
        impressions
        reactions
        comments
        shares
        likes
        posts
      }
      igAggregatedStats {
        reach
        impressions
        engagement
        saved
        likes
        comments
        followers
        posts
        interactions
      }
      gmbAggregatedStats {
        views
        actions_website
        actions_phone
        actions_driving_directions
        posts
        reviews {
          rated_one {
            answered
            unanswered
          }
          rated_two {
            answered
            unanswered
          }
          rated_three {
            answered
            unanswered
          }
          rated_four {
            answered
            unanswered
          }
          rated_five {
            answered
            unanswered
          }
          rated_one_and_two {
            answered
            unanswered
          }
          rated_three_and_four {
            answered
            unanswered
          }
        }
      }
      statistics {
        gmb {
          location_id
          reviews {
            comment
            name
            review_id
            star_rating
            create_time
            update_time
            reviewer {
              profile_photo_url
              display_name
            }
            review_reply {
              comment
              update_time
            }
          }
        }
      }
    }
  }
`;

export function useGetIterationQuery(variables: IterationVariables) {
  return useQuery<Iteration, IterationVariables>(ITERATION, {
    variables,
    fetchPolicy: 'network-only',
  });
}
