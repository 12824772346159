import React, { useState, useContext, useEffect } from 'react';
import {
  TableRow,
  TableCell,
  Checkbox,
  makeStyles,
  Grid,
  Box,
  Drawer,
  Tooltip,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Iterations_iterations_iterations as Iteration } from '../../../schema';
import * as _ from 'lodash';
import IterationActions from './components/IterationActions';
import RemoteDataTable, {
  StrictFilterState,
} from '../../../components/RemoteDataTable/RemoteDataTable';
import IterationFilter from './components/IterationFilter';
import { v4 as uuidv4 } from 'uuid';
import {
  GetCurrentUser_currentUser as User,
  PeriodsInput,
  Iterations,
} from '../../../schema';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import { useGetIterationsQuery } from '../../../lib/graphql/resolvers/queries/iterations';
import FullscreenLoading from '../../Layout/FullscreenLoading';
import InteractiveButton from '../../../components/InteractiveButton/InteractiveButton';
import { NotificationsContext } from '../../../lib/use-notifications';
import {
  downloadFile,
  iterationDatesToLabel,
  iterationKeyToDates,
  iterationsToOptions,
} from '../../../lib/util';
import { useGetIterationListQuery } from '../../../lib/graphql/resolvers/queries/iteration-list';
import { Permission, GlobalPermission } from '../../../lib/types';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { ClientConfigContext } from '../../../lib/client-config';
import { formatDate } from '../../../format-date';

const useStyles = makeStyles(theme => ({
  table: {},
  actionButton: {
    margin: 5,
  },
  spacer: {
    height: 200,
  },
  actionsDrawer: {
    '& .MuiPaper-root': {
      boxShadow: '0 -10px 10px #f7f7f7',
    },
  },
  exportButton: {
    '& button': {
      textTransform: 'capitalize',
      boxShadow: 'none',
      height: 56,
      marginLeft: 10,
    },
  },
  downloadReportButton: {
    textTransform: 'capitalize',
    boxShadow: 'none',
    marginRight: 10,
    '& button': {
      textTransform: 'capitalize',
      boxShadow: 'none',
    },
  },
}));

const IterationListView: React.FC<{
  user: User;
}> = ({ user }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { addNotification } = useContext(NotificationsContext);
  const [visibleIterations, setVisibleIterations] = useState<Iteration[]>([]);
  const [selectedIterations, setSelectedIterations] = useState<Iteration[]>([]);
  const [refreshKey, setRefreshKey] = useState('');
  const {
    config: { featureFlags, iterationLabelFormat },
  } = useContext(ClientConfigContext);

  const isIterationSelected = (iterationId: number) => {
    return selectedIterations
      .map(iteration => iteration.id)
      .includes(iterationId);
  };
  const onIterationToggled = (iteration: Iteration, checked: boolean) => {
    if (checked) {
      setSelectedIterations([...selectedIterations, iteration]);
    } else {
      const index = selectedIterations.indexOf(iteration);
      setSelectedIterations([
        ...selectedIterations.slice(0, index),
        ...selectedIterations.slice(index + 1),
      ]);
    }
  };
  const onAllIterationsToggled = (checked: boolean) => {
    if (checked) {
      setSelectedIterations(
        _.uniq([...selectedIterations, ...visibleIterations]),
      );
    } else {
      const visibleIterationIds = visibleIterations.map(
        iteration => iteration.id,
      );
      setSelectedIterations(
        selectedIterations.filter(
          iteration => !visibleIterationIds.includes(iteration.id),
        ),
      );
    }
  };
  const allVisibleIterationsSelected = (): boolean => {
    const visibleIterationIds = visibleIterations.map(
      iteration => iteration.id,
    );
    return (
      selectedIterations.filter(iteration =>
        visibleIterationIds.includes(iteration.id),
      ).length == visibleIterationIds.length
    );
  };
  const noVisibleIterationSelected = (): boolean => {
    const visibleIterationIds = visibleIterations.map(
      iteration => iteration.id,
    );
    return (
      selectedIterations.filter(iteration =>
        visibleIterationIds.includes(iteration.id),
      ).length == 0
    );
  };

  const taxonomies = {
    iterations: useGetIterationListQuery({ input: {} }),
  };

  const getReport = async (iteration: Iteration) => {
    const path = `reports/iteration-report-pdf/${iteration.id}`;
    try {
      await downloadFile(path);
    } catch (e) {
      const error = e as Error;
      addNotification({
        message: error.message,
        variant: 'error',
      });
    }
  };

  const filterFactory = (
    state: StrictFilterState<PeriodsInput>,
    onChange: (value: StrictFilterState<PeriodsInput>) => void,
  ) => (
    <IterationFilter
      user={user}
      state={state}
      onChange={onChange}
      periods={
        taxonomies.iterations.data
          ? taxonomies.iterations.data.iterationList.iterations
          : []
      }
    />
  );

  const canViewIterationStatus = user.globalPermissions.includes(
    GlobalPermission.ACCESS_TASK_LIST,
  );

  const [downloadReportIteration, setDownloadReportIteration] = useState('');

  const hasKpi = featureFlags.includes('CAN_MANAGE_TASKS');

  const [autoDownloadingReport, setAutoDownloadingReport] = useState(false);
  useEffect(() => {
    const params = new URL(document.location.href).searchParams;
    const autoDownloadReportIteration = params.get('download-iteration-report');
    if (autoDownloadReportIteration) {
      setAutoDownloadingReport(true);
      getReport(({
        id: autoDownloadReportIteration,
      } as unknown) as Iteration)
        .then(() => setAutoDownloadingReport(false))
        .catch(e => {
          let message = 'Something went wrong';
          if (e instanceof Error) {
            message = e.message;
          }
          addNotification({ variant: 'error', message });
        });
    }

    // it is correct to have empty array here- this hook must run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (taxonomies.iterations.loading) {
    return <FullscreenLoading />;
  }

  if (taxonomies.iterations.error) {
    return <h1>{String(taxonomies.iterations.error)}</h1>;
  }

  return (
    <>
      {autoDownloadingReport && <FullscreenLoading />}

      <Drawer
        anchor={'bottom'}
        open={selectedIterations.length > 0}
        variant="persistent"
        className={classes.actionsDrawer}
      >
        {selectedIterations.length > 0 && (
          <IterationActions
            selectedIterations={selectedIterations}
            actionDone={() => {
              setSelectedIterations([]);
              setRefreshKey(uuidv4());
            }}
            unselectAll={() => setSelectedIterations([])}
          />
        )}
      </Drawer>

      <Grid container>
        <Grid item xs={12} md={12}>
          {user.globalPermissions.includes(
            GlobalPermission.GET_ITERATIONS_REPORT,
          ) &&
            taxonomies.iterations.data && (
              <Box>
                <Grid item xs={12}>
                  <Box p={3}>
                    <Select
                      value={downloadReportIteration}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                        setDownloadReportIteration(String(e.target.value))
                      }
                    >
                      {iterationsToOptions(
                        taxonomies.iterations.data.iterationList.iterations,
                        iterationLabelFormat,
                        t,
                      ).map((opt, index) => (
                        <MenuItem key={index} value={opt.value}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <InteractiveButton
                      disabled={!downloadReportIteration}
                      className={classes.downloadReportButton}
                      variant="outlined"
                      color="primary"
                      label={t('components.iterationList.downloadReport')}
                      onClick={async () => {
                        const iteration = iterationKeyToDates(
                          downloadReportIteration,
                        );
                        const path = `reports/iterations/${iteration.startsAt}/${iteration.endsBefore}`;
                        try {
                          await downloadFile(path);
                        } catch (e) {
                          const error = e as Error;
                          addNotification({
                            message: error.message,
                            variant: 'error',
                          });
                        }
                      }}
                    />
                  </Box>
                </Grid>
              </Box>
            )}

          <RemoteDataTable
            onDataChanged={(iterations: Iteration[]) =>
              setVisibleIterations(iterations)
            }
            cacheId="iterations-list"
            searchable={true}
            renderHeaderRow={() => (
              <TableRow>
                <TableCell component="th">
                  {t('components.iterationList.period')}
                </TableCell>
                <TableCell component="th">
                  {t('components.iterationList.remoteId')}
                </TableCell>
                <TableCell component="th">
                  {t('components.iterationList.companyName')}
                </TableCell>
                {hasKpi && (
                  <>
                    <TableCell component="th">
                      {t('components.iterationList.usageScore')}
                    </TableCell>
                    <TableCell component="th">
                      {t(
                        'components.iterationList.brandCommunicationQualityScore',
                      )}
                    </TableCell>
                    <TableCell component="th">
                      {t('components.iterationList.engagementScore')}
                    </TableCell>
                    <TableCell component="th">
                      {t('components.iterationList.responsivenessScore')}
                    </TableCell>
                    <TableCell component="th">
                      {t('components.iterationList.overallScore')}
                    </TableCell>
                  </>
                )}
                <TableCell component="th"></TableCell>
                {canViewIterationStatus && (
                  <TableCell component="th"></TableCell>
                )}
                <TableCell component="th" padding="checkbox">
                  <Checkbox
                    indeterminate={
                      !(
                        noVisibleIterationSelected() ||
                        allVisibleIterationsSelected()
                      )
                    }
                    checked={allVisibleIterationsSelected()}
                    onChange={(event: React.ChangeEvent, checked: boolean) =>
                      onAllIterationsToggled(checked)
                    }
                    inputProps={{
                      'aria-label': 'select all visible iterations',
                    }}
                  />
                </TableCell>
              </TableRow>
            )}
            renderDataRow={(iteration: Iteration) => {
              return (
                <TableRow key={iteration.id}>
                  <TableCell>
                    {iterationDatesToLabel(iteration, iterationLabelFormat, t)}
                  </TableCell>
                  <TableCell>{iteration.store.remoteId}</TableCell>
                  <TableCell>{iteration.store.companyName}</TableCell>
                  {hasKpi && (
                    <>
                      <TableCell>{iteration.usageScore}</TableCell>
                      <TableCell>
                        {iteration.brandCommunicationQualityScore}
                      </TableCell>
                      <TableCell>{iteration.engagementScore}</TableCell>
                      <TableCell>{iteration.responsivenessScore}</TableCell>
                      <TableCell>{iteration.overallScore}</TableCell>
                    </>
                  )}
                  <TableCell style={{ textAlign: 'center' }}>
                    {iteration.permissions.includes(
                      Permission.ITERATION_GET_REPORT,
                    ) && (
                      <>
                        <InteractiveButton
                          variant="outlined"
                          color="primary"
                          onClick={() => getReport(iteration)}
                          icon={<PictureAsPdfRoundedIcon />}
                        />
                        {iteration.reportDate && (
                          <div>{formatDate(iteration.reportDate)}</div>
                        )}
                      </>
                    )}
                  </TableCell>
                  {canViewIterationStatus && (
                    <TableCell>
                      {iteration.outdated && (
                        <Tooltip
                          title={
                            t(
                              'components.iterationList.outdatedIteration',
                            ) as string
                          }
                        >
                          <ErrorRoundedIcon
                            style={{ color: 'red' }}
                          ></ErrorRoundedIcon>
                        </Tooltip>
                      )}
                    </TableCell>
                  )}
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="default"
                      checked={isIterationSelected(iteration.id)}
                      onChange={(event: React.ChangeEvent, checked: boolean) =>
                        onIterationToggled(iteration, checked)
                      }
                      inputProps={{ 'aria-label': 'select this row' }}
                    />
                  </TableCell>
                </TableRow>
              );
            }}
            useQuery={useGetIterationsQuery}
            parseData={(data: Iterations) => data.iterations.iterations}
            parseFilteredCount={(data: Iterations) =>
              data.iterations.filteredCount
            }
            filter={{
              emptyState: {},
              factory: filterFactory,
            }}
            refreshKey={refreshKey}
            overwriteCachedFilter={cachedFilter => {
              // due to some breaking changes year and month does not exist anymore but
              // could still exist in cached filter value (causing input to fail at graphql level)
              delete cachedFilter['year'];
              delete cachedFilter['month'];
            }}
          />
        </Grid>
      </Grid>
      {/** add space so as stores are hot covered by drawer */}
      {selectedIterations.length > 0 && <Box className={classes.spacer}></Box>}
    </>
  );
};
export default IterationListView;
