import { Box, Link, makeStyles } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  found: number;
};

const useStyles = makeStyles({
  feedbackBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    marginBottom: '1.5rem',
    fontSize: '0.875rem',
    width: '100%',
    borderRadius: '5px',
  },
  icon: {
    margin: '10px 20px 10px 10px',
  },
  errorBox: {
    color: 'rgb(97, 26, 21)',
    backgroundColor: 'rgb(253, 236, 234)',
  },
  errorIcon: {
    color: '#f44336',
  },
  warningBox: {
    color: 'rgb(102, 60, 0)',
    backgroundColor: 'rgb(255, 244, 229)',
  },
  warningIcon: {
    color: '#ff9800',
  },
  noIssue: {
    color: 'rgb(30, 70, 32)',
    backgroundColor: 'rgb(237, 247, 237)',
  },
  noIssueIcon: {
    color: '#4caf50',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    fontSize: '0.7rem',
  },
});

export const PasswordBreachedWarning: React.FC<Props> = ({ found }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={[classes.warningBox, classes.feedbackBox].join(' ')}>
      <ReportProblemOutlined
        className={[classes.icon, classes.warningIcon].join(' ')}
        fontSize="large"
      />
      <div className={classes.errorContainer}>
        <span>{t('components.password-breached-warning.description')}</span>
        <span>
          {t('components.password-breached-warning.times-seen', { found })}
        </span>
        <Box mt="5px">
          <em className={classes.link}>
            {t('components.password-breached-warning.powered-by')}{' '}
            <Link href="https://haveibeenpwned.com/Passwords">
              haveibeenpwned.com
            </Link>
          </em>
        </Box>
      </div>
    </Box>
  );
};
