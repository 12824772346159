import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import { SetTaskDone, SetTaskDoneVariables } from '../../../../schema';

const SET_TASK_DONE = gql`
  mutation SetTaskDone($input: SetTaskDoneInput!) {
    setTaskDone(input: $input) {
      task {
        id
        permissions
      }
    }
  }
`;

export function useSetTaskDoneMutation() {
  return useDirectMutation<SetTaskDone, SetTaskDoneVariables>(SET_TASK_DONE);
}
