import { Dispatch, useReducer } from 'react';
import UnreachableCaseError from '../../lib/unreachable-case.error';
import { Dashboard_dashboard } from '../../schema';

export type IterationPeriod = {
  label: string;
  startsAt: string;
};
export type ChartMetric = 'followers' | 'reach' | 'impressions';
export type Dashboard = Dashboard_dashboard;

type DashboardState = {
  area: string;
  isFrozen: boolean;
  iterationAnalyzed?: IterationPeriod;
  iterationCompared?: IterationPeriod;
  fbSelectedMetric: ChartMetric;
  igSelectedMetric: ChartMetric;
  facebookMetrics?: Dashboard['facebook'];
  instagramMetrics?: Dashboard['instagram'];
  gmbMetrics?: Dashboard['gmb'];
  scoresMetrics?: Dashboard['scores'];
  iterationList: IterationPeriod[];
};

type SetArea = {
  type: 'SET_AREA';
  payload: string;
};

export type SetIterationAnalyzed = {
  type: 'SET_ITERATION_ANALYZED';
  payload: IterationPeriod;
};

export type SetIterationCompared = {
  type: 'SET_ITERATION_COMPARED';
  payload: IterationPeriod;
};

export type SetFbSelectedMetric = {
  type: 'SET_FB_SELECTED_METRIC';
  payload: ChartMetric;
};

export type SetIgSelectedMetric = {
  type: 'SET_IG_SELECTED_METRIC';
  payload: ChartMetric;
};

type SetMetrics = {
  type: 'SET_METRICS';
  payload: {
    isFrozen: boolean;
    facebookMetrics?: Dashboard['facebook'];
    instagramMetrics?: Dashboard['instagram'];
    gmbMetrics?: Dashboard['gmb'];
    scoresMetrics?: Dashboard['scores'];
  };
};

type Reset = {
  type: 'RESET';
};

type SetIterationList = {
  type: 'SET_ITERATION_LIST';
  payload: IterationPeriod[];
};

export const ALL_AREAS_OPTION = 'all-areas';

export type DashboardAction =
  | SetArea
  | SetIterationAnalyzed
  | SetIterationCompared
  | SetFbSelectedMetric
  | SetIgSelectedMetric
  | SetMetrics
  | SetIterationList
  | Reset;

const initialState: DashboardState = {
  isFrozen: true, // default to true to hide warning message
  iterationAnalyzed: undefined,
  iterationCompared: undefined,
  area: ALL_AREAS_OPTION,
  facebookMetrics: undefined,
  instagramMetrics: undefined,
  gmbMetrics: undefined,
  scoresMetrics: undefined,
  fbSelectedMetric: 'followers',
  igSelectedMetric: 'followers',
  iterationList: [],
};

function reducer(
  state: DashboardState,
  action: DashboardAction,
): DashboardState {
  switch (action.type) {
    case 'SET_AREA':
      return {
        ...state,
        area: action.payload,
      };
    case 'SET_ITERATION_ANALYZED':
      return {
        ...state,
        iterationAnalyzed: action.payload,
      };
    case 'SET_ITERATION_COMPARED':
      return {
        ...state,
        iterationCompared: action.payload,
      };
    case 'SET_FB_SELECTED_METRIC':
      return {
        ...state,
        fbSelectedMetric: action.payload,
      };
    case 'SET_IG_SELECTED_METRIC':
      return {
        ...state,
        igSelectedMetric: action.payload,
      };
    case 'SET_METRICS':
      return {
        ...state,
        isFrozen: action.payload.isFrozen,
        facebookMetrics: action.payload.facebookMetrics,
        instagramMetrics: action.payload.instagramMetrics,
        gmbMetrics: action.payload.gmbMetrics,
        scoresMetrics: action.payload.scoresMetrics,
      };
    case 'SET_ITERATION_LIST':
      return {
        ...state,
        iterationList: action.payload,
      };
    case 'RESET':
      return {
        ...initialState,
        iterationList: state.iterationList,
      };
    default:
      throw new UnreachableCaseError(action);
  }
}

export function useDashboardReducer(): [
  DashboardState,
  Dispatch<DashboardAction>,
] {
  return useReducer(reducer, initialState);
}
