import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import { UpdateStore, UpdateStoreVariables } from '../../../../schema';

const UPDATE_STORE = gql`
  mutation UpdateStore($input: UpsertStoreInput!) {
    updateStore(input: $input) {
      store {
        id
      }
    }
  }
`;

export function useUpdateStoreMutation() {
  return useDirectMutation<UpdateStore, UpdateStoreVariables>(UPDATE_STORE);
}
