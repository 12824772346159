import { useDirectQuery } from '../../graphql';
import gql from 'graphql-tag';
import { Dashboard, DashboardVariables } from '../../../../schema';

const GET_DASHBOARD = gql`
  query Dashboard($input: DashboardInput!) {
    dashboard(input: $input) {
      isFrozen
      facebook {
        analyzedStores
        followers {
          num
          change
          trend {
            label
            value
            change
          }
        }
        reach {
          num
          change
          trend {
            label
            value
            change
          }
        }
        impressions {
          num
          change
          trend {
            label
            value
            change
          }
        }
      }
      instagram {
        analyzedStores
        followers {
          num
          change
          trend {
            label
            value
            change
          }
        }
        reach {
          num
          change
          trend {
            label
            value
            change
          }
        }
        impressions {
          num
          change
          trend {
            label
            value
            change
          }
        }
      }
      gmb {
        oneAndTwo {
          received {
            num
            change
          }
          managed {
            num
            change
          }
          responseRate {
            num
            change
          }
          compliant {
            num
            change
          }
          notCompliant {
            num
            change
          }
        }
        threeAndFour {
          received {
            num
            change
          }
          managed {
            num
            change
          }
          responseRate {
            num
            change
          }
          compliant {
            num
            change
          }
          notCompliant {
            num
            change
          }
        }
        five {
          received {
            num
            change
          }
          managed {
            num
            change
          }
          responseRate {
            num
            change
          }
          compliant {
            num
            change
          }
          notCompliant {
            num
            change
          }
        }
        total {
          received {
            num
            change
          }
          managed {
            num
            change
          }
          responseRate {
            num
            change
          }
          compliant {
            num
            change
          }
          notCompliant {
            num
            change
          }
        }
      }
      scores {
        avg {
          usage {
            num
            change
          }
          engagement {
            num
            change
          }
          brandCommunicationQuality {
            num
            change
          }
          responsiveness {
            num
            change
          }
          overall {
            num
            change
          }
        }
        distribution {
          usage {
            val
            refVal
            label
          }
          engagement {
            val
            refVal
            label
          }
          brandCommunicationQuality {
            val
            refVal
            label
          }
          responsiveness {
            val
            refVal
            label
          }
          overall {
            val
            refVal
            label
          }
        }
        bestStores {
          usage {
            label
            change
          }
          engagement {
            label
            change
          }
          brandCommunicationQuality {
            label
            change
          }
          responsiveness {
            label
            change
          }
          overall {
            label
            change
          }
        }
        worstStores {
          usage {
            label
            change
          }
          engagement {
            label
            change
          }
          brandCommunicationQuality {
            label
            change
          }
          responsiveness {
            label
            change
          }
          overall {
            label
            change
          }
        }
      }
    }
  }
`;

export function useGetDashboardDirectQuery() {
  return useDirectQuery<Dashboard, DashboardVariables>(GET_DASHBOARD);
}
