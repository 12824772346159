import React, { useState } from 'react';
import {
  Box,
  Grid,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TaskType } from '../../../lib/types';
import RemoteDataTable, {
  StrictFilterState,
} from '../../../components/RemoteDataTable/RemoteDataTable';
import TaskPerStoreFilter from './components/TaskPerStoreFilter';
import {
  Store_store as Store,
  TasksInput,
  TasksPerStore,
  TasksPerStore_tasks_tasks as Task,
} from '../../../schema';
import { v4 as uuidv4 } from 'uuid';
import TaskBox from './components/TaskBox';
import { useParams } from 'react-router-dom';
import { useGetStoreQuery } from '../../../lib/graphql/resolvers/queries/store';
import { useGetIterationListQuery } from '../../../lib/graphql/resolvers/queries/iteration-list';
import FullscreenLoading from '../../Layout/FullscreenLoading';
import { useGetTasksPerStoreQuery } from '../../../lib/graphql/resolvers/queries/tasks-per-store';

const tabs: TaskType[] = [
  TaskType.FACEBOOK_POST,
  TaskType.INSTAGRAM_POST,
  TaskType.GOOGLE_MYBUSINESS_POST,
  TaskType.FACEBOOK_PAGE,
  TaskType.GOOGLE_MYBUSINESS_REVIEW,
];

const TaskListPerStoreView: React.FC<{
  store: Store;
  initialTaskType: TaskType;
}> = ({ store, initialTaskType }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(tabs.indexOf(initialTaskType));
  const [taskListRefreshKey, setTaskListRefreshKey] = useState('');

  const { loading, data, error } = useGetIterationListQuery({
    input: { storeId: store.id },
  });

  if (loading) {
    return <FullscreenLoading />;
  }

  if (error) {
    return <h1>{String(error)}</h1>;
  }

  if (!data || !data.iterationList) {
    return <h1>{t('components.taskList.noPeriodsAvailable')}</h1>;
  }

  const changeVisibleTasks = (event: unknown, value: unknown) => {
    setActiveTab(value as number);
    /*setTaskListRefreshKey(uuidv4());*/
  };

  const filterFactory = (
    state: StrictFilterState<TasksInput>,
    onChange: (value: StrictFilterState<TasksInput>) => void,
  ) => (
    <TaskPerStoreFilter
      state={state}
      onChange={onChange}
      iterationOptions={data.iterationList.iterations}
    />
  );

  return (
    <>
      <Box p={3} style={{ background: '#fff' }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">
            {store.remoteId} - {store.companyName}
          </Typography>
        </Box>
      </Box>
      <Tabs
        style={{ background: '#fff' }}
        value={activeTab}
        onChange={changeVisibleTasks}
        aria-label="task tabs"
        variant="fullWidth"
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={t(`enums.TaskType.${tab}`)} />
        ))}
      </Tabs>
      <Grid container>
        <Grid item xs={12}>
          <RemoteDataTable
            cacheId="per-store-tasks-list"
            searchable={false}
            renderDataRow={(task: Task) => (
              <TableRow key={task.id}>
                <TableCell>
                  <TaskBox
                    task={task}
                    onEdit={() => setTaskListRefreshKey(uuidv4())}
                  />
                </TableCell>
              </TableRow>
            )}
            useQuery={useGetTasksPerStoreQuery}
            parseData={(data: TasksPerStore) => data.tasks.tasks}
            parseFilteredCount={(data: TasksPerStore) =>
              data.tasks.filteredCount
            }
            filter={{
              emptyState: {
                iterationStartsAt: '',
                iterationEndsBefore: '',
                status: '',
              },
              factory: filterFactory,
            }}
            refreshKey={taskListRefreshKey}
            externalFilter={{
              storeId: store.id,
              type: tabs[activeTab],
            }}
            overwriteCachedFilter={cachedFilter => {
              // due to some breaking changes year and month does not exist anymore but
              // could still exist in cached filter value (causing input to fail at graphql level)
              delete cachedFilter['year'];
              delete cachedFilter['month'];
            }}
            pageSize={5}
          />
        </Grid>
      </Grid>
    </>
  );
};

const PreloadedTaskListPerStoreView: React.FC = () => {
  const { t } = useTranslation();
  const { remoteId, taskType } = useParams<{
    remoteId: string;
    taskType: string;
  }>();
  const { loading, data, error } = useGetStoreQuery({ remoteId });

  if (loading) {
    return <FullscreenLoading />;
  }

  if (error) {
    return <h1>{String(error)}</h1>;
  }

  if (!data || !data.store) {
    return <h1>{t('components.storeView.empty')}</h1>;
  }
  const validTaskTypes: Record<TaskType, ''> = {
    FACEBOOK_PAGE: '',
    FACEBOOK_POST: '',
    GOOGLE_MYBUSINESS_POST: '',
    GOOGLE_MYBUSINESS_REVIEW: '',
    INSTAGRAM_POST: '',
  };
  if (!Object.keys(validTaskTypes).includes(taskType)) {
    return <h1>Invalid task type {taskType}</h1>;
  }

  return (
    <TaskListPerStoreView
      store={data.store}
      initialTaskType={taskType as TaskType}
    />
  );
};

export default PreloadedTaskListPerStoreView;
