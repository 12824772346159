import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import {
  RefreshStoreStatistics,
  RefreshStoreStatisticsVariables,
} from '../../../../schema';

const REFRESH_STORE_STATISTICS = gql`
  mutation RefreshStoreStatistics($input: RefreshStatisticsInput!) {
    refreshStatistics(input: $input) {
      message
    }
  }
`;

export function useRefreshStoreStatisticsMutation() {
  return useDirectMutation<
    RefreshStoreStatistics,
    RefreshStoreStatisticsVariables
  >(REFRESH_STORE_STATISTICS);
}
