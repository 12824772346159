import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  statBox: {
    background: '#eee',
    borderRadius: 7,
  },
}));

const StatBox: React.FC<{
  label: string;
  value: string | number;
  color: string;
}> = ({ label, value, color }) => {
  const classes = useStyles();
  return (
    <Box p={2}>
      <Box
        p={1}
        display="flex"
        justifyContent="space-between"
        className={classes.statBox}
      >
        <Typography variant="body1">{label}</Typography>
        <Box color={color}>
          <Typography variant="body1">{value}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default StatBox;
