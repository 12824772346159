import React, { useContext } from 'react';
import { TasksInput } from '../../../../schema';
import {
  makeStyles,
  Grid,
  FormControl,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import { TaskStatus } from '../../../../lib/types';
import { useTranslation } from 'react-i18next';
import BootstrapInput from '../../../../components/BootstrapInput/BootstrapInput';
import { StrictFilterState } from '../../../../components/RemoteDataTable/RemoteDataTable';
import { IterationList_iterationList_iterations as IterationItem } from '../../../../schema';
import * as _ from 'lodash';
import { ClientConfigContext } from '../../../../lib/client-config';
import {
  iterationDatesToKey,
  iterationKeyToDates,
  iterationsToOptions,
} from '../../../../lib/util';

const useStyles = makeStyles(theme => ({
  filterControl: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

const TaskPerStoreFilter: React.FC<{
  iterationOptions: IterationItem[];
  state: StrictFilterState<TasksInput>;
  onChange: (value: StrictFilterState<TasksInput>) => void;
}> = ({ iterationOptions, state, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    config: { iterationLabelFormat },
  } = useContext(ClientConfigContext);

  return (
    <Grid container>
      <Grid item xs={6} sm={4} md={3}>
        <FormControl variant="outlined" className={classes.filterControl}>
          <Typography variant="subtitle2" color="textSecondary">
            {t('fields.task.status')}
          </Typography>
          <Select
            input={<BootstrapInput />}
            value={state.status}
            onChange={e =>
              onChange({
                ...state,
                status: e.target.value as TaskStatus,
              })
            }
          >
            {Object.keys(TaskStatus).map(status => (
              <MenuItem key={status} value={status}>
                {t(`enums.TaskStatus.${status}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <FormControl variant="outlined" className={classes.filterControl}>
          <Typography variant="subtitle2" color="textSecondary">
            {t('fields.task.period')}
          </Typography>
          <Select
            input={<BootstrapInput />}
            value={
              state.iterationStartsAt && state.iterationEndsBefore
                ? iterationDatesToKey({
                    startsAt: state.iterationStartsAt,
                    endsBefore: state.iterationEndsBefore,
                  })
                : ''
            }
            onChange={e => {
              const it = iterationKeyToDates(String(e.target.value));
              onChange({
                ...state,
                iterationStartsAt: it.startsAt,
                iterationEndsBefore: it.endsBefore,
              });
            }}
          >
            {iterationsToOptions(iterationOptions, iterationLabelFormat, t).map(
              (entry, index) => (
                <MenuItem key={index} value={entry.value}>
                  {entry.label}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
export default TaskPerStoreFilter;
