import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Store, StoreVariables } from '../../../../schema';

const GET_STORE = gql`
  query Store($remoteId: String!) {
    store(remoteId: $remoteId) {
      id
      remoteId
      createdAt
      updatedAt
      contactName
      companyName
      area
      phone
      website
      status
      permissions
      iterations {
        id
        startsAt
        endsBefore
      }
    }
    currentUser {
      id
      email
      role
      globalPermissions
    }
  }
`;

export function useGetStoreQuery(variables: StoreVariables) {
  return useQuery<Store, StoreVariables>(GET_STORE, {
    variables,
    fetchPolicy: 'network-only',
  });
}
