import { useDirectQuery } from '../../graphql';
import gql from 'graphql-tag';
import { IterationList, IterationListVariables } from '../../../../schema';
import { useQuery } from 'react-apollo';

const GET_ITERATION_LIST = gql`
  query IterationList($input: IterationListInput!) {
    iterationList(input: $input) {
      iterations {
        startsAt
        endsBefore
      }
    }
  }
`;

export function useGetIterationListDirectQuery() {
  return useDirectQuery<IterationList, IterationListVariables>(
    GET_ITERATION_LIST,
  );
}

export function useGetIterationListQuery(variables: IterationListVariables) {
  return useQuery<IterationList, IterationListVariables>(GET_ITERATION_LIST, {
    variables,
    fetchPolicy: 'network-only',
  });
}
