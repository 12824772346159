import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import {
  SetPublishingTarget,
  SetPublishingTargetVariables,
} from '../../../../schema';

const SET_PUBLISHING_TARGET = gql`
  mutation SetPublishingTarget($input: SetPublishingTargetInput!) {
    setPublishingTarget(input: $input) {
      target {
        startsAt
        endsBefore
        target
      }
    }
  }
`;

export function useSetPublishingTargetMutation() {
  return useDirectMutation<SetPublishingTarget, SetPublishingTargetVariables>(
    SET_PUBLISHING_TARGET,
  );
}
