import React, { useRef } from 'react';
import { makeStyles, Button, Box } from '@material-ui/core';
import PostImage from '../PostImage/PostImage';
import DOMPurify from 'dompurify';
import LaunchIcon from '@material-ui/icons/Launch';
import { useTranslation } from 'react-i18next';
//import twemoji from 'twemoji';
import { TasksPerStore_tasks_tasks_media as Media } from '../../schema';

const useStyles = makeStyles({
  postContainer: {
    border: '1px solid #cdcdcd',
    width: '100%',
  },
  messageContainer: {
    padding: 15,
    fontSize: 14,
    wordWrap: 'break-word',
    fontWeight: 'normal',
    lineHeight: 1.38,
    '& .emoji': {
      width: 14,
      height: 14,
    },
  },
});

const Post: React.FC<{
  message: string | null;
  image: Media | null;
  previewUrl: string | null;
}> = ({ message, image, previewUrl }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (previewUrl) {
      window.open(
        previewUrl,
        '_blank',
        `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,width=600,height=700`,
      ); // need to specify height too
    }
  };

  const postMessageRef = useRef<HTMLDivElement | null>(null);

  /*useEffect(() => {
    if (postMessageRef.current) {
      twemoji.parse(postMessageRef.current);
    }
  });*/

  return (
    <>
      <Box className={classes.postContainer}>
        {image && <PostImage image={image} previewUrl={previewUrl} />}
        {message && (
          <Box className={classes.messageContainer}>
            <div
              ref={postMessageRef}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
            />
          </Box>
        )}
      </Box>
      <Box alignItems="flex">
        {previewUrl && (
          <Button
            color="primary"
            onClick={onClick}
            endIcon={<LaunchIcon></LaunchIcon>}
          >
            {t('postPreview.viewOriginal')}
          </Button>
        )}
      </Box>
    </>
  );
};

export default Post;
