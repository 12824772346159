export const LOCALES = {
  af: 'Afrikaans',
  sq: 'Albanian',
  am: 'Amharic',
  'ar-DZ': 'Arabic - Algeria',
  'ar-BH': 'Arabic - Bahrain',
  'ar-EG': 'Arabic - Egypt',
  'ar-IQ': 'Arabic - Iraq',
  'ar-JO': 'Arabic - Jordan',
  'ar-KW': 'Arabic - Kuwait',
  'ar-LB': 'Arabic - Lebanon',
  'ar-LY': 'Arabic - Libya',
  'ar-MA': 'Arabic - Morocco',
  'ar-OM': 'Arabic - Oman',
  'ar-QA': 'Arabic - Qatar',
  'ar-SA': 'Arabic - Saudi Arabia',
  'ar-SY': 'Arabic - Syria',
  'ar-TN': 'Arabic - Tunisia',
  'ar-AE': 'Arabic - United Arab Emirates',
  'ar-YE': 'Arabic - Yemen',
  hy: 'Armenian',
  as: 'Assamese',
  'az-AZ': 'Azeri - Cyrillic',
  eu: 'Basque',
  be: 'Belarusian',
  bn: 'Bengali - Bangladesh',
  bs: 'Bosnian',
  bg: 'Bulgarian',
  my: 'Burmese',
  ca: 'Catalan',
  'zh-CN': 'Chinese - China',
  'zh-HK': 'Chinese - Hong Kong SAR',
  'zh-MO': 'Chinese - Macau SAR',
  'zh-SG': 'Chinese - Singapore',
  'zh-TW': 'Chinese - Taiwan',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  dv: 'Divehi; Dhivehi; Maldivian',
  'nl-BE': 'Dutch - Belgium',
  'nl-NL': 'Dutch',
  'en-AU': 'English - Australia',
  'en-BZ': 'English - Belize',
  'en-CA': 'English - Canada',
  'en-CB': 'English - Caribbean',
  'en-GB': 'English',
  'en-IN': 'English - India',
  'en-IE': 'English - Ireland',
  'en-JM': 'English - Jamaica',
  'en-NZ': 'English - New Zealand',
  'en-PH': 'English - Phillippines',
  'en-ZA': 'English - Southern Africa',
  'en-TT': 'English - Trinidad',
  'en-US': 'English - United States',
  et: 'Estonian',
  fo: 'Faroese',
  fa: 'Farsi - Persian',
  fi: 'Finnish',
  'fr-BE': 'French - Belgium',
  'fr-CA': 'French - Canada',
  'fr-FR': 'French',
  'fr-LU': 'French - Luxembourg',
  'fr-CH': 'French - Switzerland',
  mk: 'FYRO Macedonia',
  'gd-IE': 'Gaelic - Ireland',
  gd: 'Gaelic - Scotland',
  'de-AT': 'German - Austria',
  'de-DE': 'German',
  'de-LI': 'German - Liechtenstein',
  'de-LU': 'German - Luxembourg',
  'de-CH': 'German - Switzerland',
  el: 'Greek',
  gn: 'Guarani - Paraguay',
  gu: 'Gujarati',
  he: 'Hebrew',
  hi: 'Hindi',
  hu: 'Hungarian',
  is: 'Icelandic',
  id: 'Indonesian',
  'it-IT': 'Italian',
  'it-CH': 'Italian - Switzerland',
  ja: 'Japanese',
  kn: 'Kannada',
  ks: 'Kashmiri',
  kk: 'Kazakh',
  km: 'Khmer',
  ko: 'Korean',
  lo: 'Lao',
  la: 'Latin',
  lv: 'Latvian',
  lt: 'Lithuanian',
  'ms-BN': 'Malay - Brunei',
  'ms-MY': 'Malay - Malaysia',
  ml: 'Malayalam',
  mt: 'Maltese',
  mi: 'Maori',
  mr: 'Marathi',
  mn: 'Mongolian',
  ne: 'Nepali',
  'no-NO': 'Norwegian - Bokml',
  or: 'Oriya',
  pl: 'Polish',
  'pt-BR': 'Portuguese - Brazil',
  'pt-PT': 'Portuguese - Portugal',
  pa: 'Punjabi',
  rm: 'Raeto-Romance',
  'ro-MO': 'Romanian - Moldova',
  ro: 'Romanian - Romania',
  ru: 'Russian',
  'ru-MO': 'Russian - Moldova',
  sa: 'Sanskrit',
  'sr-SP': 'Serbian - Cyrillic',
  tn: 'Setsuana',
  sd: 'Sindhi',
  si: 'Sinhala; Sinhalese',
  sk: 'Slovak',
  sl: 'Slovenian',
  so: 'Somali',
  sb: 'Sorbian',
  'es-AR': 'Spanish - Argentina',
  'es-BO': 'Spanish - Bolivia',
  'es-CL': 'Spanish - Chile',
  'es-CO': 'Spanish - Colombia',
  'es-CR': 'Spanish - Costa Rica',
  'es-DO': 'Spanish - Dominican Republic',
  'es-EC': 'Spanish - Ecuador',
  'es-SV': 'Spanish - El Salvador',
  'es-GT': 'Spanish - Guatemala',
  'es-HN': 'Spanish - Honduras',
  'es-MX': 'Spanish - Mexico',
  'es-NI': 'Spanish - Nicaragua',
  'es-PA': 'Spanish - Panama',
  'es-PY': 'Spanish - Paraguay',
  'es-PE': 'Spanish - Peru',
  'es-PR': 'Spanish - Puerto Rico',
  'es-ES': 'Spanish',
  'es-UY': 'Spanish - Uruguay',
  'es-VE': 'Spanish - Venezuela',
  sw: 'Swahili',
  'sv-FI': 'Swedish - Finland',
  'sv-SE': 'Swedish - Sweden',
  tg: 'Tajik',
  ta: 'Tamil',
  tt: 'Tatar',
  te: 'Telugu',
  th: 'Thai',
  bo: 'Tibetan',
  ts: 'Tsonga',
  tr: 'Turkish',
  tk: 'Turkmen',
  uk: 'Ukrainian',
  ur: 'Urdu',
  'uz-UZ': 'Uzbek - Cyrillic',
  vi: 'Vietnamese',
  cy: 'Welsh',
  xh: 'Xhosa',
  yi: 'Yiddish',
  zu: 'Zulu',
};
