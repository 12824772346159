import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';
import { Box, makeStyles, IconButton } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles({
  content: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  collapsed: {
    display: 'none',
  },
  header: {
    '& h6': {
      fontWeight: 700,
      fontSize: '1rem',
    },
  },
  collapseHeader: {
    display: 'flex',
    '& h6': {
      flexGrow: 1,
    },
    '& button': {
      height: 20,
      width: 20,
    },
  },
  errorIcon: {
    float: 'right',
  },
});

interface Props {
  renderTitle?: () => ReactNode;
  children?: ReactNode;
  contentPadding?: number;
  expandable?: boolean;
  defaultExpanded?: boolean;
  reportProblem?: boolean;
}

const SummaryInfoBox: React.FC<Props> = ({
  renderTitle,
  children,
  expandable = false,
  defaultExpanded = true,
  contentPadding = 2,
  reportProblem = false,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(expandable ? defaultExpanded : true);

  const toggleExpanded = () => {
    setExpanded(!expanded);

    // this is needed to dynamic sizing/positioning for child components
    setImmediate(() => window.dispatchEvent(new Event('resize')));
  };

  return (
    <Box bgcolor="#FBFBFF" borderRadius={5} m={1}>
      <Box bgcolor="#F7F7FF" p={2} borderRadius={'5px 5px 0 0'}>
        <div
          className={clsx(classes.header, expandable && classes.collapseHeader)}
        >
          {reportProblem && (
            <ErrorIcon color="error" className={classes.errorIcon} />
          )}
          {renderTitle && renderTitle()}
          {expandable && (
            <div className="expandControls">
              <IconButton onClick={toggleExpanded}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          )}
        </div>
      </Box>
      {children && (
        <Box
          className={clsx(classes.content, !expanded && classes.collapsed)}
          p={contentPadding}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default SummaryInfoBox;
