import { useDirectQuery } from '../../graphql';
import gql from 'graphql-tag';
import {
  AvailableReports,
  AvailableReportsVariables,
} from '../../../../schema';
import { useQuery } from 'react-apollo';

const GET_AVAILABLE_REPORTS = gql`
  query AvailableReports($startsAt: String!, $endsBefore: String!) {
    availableReports(startsAt: $startsAt, endsBefore: $endsBefore)
  }
`;

export function useGetAvailableReportsDirectQuery() {
  return useDirectQuery<AvailableReports, AvailableReportsVariables>(
    GET_AVAILABLE_REPORTS,
  );
}

export function useGetAvailableReportsQuery(
  variables: AvailableReportsVariables,
) {
  return useQuery<AvailableReports, AvailableReportsVariables>(
    GET_AVAILABLE_REPORTS,
    {
      variables,
      fetchPolicy: 'network-only',
    },
  );
}
