import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/string/match-all';

import * as Sentry from '@sentry/react';

import React from 'react';
import ReactDOM from 'react-dom';
import { initialize as initializePrefill } from './lib/prefill';
import './lib/i18n';

import App from './App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://6b1e7c43d0554ceeb423941f164aa6a1@o123502.ingest.sentry.io/5606319',
    release: process.env.REACT_APP_COMMIT_HASH,
    tracesSampleRate: 1.0,
  });
}

initializePrefill();

ReactDOM.render(<App />, document.getElementById('root'));

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready
    .then(registration => {
      registration.unregister().catch(console.error);
    })
    .catch(console.error);
}
