import { Grid, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import {
  useLoginStatus,
  useLogin,
  useAccountInfo,
  useFacebookPages,
  useConnectedInstagramAccount,
  TypeaheadFacebookPageWithToken,
} from './use-facebook';
import { FacebookProvider } from 'react-facebook';
import Connect from './components/Connect';
import Page from './components/Page';
import Confirm from './components/Confirm';
import Info from './components/Info';
import { useAppTheme } from '../../theme';
import Done from './components/Done';
import IgAccount from './components/IgAccount';
import { fromError } from '../../lib/pretty-error';
import { NotificationsContext } from '../../lib/use-notifications';
import { useClaimFacebookPageWithPageTokenMutation } from './query';
import { FACEBOOK_SDK_APP_ID, FACEBOOK_LOGIN_SCOPES } from '../../constants';

const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
  },
}));

type Props = Record<string, unknown>;

const FbOnboarding: React.FC<Props> = () => {
  const classes = useStyles();
  const theme = useAppTheme();
  const { addNotification } = useContext(NotificationsContext);

  const claimFacebookPageWithPageToken = useClaimFacebookPageWithPageTokenMutation();
  const { loading: loginStatusLoading, userId } = useLoginStatus();
  const [handleLogin, handleLogout] = useLogin(FACEBOOK_LOGIN_SCOPES);
  const [account, accountLoading] = useAccountInfo(userId);
  const [pages, pagesLoading] = useFacebookPages(userId);
  const [selectedPage, setSelectedPage] = useState<
    TypeaheadFacebookPageWithToken | undefined
  >(undefined);
  const [
    connectedIgAccount,
    connectedIgAccountLoading,
  ] = useConnectedInstagramAccount(selectedPage);

  const [finishing, setFinishing] = useState(false);
  const [done, setDone] = useState(false);

  const finish = async () => {
    setFinishing(true);

    try {
      if (selectedPage) {
        await claimFacebookPageWithPageToken({
          pageId: selectedPage.id,
          pageToken: selectedPage.accessToken,
        });
      } else {
        throw new Error('No page selected');
      }
      setDone(true);
      handleLogout();
      // log out
    } catch (e) {
      fromError(e).forEach(pretty =>
        addNotification({ message: pretty.message, variant: 'error' }),
      );
    } finally {
      setFinishing(false);
    }
  };

  return (
    <Grid
      container
      component="main"
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <Grid item container xs={12} md={4}>
        <Grid item xs={12} justify="center" alignItems="center" container>
          <img
            src={theme.assets.logo}
            alt="logo"
            style={{ maxHeight: '60px' }}
          />
        </Grid>
        {done ? (
          <Grid item xs={12} justify="center" alignItems="center" container>
            <Done />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Info />

              <Connect
                login={handleLogin}
                logout={handleLogout}
                name={account ? account.name : undefined}
                status={
                  finishing
                    ? 'disabled'
                    : loginStatusLoading || accountLoading
                    ? 'loading'
                    : 'normal'
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Page
                pages={pages}
                value={selectedPage}
                onChange={setSelectedPage}
                status={
                  !account
                    ? 'hidden'
                    : finishing
                    ? 'disabled'
                    : pagesLoading
                    ? 'loading'
                    : 'normal'
                }
              />
              <IgAccount
                account={connectedIgAccount}
                loading={connectedIgAccountLoading}
              ></IgAccount>
            </Grid>
            <Grid item xs={12}>
              <Confirm
                onConfirm={finish}
                status={
                  !account || !selectedPage
                    ? 'hidden'
                    : finishing
                    ? 'loading'
                    : 'normal'
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const FbOnboardingWithFbProvider: React.FC = () => {
  return (
    <FacebookProvider appId={FACEBOOK_SDK_APP_ID}>
      <FbOnboarding />
    </FacebookProvider>
  );
};

export default FbOnboardingWithFbProvider;
