import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { VisibleIgPosts, VisibleIgPostsVariables } from '../../../../schema';

const VISIBLE_IG_POSTS = gql`
  query VisibleIgPosts($iterationId: Int!, $storeId: Int!) {
    visibleIgPosts(iterationId: $iterationId, storeId: $storeId) {
      media_info {
        id
      }
      stats {
        reach
        impressions
        engagement
        saved
        likes
        comments
      }
      details {
        media {
          type
          url
          isLocalFile
        }
        previewUrl
        message
      }
    }
  }
`;

export function useGetVisibleIgPostsQuery(variables: VisibleIgPostsVariables) {
  return useQuery<VisibleIgPosts, VisibleIgPostsVariables>(VISIBLE_IG_POSTS, {
    variables,
    fetchPolicy: 'network-only',
  });
}
