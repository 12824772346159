import React, { useState, useContext } from 'react';
import {
  Button,
  TableRow,
  TableCell,
  Checkbox,
  makeStyles,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ButtonGroup,
  Box,
  Drawer,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CreateStore from './components/CreateStore';
import {
  Stores,
  Stores_stores_stores as Store,
  StoresInput,
} from '../../../schema';
import * as _ from 'lodash';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import StoreActions from './components/StoreActions';
import { Permission, GlobalPermission } from '../../../lib/types';
import RemoteDataTable, {
  StrictFilterState,
} from '../../../components/RemoteDataTable/RemoteDataTable';
import StoreFilter from './components/StoreFilter';
import { v4 as uuidv4 } from 'uuid';
import { GetCurrentUser_currentUser as User } from '../../../schema';
import ImportFromFile from './components/ImportFromFile';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { StoreStatus } from '../../../lib/types';
import { useGetStoresQuery } from '../../../lib/graphql/resolvers/queries/stores';
import UpsertStore from './components/UpsertStore';
import { UpsertStoreInput } from '../../../schema';
import ChannelsIcon from '../../../components/ChannelsIcon/ChannelsIcon';
import { CHANNEL_COLOURS } from '../../../lib/types';
import { useGetOperatorsQuery } from '../../../lib/graphql/resolvers/queries/operators';
import FullscreenLoading from '../../Layout/FullscreenLoading';
import InteractiveButton from '../../../components/InteractiveButton/InteractiveButton';
import { NotificationsContext } from '../../../lib/use-notifications';
import { downloadFile } from '../../../lib/util';
import { useGetAreasQuery } from '../../../lib/graphql/resolvers/queries/areas';
import { ClientConfigContext } from '../../../lib/client-config';
import { StoreListColumn } from '../../../lib/client-config-response';
import CommentIcon from '@material-ui/icons/Comment';
import { Role } from '../../../lib/types';

const useStyles = makeStyles(theme => ({
  table: {},
  actionButton: {
    margin: 5,
  },
  spacer: {
    height: 200,
  },
  actionsDrawer: {
    '& .MuiPaper-root': {
      boxShadow: '0 -10px 10px #f7f7f7',
    },
  },
  exportButton: {
    '& button': {
      textTransform: 'capitalize',
      boxShadow: 'none',
      height: 56,
      marginLeft: 10,
    },
  },
}));

const listItem = (
  text: string,
  details: string | null,
  ok: boolean,
  t: (s: string) => string,
) => {
  if (ok) {
    return (
      <ListItem>
        <ListItemIcon>
          <CheckCircleOutlineIcon style={{ color: 'green' }} />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  }
  // display details only if ko
  return (
    <ListItem>
      <ListItemIcon>
        <CancelOutlinedIcon style={{ color: 'red' }} />
      </ListItemIcon>
      <ListItemText
        primary={text}
        secondary={
          details ? details : t('components.storeList.infoDialog.pending')
        }
      />
    </ListItem>
  );
};

const StoreListView: React.FC<{
  user: User;
}> = ({ user }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { addNotification } = useContext(NotificationsContext);
  const {
    config: { sortedVisibleStoreListColumns, featureFlags },
  } = useContext(ClientConfigContext);
  const [visibleStores, setVisibleStores] = useState<Store[]>([]);
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);
  const [refreshKey, setRefreshKey] = useState('');
  const [
    infoModalSelectedStore,
    setInfoModalSelectedStore,
  ] = useState<Store | null>(null);
  const [
    activatingStoreInfoModalOpen,
    setActivatingStoreInfoModalOpen,
  ] = useState(false);
  const [
    notesModalSelectedStore,
    setNotesModalSelectedStore,
  ] = useState<Store | null>(null);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [
    storeToBeEdited,
    setStoreToBeEdited,
  ] = useState<UpsertStoreInput | null>(null);

  const handleActivatingStoreInfoModalClose = () => {
    setActivatingStoreInfoModalOpen(false);
  };

  const handleNotesModalClose = () => {
    setNotesModalOpen(false);
  };

  const isStoreSelected = (storeId: number) => {
    return selectedStores.map(store => store.id).includes(storeId);
  };
  const onStoreToggled = (store: Store, checked: boolean) => {
    if (checked) {
      setSelectedStores([...selectedStores, store]);
    } else {
      const index = selectedStores.indexOf(store);
      setSelectedStores([
        ...selectedStores.slice(0, index),
        ...selectedStores.slice(index + 1),
      ]);
    }
  };
  const onAllStoresToggled = (checked: boolean) => {
    if (checked) {
      setSelectedStores(_.uniq([...selectedStores, ...visibleStores]));
    } else {
      const visibleStoreIds = visibleStores.map(store => store.id);
      setSelectedStores(
        selectedStores.filter(store => !visibleStoreIds.includes(store.id)),
      );
    }
  };
  const allVisibleStoresSelected = (): boolean => {
    const visibleStoreIds = visibleStores.map(store => store.id);
    return (
      selectedStores.filter(store => visibleStoreIds.includes(store.id))
        .length == visibleStoreIds.length
    );
  };
  const noVisibleStoreSelected = (): boolean => {
    const visibleStoreIds = visibleStores.map(store => store.id);
    return (
      selectedStores.filter(store => visibleStoreIds.includes(store.id))
        .length == 0
    );
  };

  const canEditStores = user.globalPermissions.includes(
    GlobalPermission.IMPORT_STORES,
  );

  const canGetStoresReport = user.globalPermissions.includes(
    GlobalPermission.GET_STORES_REPORT,
  );

  const canFilterStores =
    featureFlags.includes('CAN_FILTER_STORES') ||
    [Role.OPERATOR, Role.SUPER_USER].includes(user.role);

  const showActivationDetails = (store: Store) => {
    setInfoModalSelectedStore(store);
    setActivatingStoreInfoModalOpen(true);
  };

  const showStoreNotes = (store: Store) => {
    setNotesModalSelectedStore(store);
    setNotesModalOpen(true);
  };

  const editStore = (store: Store) => {
    setSelectedStores([]); // deselect all stores, otherwise problems may arise
    setStoreToBeEdited({
      remoteId: store.remoteId,
      contactName: store.contactName,
      companyName: store.companyName,
      area: store.area,
      storeManagerEmail: store.manager ? store.manager.email : null,
      phone: store.phone,
      website: store.website,
      googleMybusinessLocation: store.gmbLocation
        ? store.gmbLocation.gmbLocationId
        : null,
      areaManagerEmail: store.areaManager ? store.areaManager.email : null,
      facebookPage: store.facebookPage
        ? store.facebookPage.facebookPageId
        : null,
      digitalAccountEmail: store.digitalAccount
        ? store.digitalAccount.email
        : null,
      salesAccountEmail: store.salesAccount ? store.salesAccount.email : null,
      salesDirectorEmail: store.salesDirector
        ? store.salesDirector.email
        : null,
      operatorEmail: store.operator ? store.operator.email : null,
      locale: store.locale ? store.locale : null,
      country: store.country ? store.country : null,
      address: store.address ? store.address : null,
      zipCode: store.zipCode ? store.zipCode : null,
      city: store.city ? store.city : null,
      serviceStartedAt: store.serviceStartedAt ? store.serviceStartedAt : null,
      notes: store.notes ? store.notes : null,
    });
  };

  const taxonomies = {
    operators: useGetOperatorsQuery(),
    areas: useGetAreasQuery(),
  };

  const filterFactory = (
    state: StrictFilterState<StoresInput>,
    onChange: (value: StrictFilterState<StoresInput>) => void,
  ) => (
    <StoreFilter
      state={state}
      onChange={onChange}
      areas={taxonomies.areas.data ? taxonomies.areas.data.areas : []}
    />
  );

  if (taxonomies.operators.loading || taxonomies.areas.loading) {
    return <FullscreenLoading />;
  }

  if (taxonomies.operators.error) {
    return <h1>{String(taxonomies.operators.error)}</h1>;
  }

  if (taxonomies.areas.error) {
    return <h1>{String(taxonomies.areas.error)}</h1>;
  }

  return (
    <>
      <Drawer
        anchor={'bottom'}
        open={selectedStores.length > 0}
        variant="persistent"
        className={classes.actionsDrawer}
      >
        {selectedStores.length > 0 && (
          <StoreActions
            selectedStores={selectedStores}
            actionDone={() => {
              setSelectedStores([]);
              setRefreshKey(uuidv4());
            }}
            unselectAll={() => setSelectedStores([])}
          />
        )}
      </Drawer>

      <Grid container>
        <Grid item xs={12} md={12}>
          <RemoteDataTable
            onDataChanged={(stores: Store[]) => setVisibleStores(stores)}
            cacheId="stores-list"
            searchable={true}
            upperLeftHeader={
              canEditStores || canGetStoresReport ? (
                <Grid container>
                  <Grid item xs={12}>
                    {canEditStores && (
                      <CreateStore
                        onClick={() => {
                          setSelectedStores([]);
                        }}
                        done={async () => setRefreshKey(uuidv4())}
                        operators={
                          taxonomies.operators.data
                            ? taxonomies.operators.data.operators
                            : []
                        }
                      />
                    )}
                    {canEditStores && (
                      <ImportFromFile
                        onClick={() => {
                          setSelectedStores([]);
                        }}
                        done={async () => setRefreshKey(uuidv4())}
                      />
                    )}
                    {canGetStoresReport && (
                      <InteractiveButton
                        className={classes.exportButton}
                        variant="contained"
                        color="primary"
                        label={t('components.storeList.export')}
                        onClick={async () => {
                          try {
                            await downloadFile(`reports/stores`);
                          } catch (e) {
                            console.log(e);
                            addNotification({
                              message: e.message,
                              variant: 'error',
                            });
                          }
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              ) : undefined
            }
            renderHeaderRow={() => {
              const headerColumns: Record<StoreListColumn, JSX.Element> = {
                AREA: (
                  <TableCell component="th">
                    {t('components.storeList.area')}
                  </TableCell>
                ),
                CHANNELS: (
                  <TableCell component="th">
                    {t('components.storeList.channels')}
                  </TableCell>
                ),
                CITY: (
                  <TableCell component="th">
                    {t('components.storeList.city')}
                  </TableCell>
                ),
                COMPANY_NAME: (
                  <TableCell component="th">
                    {t('components.storeList.companyName')}
                  </TableCell>
                ),
                REMOTE_ID: (
                  <TableCell component="th">
                    {t('components.storeList.remoteId')}
                  </TableCell>
                ),
                STATUS: (
                  <TableCell component="th">
                    {t('components.storeList.status')}
                  </TableCell>
                ),
                NOTES: (
                  <TableCell component="th">{/*empty on purpose*/}</TableCell>
                ),
              };

              return (
                <TableRow>
                  {sortedVisibleStoreListColumns.map(col => headerColumns[col])}
                  <TableCell component="th" padding="checkbox"></TableCell>
                  {canEditStores && (
                    <TableCell component="th" padding="checkbox">
                      <Checkbox
                        indeterminate={
                          !(
                            noVisibleStoreSelected() ||
                            allVisibleStoresSelected()
                          )
                        }
                        checked={allVisibleStoresSelected()}
                        onChange={(
                          event: React.ChangeEvent,
                          checked: boolean,
                        ) => onAllStoresToggled(checked)}
                        inputProps={{
                          'aria-label': 'select all visible stores',
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            }}
            renderDataRow={(store: Store) => {
              const hasFbPageButNotActiveYet = Boolean(
                store.facebookPage && !store.facebookPage.startedTrackingAt,
              );
              const hasGmbLocationButNotActiveYet = Boolean(
                store.gmbLocation && !store.gmbLocation.startedTrackingAt,
              );
              const canShowActivationDetails =
                [StoreStatus.ACTIVATING, StoreStatus.ACTIVE].includes(
                  store.status,
                ) &&
                (hasFbPageButNotActiveYet || hasGmbLocationButNotActiveYet);

              const rowColumns: Record<StoreListColumn, JSX.Element> = {
                AREA: <TableCell>{store.area}</TableCell>,
                CITY: <TableCell>{store.city}</TableCell>,
                CHANNELS: (
                  <TableCell>
                    <Box display="flex">
                      {store.facebookPage && (
                        <span style={{ color: CHANNEL_COLOURS['FACEBOOK'] }}>
                          <ChannelsIcon
                            channel="FACEBOOK"
                            grayscale={!store.facebookPage.startedTrackingAt}
                          />
                        </span>
                      )}
                      {store.facebookPage &&
                        store.facebookPage.instagramAccountId && (
                          <span style={{ color: CHANNEL_COLOURS['INSTAGRAM'] }}>
                            <ChannelsIcon
                              channel="INSTAGRAM"
                              grayscale={!store.facebookPage.startedTrackingAt}
                            />
                          </span>
                        )}
                      {store.gmbLocation && (
                        <span
                          style={{
                            color: CHANNEL_COLOURS['GOOGLE_MYBUSINESS'],
                          }}
                        >
                          <ChannelsIcon
                            channel="GOOGLE_MYBUSINESS"
                            grayscale={!store.gmbLocation.startedTrackingAt}
                          />
                        </span>
                      )}
                    </Box>
                  </TableCell>
                ),
                COMPANY_NAME: <TableCell>{store.companyName}</TableCell>,
                REMOTE_ID: <TableCell>{store.remoteId}</TableCell>,
                STATUS: (
                  <TableCell>
                    {t(`enums.StoreStatus.${store.status}`)}
                    {canShowActivationDetails && (
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => showActivationDetails(store)}
                      >
                        <InfoRoundedIcon />
                      </IconButton>
                    )}
                  </TableCell>
                ),
                NOTES: (
                  <TableCell>
                    {store.notes && (
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => showStoreNotes(store)}
                      >
                        <CommentIcon />
                      </IconButton>
                    )}
                  </TableCell>
                ),
              };

              return (
                <TableRow key={store.id}>
                  {sortedVisibleStoreListColumns.map(col => rowColumns[col])}
                  <TableCell padding="checkbox">
                    <ButtonGroup>
                      {store.permissions.includes(Permission.STORE_EDIT) && (
                        <IconButton onClick={() => editStore(store)}>
                          <EditIcon />
                        </IconButton>
                      )}
                      {store.permissions.includes(
                        Permission.STORE_VIEW_DETAILS,
                      ) && (
                        <Link to={`/stores/${store.remoteId}`}>
                          <IconButton>
                            <ExitToAppIcon />
                          </IconButton>
                        </Link>
                      )}
                    </ButtonGroup>
                  </TableCell>
                  {canEditStores && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="default"
                        checked={isStoreSelected(store.id)}
                        onChange={(
                          event: React.ChangeEvent,
                          checked: boolean,
                        ) => onStoreToggled(store, checked)}
                        inputProps={{ 'aria-label': 'select this row' }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            }}
            useQuery={useGetStoresQuery}
            parseData={(data: Stores) => data.stores.stores}
            parseFilteredCount={(data: Stores) => data.stores.filteredCount}
            filter={
              canFilterStores
                ? {
                    emptyState: {
                      status: '',
                      area: '',
                      channels: [],
                    },
                    factory: filterFactory,
                  }
                : undefined
            }
            refreshKey={refreshKey}
          />
        </Grid>
      </Grid>
      {/** add space so as stores are hot covered by drawer */}
      {selectedStores.length > 0 && <Box className={classes.spacer}></Box>}
      {/**
       * detailed info dialog for activating stores
       */}
      <Dialog
        maxWidth="md"
        open={activatingStoreInfoModalOpen}
        onClose={handleActivatingStoreInfoModalClose}
        aria-labelledby="info-dialog-title"
      >
        {infoModalSelectedStore && (
          <DialogTitle id="info-dialog-title">
            Store {infoModalSelectedStore!.remoteId}
          </DialogTitle>
        )}
        {infoModalSelectedStore && (
          <DialogContent>
            <List>
              {infoModalSelectedStore!.facebookPage && (
                <>
                  {listItem(
                    t('components.storeList.infoDialog.fbPageAccess'),
                    infoModalSelectedStore!.facebookPage.hasAccessLastResponse,
                    Boolean(infoModalSelectedStore!.facebookPage.hasAccess),
                    t,
                  )}
                  {/**display only if no access available because for imported stored claim procedure was not necessary */}
                  {!infoModalSelectedStore!.facebookPage.hasAccess &&
                    listItem(
                      t('components.storeList.infoDialog.fbPageClaim'),
                      null,
                      Boolean(infoModalSelectedStore!.facebookPage.claimedAt),
                      t,
                    )}
                  {listItem(
                    t('components.storeList.infoDialog.fbPageTracking'),
                    infoModalSelectedStore!.facebookPage
                      .startTrackingLastResponse,
                    Boolean(
                      infoModalSelectedStore!.facebookPage.startedTrackingAt,
                    ),
                    t,
                  )}
                </>
              )}
              {infoModalSelectedStore!.gmbLocation && (
                <>
                  {listItem(
                    t('components.storeList.infoDialog.gmbLocationAccess'),
                    infoModalSelectedStore!.gmbLocation.hasAccessLastResponse,
                    Boolean(infoModalSelectedStore!.gmbLocation.hasAccess),
                    t,
                  )}
                  {listItem(
                    t('components.storeList.infoDialog.gmbLocationTracking'),
                    infoModalSelectedStore!.gmbLocation
                      .startTrackingLastResponse,
                    Boolean(
                      infoModalSelectedStore!.gmbLocation.startedTrackingAt,
                    ),
                    t,
                  )}
                </>
              )}
            </List>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleActivatingStoreInfoModalClose} color="primary">
            {t('components.storeView.close')}
          </Button>
        </DialogActions>
      </Dialog>
      {/**
       * store notes dialog
       */}
      <Dialog
        maxWidth="md"
        open={notesModalOpen}
        onClose={handleNotesModalClose}
        aria-labelledby="info-dialog-title"
      >
        {notesModalSelectedStore && (
          <DialogTitle id="info-dialog-title">
            Store {notesModalSelectedStore!.remoteId}
          </DialogTitle>
        )}
        {notesModalSelectedStore && (
          <DialogContent>{String(notesModalSelectedStore.notes)}</DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleNotesModalClose} color="primary">
            {t('components.storeView.close')}
          </Button>
        </DialogActions>
      </Dialog>
      {/**
       * edit store modal
       */}
      {storeToBeEdited != null && (
        <UpsertStore
          type={'update'}
          onSaved={async () => {
            setStoreToBeEdited(null);
            setRefreshKey(uuidv4()); // refresh stores list
          }}
          defaultFormState={storeToBeEdited}
          open={true}
          onClose={() => setStoreToBeEdited(null)}
          operators={
            taxonomies.operators.data ? taxonomies.operators.data.operators : []
          }
        ></UpsertStore>
      )}
    </>
  );
};
export default StoreListView;
