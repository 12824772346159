import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { GetCurrentUser } from '../../../../schema';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      email
      name
      role
      permissions
      globalPermissions
    }
  }
`;

export function useGetCurrentUserQuery() {
  return useQuery<GetCurrentUser>(GET_CURRENT_USER);
}
