import React, { useContext, useState } from 'react';
import { TasksPerStore_tasks_tasks as Task } from '../../../../schema';
import {
  CompliancyIssue,
  Permission,
  TaskFlag,
  TaskStatus,
  TaskType,
} from '../../../../lib/types';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import FacebookPageImage from '../../../../components/FacebookPageImage/FacebookPageImage';
import Post from '../../../../components/Post/Post';
import InteractiveButton from '../../../../components/InteractiveButton/InteractiveButton';
import { useGqlClient } from '../../../../lib/graphql/use-gql-client';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import { NotificationsContext } from '../../../../lib/use-notifications';
import UnreachableCaseError from '../../../../lib/unreachable-case.error';
import { ClientConfigContext } from '../../../../lib/client-config';
import * as _ from 'lodash';
import { Review } from '../../../../components/Review/Review';

const useStyles = makeStyles(theme => ({
  filterControl: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  hashtagStream: {
    textTransform: 'none',
    margin: 5,
  },
  selectedHashtagStream: {
    background: '#ffff00',
    '&:hover': {
      background: '#f7f72f',
    },
  },
  checkbox: {
    width: '100%',
    marginBottom: 10,
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
  },
}));

const getPostTaskCheckbox = (task: Task): boolean => {
  const type = task.type as TaskType;
  switch (type) {
    case TaskType.FACEBOOK_PAGE:
      return false;
    case TaskType.FACEBOOK_POST:
    case TaskType.INSTAGRAM_POST:
      return task.isVendorPost;
    case TaskType.GOOGLE_MYBUSINESS_POST:
    case TaskType.GOOGLE_MYBUSINESS_REVIEW:
      return false; // gmb doesn't have isScheduledContent flag
  }
  throw new UnreachableCaseError(type);
};

type PageTaskCheckbox =
  | 'coverImageNotInLine'
  | 'profileImageNotInLine'
  | 'callToActionNotInLine';

const getPageTaskCheckbox = (task: Task, key: PageTaskCheckbox): boolean => {
  if (task.type == TaskType.FACEBOOK_PAGE) {
    return task.data.page && task.data.page[key]
      ? Boolean(task.data.page[key])
      : false;
  }
  return false;
};

const compliancyIssuesToMap = (task: Task): CompliancyIssue[] => {
  if (task.type !== TaskType.FACEBOOK_PAGE) {
    return task.data.post && task.data.post.compliancyIssues
      ? task.data.post.compliancyIssues
      : [];
  }
  return [];
};

const TaskActions: React.FC<{
  task: Task;
  changed: boolean;
  edit: () => Promise<void>;
  done: () => Promise<void>;
  reset: () => Promise<void>;
  cancel?: () => Promise<void>;
}> = ({ task, edit, done, reset, cancel, changed }) => {
  const { t } = useTranslation();
  const gqlClient = useGqlClient();
  const [iterationHasReport, setIterationHasReport] = useState(false);

  return (
    <>
      {task.status == TaskStatus.DONE && (
        <Alert severity="info">
          {t('components.taskBox.completedOn', {
            date: task.completedAt,
          })}
        </Alert>
      )}
      {task.status == TaskStatus.CANCELED && (
        <Alert severity="error">{t('components.taskBox.canceled')}</Alert>
      )}
      {task.permissions.includes(Permission.TASK_EDIT_ISSUES) && (
        <Box p={2}>
          <InteractiveButton
            onClick={edit}
            variant="outlined"
            disabled={!changed}
            fullWidth
            color="secondary"
            label={t('fields.taskBox.save')}
          />
        </Box>
      )}
      {task.permissions.includes(Permission.TASK_RESET) && (
        <Box p={2}>
          <InteractiveButton
            variant="contained"
            fullWidth
            color="primary"
            label={t('fields.taskBox.reset')}
            confirm={{
              beforeDisplay: async () => {
                try {
                  const res = await gqlClient.iterationPermissions({
                    id: task.iteration.id,
                    storeId: task.iteration.storeId,
                  });
                  setIterationHasReport(
                    Boolean(res.iteration) &&
                      res.iteration!.permissions.includes(
                        Permission.ITERATION_GET_REPORT,
                      ),
                  );
                } catch (e) {
                  console.log(e);
                }
              },
              title: t('components.taskBox.resetTask'),
              content: (
                <>
                  {iterationHasReport && (
                    <Alert severity="warning">
                      {t('components.taskBox.resetTaskWithReportDisclaimer')}
                    </Alert>
                  )}
                  <Typography variant="body2">
                    {t('components.taskBox.resetTaskModalInfo')}
                  </Typography>
                </>
              ),
              submit: {
                onClick: reset,
              },
            }}
          />
        </Box>
      )}
      {/** cancel operation for fb page task make no sense */}
      {cancel && task.permissions.includes(Permission.TASK_CANCEL) && (
        <Box p={2}>
          <InteractiveButton
            variant="contained"
            fullWidth
            color="primary"
            label={t('fields.taskBox.cancel')}
            confirm={{
              beforeDisplay: async () => {
                try {
                  const res = await gqlClient.iterationPermissions({
                    id: task.iteration.id,
                    storeId: task.iteration.storeId,
                  });
                  setIterationHasReport(
                    Boolean(res.iteration) &&
                      res.iteration!.permissions.includes(
                        Permission.ITERATION_GET_REPORT,
                      ),
                  );
                } catch (e) {
                  console.log(e);
                }
              },
              title: t('components.taskBox.cancelTask'),
              content: (
                <>
                  {iterationHasReport && (
                    <Alert severity="warning">
                      {t('components.taskBox.cancelTaskWithReportDisclaimer')}
                    </Alert>
                  )}
                  <Typography variant="body2">
                    {t('components.taskBox.cancelTaskModalInfo')}
                  </Typography>
                </>
              ),
              submit: {
                onClick: cancel,
              },
            }}
          />
        </Box>
      )}
      {task.permissions.includes(Permission.TASK_SET_DONE) && (
        <Box p={2}>
          <InteractiveButton
            variant="contained"
            fullWidth
            color="secondary"
            label={t('fields.taskBox.done')}
            onClick={async () => {
              try {
                if (changed) {
                  await edit();
                }
                await done();
              } catch (e) {
                console.log(e);
              }
            }}
          />
        </Box>
      )}
    </>
  );
};

const TaskBox: React.FC<{
  task: Task;
  onEdit: () => void;
}> = ({ task, onEdit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const gqlClient = useGqlClient();
  const { addNotification } = useContext(NotificationsContext);
  const {
    config: { featureFlags, hashtagStreams },
  } = useContext(ClientConfigContext);

  // post task input
  const [isScheduledContent, setIsScheduledContent] = useState(
    getPostTaskCheckbox(task),
  );
  const [compliancyIssues, setCompliancyIssues] = useState<CompliancyIssue[]>(
    compliancyIssuesToMap(task),
  );
  const [changed, setChanged] = useState(false);

  // fb page task input
  const [coverImageNotInLine, setCoverImageNotInLine] = useState(
    getPageTaskCheckbox(task, 'coverImageNotInLine'),
  );
  const [profileImageNotInLine, setProfileImageNotInLine] = useState(
    getPageTaskCheckbox(task, 'profileImageNotInLine'),
  );
  const [callToActionNotInLine, setCallToActionNotInLine] = useState(
    getPageTaskCheckbox(task, 'callToActionNotInLine'),
  );
  const [isNotCompliant, setIsNotCompliant] = useState(
    task.data.gmbReview?.isCompliant ? false : true,
  );

  const [selectedStreams, setSelectedStreams] = useState(
    task.data.post && task.data.post.streams ? task.data.post.streams : [],
  );

  const savePostTaskIssues = async () => {
    try {
      await gqlClient.editPostTaskIssues({
        input: {
          id: task.id,
          isScheduledContent,
          compliancyIssues,
          streams: selectedStreams,
        },
      });
      addNotification({
        message: t('components.taskBox.changesSaved'),
        variant: 'success',
      });
      onEdit();
    } catch (e) {
      console.log(e);
    }
  };

  const savePageTaskIssues = async () => {
    try {
      await gqlClient.editPageTaskIssues({
        input: {
          id: task.id,
          coverImageNotInLine,
          profileImageNotInLine,
          callToActionNotInLine,
        },
      });
      addNotification({
        message: t('components.taskBox.changesSaved'),
        variant: 'success',
      });
      onEdit();
    } catch (e) {
      console.log(e);
    }
  };

  const saveReviewTaskCompliancy = async (): Promise<void> => {
    try {
      await gqlClient.editReviewTaskCompliancy({
        input: {
          id: task.id,
          isCompliant: !isNotCompliant,
        },
      });
      addNotification({
        message: t('components.taskBox.changesSaved'),
        variant: 'success',
      });
      onEdit();
    } catch (e) {
      console.error(e);
    }
  };

  const moveToDone = async () => {
    try {
      await gqlClient.setTaskDone({
        input: {
          id: task.id,
        },
      });
      addNotification({
        message: t('components.taskBox.taskDone'),
        variant: 'success',
      });
      onEdit();
    } catch (e) {
      console.log(e);
    }
  };

  const resetTask = async () => {
    try {
      await gqlClient.resetTask({
        input: {
          id: task.id,
        },
      });
      addNotification({
        message: t('components.taskBox.taskResetDone'),
        variant: 'success',
      });
      onEdit();
    } catch (e) {
      console.log(e);
    }
  };

  const cancelTask = async () => {
    try {
      await gqlClient.cancelTask({
        input: {
          id: task.id,
        },
      });
      addNotification({
        message: t('components.taskBox.taskCancelDone'),
        variant: 'success',
      });
      onEdit();
    } catch (e) {
      console.log(e);
    }
  };

  const toggleHashtagStream = (stream: string) => {
    let updatedStreams = [...selectedStreams];
    if (selectedStreams.includes(stream)) {
      _.remove(updatedStreams, s => s == stream);
    } else {
      updatedStreams = [stream]; // can deselect multiple but can select one only
    }
    setSelectedStreams(updatedStreams);
    setChanged(true);
  };

  return (
    <>
      {/* === fb page task === */}
      {task.type == TaskType.FACEBOOK_PAGE && (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FacebookPageImage
              image={task.media}
              previewUrl={task.previewUrl}
              canUpdate={task.permissions.includes(Permission.TASK_EDIT_ISSUES)}
              taskId={task.id}
            />
          </Grid>
          <Grid item xs={4}>
            <Grid container>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        disabled={Boolean(task.completedAt)}
                        name={'coverImageNotInLine'}
                        checked={coverImageNotInLine}
                        onChange={(e, checked) => {
                          setCoverImageNotInLine(checked);
                          setChanged(true);
                        }}
                      />
                    }
                    label={t('fields.taskBox.coverImageNotInline')}
                    className={classes.checkbox}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        disabled={Boolean(task.completedAt)}
                        name={'profileImageNotInLine'}
                        checked={profileImageNotInLine}
                        onChange={(e, checked) => {
                          setProfileImageNotInLine(checked);
                          setChanged(true);
                        }}
                      />
                    }
                    label={t('fields.taskBox.profileImageNotInline')}
                    className={classes.checkbox}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        disabled={Boolean(task.completedAt)}
                        name={'callToActionNotInLine'}
                        checked={callToActionNotInLine}
                        onChange={(e, checked) => {
                          setCallToActionNotInLine(checked);
                          setChanged(true);
                        }}
                      />
                    }
                    label={t('fields.taskBox.callToActionNotInline')}
                    className={classes.checkbox}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <>
              <TaskActions
                task={task}
                edit={savePageTaskIssues}
                done={moveToDone}
                reset={resetTask}
                changed={changed}
              />
            </>
          </Grid>
        </Grid>
      )}
      {/* === gmb review task === */}
      {task.type === TaskType.GOOGLE_MYBUSINESS_REVIEW && (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Review
              reviewer={{
                displayName: task.reviewDetails?.reviewer?.displayName,
                photoUrl: task.reviewDetails?.reviewer?.photo,
              }}
              comment={task.reviewDetails?.comment}
              rating={task.reviewDetails?.rating}
              reply={task.reviewDetails?.reply}
              createdAt={task.reviewDetails?.createdAt}
            />
          </Grid>
          <Grid item xs={4}>
            <Box>
              <FormControlLabel
                label={t('components.taskBox.isNotCompliant')}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={isNotCompliant}
                    onChange={() => {
                      setIsNotCompliant(!isNotCompliant);
                      setChanged(true);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />
            </Box>
            <TaskActions
              task={task}
              edit={saveReviewTaskCompliancy}
              done={moveToDone}
              reset={resetTask}
              changed={changed}
            />
          </Grid>
        </Grid>
      )}
      {/* === post task === */}
      {task.type != TaskType.FACEBOOK_PAGE &&
        task.type !== TaskType.GOOGLE_MYBUSINESS_REVIEW && (
          <Grid container>
            <Grid item xs={4}>
              <Post
                message={task.message}
                image={task.media}
                previewUrl={task.previewUrl}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 10 }}>
              <Box style={{ borderBottom: '1px solid #777' }}>
                {'ID '}
                {task.targetId}
                {' - '}
                {t('components.taskBox.createdOn')} {task.targetCreatedAt}
              </Box>
              {task.status == TaskStatus.NEW &&
                task.flags.includes(TaskFlag.POST_CHANGED) && (
                  <Alert severity="warning">
                    {t('components.taskBox.postChanged')}
                  </Alert>
                )}

              {task.type != TaskType.GOOGLE_MYBUSINESS_POST && (
                <>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          disabled={Boolean(task.status != TaskStatus.NEW)}
                          name="isScheduledContent"
                          checked={isScheduledContent}
                          onChange={() => {
                            setIsScheduledContent(!isScheduledContent);
                            setChanged(true);
                          }}
                        />
                      }
                      label={t('fields.taskBox.scheduledContent')}
                      className={classes.checkbox}
                    />
                  </FormGroup>
                  <div style={{ height: 20 }}></div>
                </>
              )}
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{ textAlign: 'center' }}
              >
                {t('fields.taskBox.compliancyIssues')}
              </Typography>
              <hr />
              <FormGroup>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      style={{ textAlign: 'center' }}
                      color="textSecondary"
                    >
                      {t('components.taskBox.issuesGroup.image')}
                    </Typography>
                    {task.availableIssues &&
                      task.availableIssues.image.map((issue, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="default"
                              disabled={Boolean(task.status != TaskStatus.NEW)}
                              name={issue}
                              checked={compliancyIssues.includes(issue)}
                              onChange={(e, checked) => {
                                const issues = [...compliancyIssues];
                                if (checked) {
                                  issues.push(issue);
                                } else {
                                  issues.splice(issues.indexOf(issue), 1);
                                }
                                setCompliancyIssues(issues);
                                setChanged(true);
                              }}
                            />
                          }
                          label={t(`enums.CompliancyIssue.${issue}`)}
                          className={classes.checkbox}
                        />
                      ))}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      style={{ textAlign: 'center' }}
                      color="textSecondary"
                    >
                      {t('components.taskBox.issuesGroup.text')}
                    </Typography>
                    {task.availableIssues &&
                      task.availableIssues.text.map((issue, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="default"
                              disabled={Boolean(task.status != TaskStatus.NEW)}
                              name={issue}
                              checked={compliancyIssues.includes(issue)}
                              onChange={(e, checked) => {
                                const issues = [...compliancyIssues];
                                if (checked) {
                                  issues.push(issue);
                                } else {
                                  issues.splice(issues.indexOf(issue), 1);
                                }
                                setCompliancyIssues(issues);
                                setChanged(true);
                              }}
                            />
                          }
                          label={t(`enums.CompliancyIssue.${issue}`)}
                          className={classes.checkbox}
                        />
                      ))}
                  </Grid>
                </Grid>
              </FormGroup>
            </Grid>
            <Grid item xs={2}>
              <TaskActions
                task={task}
                edit={savePostTaskIssues}
                done={moveToDone}
                reset={resetTask}
                cancel={cancelTask}
                changed={changed}
              />
              {featureFlags.includes('HAS_HASHTAG_STREAM_MANAGEMENT') &&
                (task.type === TaskType.FACEBOOK_POST ||
                  task.type === TaskType.INSTAGRAM_POST) && (
                  <>
                    <hr />
                    <Box>
                      {task.type == TaskType.FACEBOOK_POST &&
                        hashtagStreams.fb.map(s => (
                          <Button
                            type="button"
                            onClick={e => toggleHashtagStream(s)}
                            fullWidth
                            key={s}
                            className={
                              selectedStreams.includes(s)
                                ? `${classes.selectedHashtagStream} ${classes.hashtagStream}`
                                : classes.hashtagStream
                            }
                            variant="outlined"
                          >
                            {s}
                          </Button>
                        ))}
                      {task.type == TaskType.INSTAGRAM_POST &&
                        hashtagStreams.ig.map(s => (
                          <Button
                            type="button"
                            onClick={e => toggleHashtagStream(s)}
                            fullWidth
                            key={s}
                            className={
                              selectedStreams.includes(s)
                                ? `${classes.selectedHashtagStream} ${classes.hashtagStream}`
                                : classes.hashtagStream
                            }
                            variant="outlined"
                          >
                            {s}
                          </Button>
                        ))}
                    </Box>
                  </>
                )}
            </Grid>
          </Grid>
        )}
    </>
  );
};
export default TaskBox;
