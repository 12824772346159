import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import {
  StoreOnboardingDone,
  StoreOnboardingDoneVariables,
} from '../../../../schema';

const STORE_ONBOARDING_DONE = gql`
  mutation StoreOnboardingDone($input: StoreOnboardingDoneInput!) {
    storeOnboardingDone(input: $input) {
      stores {
        id
        status
      }
    }
  }
`;

export function useStoreOnboardingDoneMutation() {
  return useDirectMutation<StoreOnboardingDone, StoreOnboardingDoneVariables>(
    STORE_ONBOARDING_DONE,
  );
}
