import React, { useState } from 'react';
import {
  Typography,
  makeStyles,
  Grid,
  IconButton,
  Popover,
} from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { PaginationSelect } from './PaginationSelect';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface PaginationDisplayProps {
  currentPage: number;
  maxPerPage: number;
  totalRecords: number;
  onChangePage: (page: number) => void;
}

const useStyles = makeStyles({
  paginationDisplay: {
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
    justifyContent: 'flex-end',
  },
  backButton: {
    marginLeft: 20,
    marginRight: 20,
  },
  buttonFontSize: {
    fontSize: 'inherit',
  },
  popoverPadding: {
    padding: 5,
  },
});

export const PaginationDisplay: React.FC<PaginationDisplayProps> = ({
  currentPage,
  maxPerPage,
  totalRecords,
  onChangePage,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  // Display the PaginationSelect Component via a Popover
  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const maxPage = Math.max(
    0,
    totalRecords % maxPerPage === 0
      ? Math.floor(totalRecords / maxPerPage) - 1
      : Math.floor(totalRecords / maxPerPage),
  );
  const disableBackward = currentPage <= 0;
  const disableForward = currentPage >= maxPage;

  return (
    <Grid container className={classes.paginationDisplay}>
      <Grid item>
        <Typography variant="body2">
          <span onClick={handleOpen}>
            {t('components.pagination.current-selection', {
              currentPage: currentPage + 1,
              maxPage: maxPage + 1,
              totalRecords: totalRecords,
            })}
          </span>
        </Typography>
      </Grid>
      <Grid item>
        <IconButton
          size="small"
          disabled={disableBackward}
          className={clsx(classes.backButton, classes.buttonFontSize)}
          onClick={() => onChangePage(currentPage - 1)}
        >
          <ArrowBackIos fontSize="inherit" />
        </IconButton>
        <IconButton
          size="small"
          disabled={disableForward}
          className={classes.buttonFontSize}
          onClick={() => onChangePage(currentPage + 1)}
        >
          <ArrowForwardIos fontSize="inherit" />
        </IconButton>
      </Grid>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <PaginationSelect
          maxPage={maxPage + 1}
          currentPage={currentPage}
          onChangePage={onChangePage}
          close={() => setOpen(false)}
        />
      </Popover>
    </Grid>
  );
};
