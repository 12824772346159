import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import { ResetTask, ResetTaskVariables } from '../../../../schema';

const RESET_TASK = gql`
  mutation ResetTask($input: ResetTaskInput!) {
    resetTask(input: $input) {
      task {
        id
        permissions
      }
    }
  }
`;

export function useResetTaskMutation() {
  return useDirectMutation<ResetTask, ResetTaskVariables>(RESET_TASK);
}
