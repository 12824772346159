import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import { CreateStore, CreateStoreVariables } from '../../../../schema';

const CREATE_STORE = gql`
  mutation CreateStore($input: UpsertStoreInput!) {
    createStore(input: $input) {
      store {
        id
      }
    }
  }
`;

export function useCreateStoreMutation() {
  return useDirectMutation<CreateStore, CreateStoreVariables>(CREATE_STORE);
}
