import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { VisibleFbPosts, VisibleFbPostsVariables } from '../../../../schema';

const VISIBLE_FB_POSTS = gql`
  query VisibleFbPosts($iterationId: Int!, $storeId: Int!) {
    visibleFbPosts(iterationId: $iterationId, storeId: $storeId) {
      post_info {
        id
        full_picture
        created_time
        message
      }
      stats {
        reach
        clicks
        impressions
        reactions
        comments
        shares
        likes
      }
      details {
        media {
          type
          url
          isLocalFile
        }
        previewUrl
        message
      }
    }
  }
`;

export function useGetVisibleFbPostsQuery(variables: VisibleFbPostsVariables) {
  return useQuery<VisibleFbPosts, VisibleFbPostsVariables>(VISIBLE_FB_POSTS, {
    variables,
    fetchPolicy: 'network-only',
  });
}
