import ReactFauxDOM from 'react-faux-dom';
import * as d3 from 'd3';

export type ScoreDistribution = {
  label: string;
  selectedIterationScore: number;
  comparedIterationScore: number;
};

type Props = {
  data: ScoreDistribution[];
};

export const DashboardBarChart: React.FC<Props> = ({ data }) => {
  const faux = ReactFauxDOM.createElement('div');

  // Get the chart dimensions and margins.
  const margin = {
    top: 20,
    right: 30,
    bottom: 30,
    left: 30,
  };

  const width = 400;
  const height = 100;
  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const svg = d3
    .select(faux)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

  // Define gradients
  const defs = svg.append('defs');

  const greyGradient = defs
    .append('linearGradient')
    .attr('id', 'greyGradient')
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '100%')
    .attr('y2', '100%');

  greyGradient.append('stop').attr('offset', '0%').attr('stop-color', '#ccc');
  greyGradient.append('stop').attr('offset', '100%').attr('stop-color', 'grey');

  const redGradient = defs
    .append('linearGradient')
    .attr('id', 'redGradient')
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '100%')
    .attr('y2', '100%');

  redGradient.append('stop').attr('offset', '0%').attr('stop-color', '#ff6666');
  redGradient.append('stop').attr('offset', '100%').attr('stop-color', 'red');

  // Create chart
  const x = d3.scaleBand().range([0, chartWidth]).padding(0.1);
  const y = d3.scaleLinear().range([chartHeight, 0]);

  const g = svg
    .append('g')
    .attr('background-color', 'yellow')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  x.domain(data.map(d => d.label));
  y.domain([
    0,
    d3.max(data, d =>
      Math.max(d.selectedIterationScore, d.comparedIterationScore),
    ) || 0,
  ]);

  // Add basic X-Axis
  g.append('g')
    .attr('transform', `translate(0,${chartHeight})`)
    .call(d3.axisBottom(x));

  const barWidth = x.bandwidth() / 2;

  data.forEach(d => {
    const distStr = d.label;
    const xPos = x(distStr);
    if (xPos === undefined) return;

    g.append('rect')
      .attr('x', xPos)
      .attr('y', y(d.selectedIterationScore))
      .attr('width', barWidth)
      .attr('height', chartHeight - y(d.selectedIterationScore))
      .attr('fill', 'url(#redGradient)');

    g.append('text')
      .attr('x', xPos + barWidth / 2)
      .attr('y', y(d.selectedIterationScore) - 5)
      .attr('text-anchor', 'middle')
      .attr('font-family', 'sans-serif')
      .attr('font-size', '10px')
      .text(d.selectedIterationScore);

    g.append('rect')
      .attr('x', xPos + barWidth)
      .attr('y', y(d.comparedIterationScore))
      .attr('width', barWidth)
      .attr('height', chartHeight - y(d.comparedIterationScore))
      .attr('font-family', 'sans-serif')
      .attr('fill', 'url(#greyGradient)');

    g.append('text')
      .attr('x', xPos + barWidth + barWidth / 2)
      .attr('y', y(d.comparedIterationScore) - 5)
      .attr('text-anchor', 'middle')
      .attr('font-family', 'sans-serif')
      .attr('font-size', '10px')
      .text(d.comparedIterationScore);
  });

  return faux.toReact();
};
