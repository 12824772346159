import gql from 'graphql-tag';
import { useDirectMutation } from '../../graphql';
import {
  LoginWithLocalAuthtoken,
  LoginWithLocalAuthtokenVariables,
  LoginWithUppAuthtoken,
  LoginWithUppAuthtokenVariables,
} from '../../../../schema';

const LOG_IN_WITH_LOCAL_AUTHTOKEN = gql`
  mutation LoginWithLocalAuthtoken($token: String!) {
    loginWithLocalAuthToken(input: { authToken: $token }) {
      token
    }
  }
`;

export function useLoginWithLocalAuthtokenMutation() {
  return useDirectMutation<
    LoginWithLocalAuthtoken,
    LoginWithLocalAuthtokenVariables
  >(LOG_IN_WITH_LOCAL_AUTHTOKEN);
}

const LOG_IN_WITH_UPP_AUTHTOKEN = gql`
  mutation LoginWithUppAuthtoken($token: String!) {
    loginWithUppAuthToken(input: { authToken: $token }) {
      token
    }
  }
`;

export function useLoginWithUppAuthtokenMutation() {
  return useDirectMutation<
    LoginWithUppAuthtoken,
    LoginWithUppAuthtokenVariables
  >(LOG_IN_WITH_UPP_AUTHTOKEN);
}
