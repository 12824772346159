import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import { ImportStores, ImportStoresVariables } from '../../../../schema';

const IMPORT_STORES = gql`
  mutation ImportStores($input: ImportStoresInput!) {
    importStores(input: $input) {
      stores {
        id
      }
    }
  }
`;

export function useImportStoresMutation() {
  return useDirectMutation<ImportStores, ImportStoresVariables>(IMPORT_STORES);
}
