import { useDirectQuery } from '../../graphql';
import gql from 'graphql-tag';
import { Operators } from '../../../../schema';
import { useQuery } from 'react-apollo';

const OPERATORS = gql`
  query Operators {
    operators {
      id
      email
    }
  }
`;

export function useGetOperatorsDirectQuery() {
  return useDirectQuery<Operators, null>(OPERATORS);
}

export function useGetOperatorsQuery() {
  return useQuery<Operators, null>(OPERATORS, {
    fetchPolicy: 'network-only',
  });
}
