import { TFunction } from 'i18next';
import { Validator, ValidatorInput } from './apply-validators';
import dayjs from 'dayjs';

export function createRequiredValidator(t: TFunction): Validator {
  return (input: ValidatorInput) => {
    if (!input || String(input).length == 0) {
      return t('validation-errors.is-required');
    }
    return undefined;
  };
}

export function createMinTextLengthValidator(
  t: TFunction,
  min: number,
): Validator {
  return (text: ValidatorInput) => {
    if (!text) {
      return undefined;
    }
    if (String(text).length >= min) {
      return undefined;
    }

    return t('validation-errors.must-be-characters-long', { num: min });
  };
}

export function createNoWhiteSpacesValidator(t: TFunction): Validator {
  return (text: ValidatorInput) => {
    if (!text) {
      return undefined;
    }
    if (/\s/.test(String(text))) {
      return t('validation-errors.no-white-spaces');
    }

    return undefined;
  };
}

export function createEmailValidator(t: TFunction): Validator {
  const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;
  return (text: ValidatorInput) => {
    if (!text) {
      return undefined;
    }
    if (!emailRegex.test(String(text))) {
      return t('validation-errors.valid-email');
    }

    return undefined;
  };
}

export function createUrlValidator(t: TFunction): Validator {
  const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
  return (text: ValidatorInput) => {
    if (!text) {
      return undefined;
    }
    if (!urlRegex.test(String(text))) {
      return t('validation-errors.valid-url');
    }

    return undefined;
  };
}

export function createOnlyNumbersOrSpacesValidator(t: TFunction): Validator {
  const regex = /^[0-9 ]+$/;
  return (text: ValidatorInput) => {
    if (!text) {
      return undefined;
    }
    if (!regex.test(String(text))) {
      return t('validation-errors.only-numbers-or-spaces');
    }

    return undefined;
  };
}

export function createOnlyNumbersValidator(t: TFunction): Validator {
  const regex = /^[0-9]+$/;
  return (text: ValidatorInput) => {
    if (!text) {
      return undefined;
    }
    if (!regex.test(String(text))) {
      return t('validation-errors.only-numbers');
    }

    return undefined;
  };
}

export function createDateStringValidator(t: TFunction): Validator {
  return (text: ValidatorInput) => {
    if (!text) {
      return undefined;
    }
    if (!dayjs(String(text), 'YYYY-MM-DD').isValid()) {
      return t('validation-errors.invalid-date');
    }

    return undefined;
  };
}
