import { useState, createContext } from 'react';

export type Notification = {
  message: string;
  variant: 'error' | 'success' | 'warning' | 'info';
};

export type NotificationContext = {
  notifications: Notification[];
  addNotification: (notification: Notification) => void;
  closeFirstNotification: () => void;
};

export const NotificationsContext = createContext<NotificationContext>({
  notifications: [],
  addNotification: () => {
    /**/
  },
  closeFirstNotification: () => {
    /**/
  },
});
NotificationsContext.displayName = 'NotificationsContext';

export function useNotificationsContextState(): NotificationContext {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (notification: Notification) => {
    setNotifications(current => [...current, notification]);
  };

  const closeFirstNotification = () => {
    setNotifications([...notifications.slice(1)]);
  };

  return {
    addNotification,
    closeFirstNotification,
    notifications,
  };
}
