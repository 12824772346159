import { useState, useEffect, useDebugValue, useContext } from 'react';
import { useApi } from 'react-facebook';
import { TypeaheadFacebookPage } from '../../components/FacebookPageAutocomplete/FacebookPageAutocomplete';
import { NotificationsContext } from '../../lib/use-notifications';

// These hooks can only be used if wrapped in react-facebook FacebookProvider

type LoginStatus = {
  status: string;
  authResponse?: {
    userID?: string;
    accessToken?: string;
  };
};

export function useLoginStatus(): {
  loading: boolean;
  connected: boolean;
  userId?: string;
  accessToken?: string;
} {
  const [, prepareApi] = useApi();
  const [response, setResponse] = useState<LoginStatus | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const { addNotification } = useContext(NotificationsContext);

  useEffect(() => {
    const setup = async () => {
      const api = await prepareApi();

      setResponse(await api.getLoginStatus());
      setLoading(false);
      api.subscribe('auth.statusChange', setResponse);
    };
    setup().catch(e => {
      let message = 'Something went wrong';
      if (e instanceof Error) {
        message = e.message;
      }
      addNotification({ variant: 'error', message });
    });

    return () => {
      const cleanup = async () => {
        const api = await prepareApi();
        api.unsubscribe('auth.statusChange', setResponse);
      };
      void cleanup();
    };
  }, [addNotification, prepareApi]);

  let userId: string | undefined;
  let accessToken: string | undefined;
  let connected = false;

  if (!loading && response && response.status === 'connected') {
    connected = true;
    if (response.authResponse) {
      userId = response.authResponse.userID;
      accessToken = response.authResponse.accessToken;
    }
  }

  useDebugValue(
    loading
      ? 'Loading'
      : connected
      ? `Connected as ${userId}`
      : 'Not connected',
  );

  return {
    loading,
    connected,
    userId,
    accessToken,
  };
}

export function useLogin(scope: string): [() => unknown, () => unknown] {
  const [, prepareApi] = useApi();

  const login = async () => {
    const api = await prepareApi();
    return api.login({
      scope,
    });
  };

  const logout = async () => {
    const api = await prepareApi();
    return api.logout();
  };

  return [login, logout];
}

export type FacebookPage = {
  name: string;
  id: string;
  access_token: string;
};

export type TypeaheadFacebookPageWithToken = TypeaheadFacebookPage & {
  accessToken: string;
};

export function useFacebookPages(
  userId?: string,
): [TypeaheadFacebookPageWithToken[], boolean] {
  const [, prepareApi] = useApi();
  const [pages, setPages] = useState<TypeaheadFacebookPageWithToken[]>([]);
  const [loading, setLoading] = useState(false);
  const { addNotification } = useContext(NotificationsContext);

  useEffect(() => {
    const getpages = async () => {
      setLoading(true);
      if (userId) {
        const api = await prepareApi();
        const response = await api.api(
          '/me/accounts?fields=name,id,access_token&limit=1000',
        );
        console.log(response);

        setPages(
          response.data
            ? (response.data as FacebookPage[]).map<TypeaheadFacebookPageWithToken>(
                p => ({
                  id: p.id,
                  name: p.name,
                  accessToken: p.access_token,
                  picture: `https://graph.facebook.com/${p.id}/picture?type=small`,
                }),
              )
            : [],
        );
      } else {
        setPages([]);
      }
      setLoading(false);
    };
    getpages().catch(e => {
      let message = 'Something went wrong';
      if (e instanceof Error) {
        message = e.message;
      }
      addNotification({ variant: 'error', message });
    });
  }, [userId, prepareApi, addNotification]);

  useDebugValue(loading ? 'Loading' : `Got ${pages.length} pages`);

  return [pages, loading];
}

export type InstagramBusinessAccount = {
  username: string;
  profile_picture_url: string;
  name: string;
};

export function useConnectedInstagramAccount(
  facebookPage?: TypeaheadFacebookPageWithToken,
): [InstagramBusinessAccount | undefined, boolean] {
  const [, prepareApi] = useApi();
  const [igAccount, setIgAccount] = useState<
    InstagramBusinessAccount | undefined
  >();
  const [loading, setLoading] = useState(false);
  const { addNotification } = useContext(NotificationsContext);

  useEffect(() => {
    const getConnectedIgAccount = async () => {
      setLoading(true);
      if (facebookPage) {
        try {
          const api = await prepareApi();
          const response = await api.api(
            `/${facebookPage.id}?fields=instagram_business_account&access_token=${facebookPage.accessToken}`,
          );
          console.log(response);
          if (
            response.instagram_business_account &&
            response.instagram_business_account.id
          ) {
            const igResponse: InstagramBusinessAccount = await api.api(
              `/${response.instagram_business_account.id}?fields=name,username,profile_picture_url&access_token=${facebookPage.accessToken}`,
            );
            console.log(igResponse);
            setIgAccount({
              profile_picture_url: igResponse.profile_picture_url,
              username: igResponse.username,
              name: igResponse.name,
            });
          } else {
            setIgAccount(undefined);
          }
        } catch (e) {
          setIgAccount(undefined);
        }
      } else {
        setIgAccount(undefined);
      }
      setLoading(false);
    };
    getConnectedIgAccount().catch(e => {
      let message = 'Something went wrong';
      if (e instanceof Error) {
        message = e.message;
      }
      addNotification({ variant: 'error', message });
    });
  }, [addNotification, facebookPage, prepareApi]);

  useDebugValue(loading ? 'Loading' : `Got result`);

  return [igAccount, loading];
}

type AccountInfo = {
  name: string;
};

export function useAccountInfo(
  userId?: string,
): [AccountInfo | undefined, boolean] {
  const [, prepareApi] = useApi();
  const [account, setAccount] = useState<AccountInfo | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const { addNotification } = useContext(NotificationsContext);

  useEffect(() => {
    const getaccount = async () => {
      setLoading(true);
      if (userId) {
        const api = await prepareApi();
        const response = await api.api(`/me?fields=name`);
        setAccount(response);
      } else {
        setAccount(undefined);
      }
      setLoading(false);
    };
    getaccount().catch(e => {
      let message = 'Something went wrong';
      if (e instanceof Error) {
        message = e.message;
      }
      addNotification({ variant: 'error', message });
    });
  }, [userId, prepareApi, setAccount, setLoading, addNotification]);

  useDebugValue(
    loading ? 'Loading' : account ? `Name: ${account.name}` : 'Not account',
  );

  return [account, loading];
}
