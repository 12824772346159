import { ApolloError } from 'apollo-boost';
import { GraphQLError } from 'graphql';

type GraphQLErrorMessage =
  | string
  | {
      statusCode: string | number;
      error: string;
    };

export type PrettyError = {
  message: string;
  details?: string;
};

export function fromGraphQLError(error: GraphQLError): PrettyError {
  const originalMessage = (error.message as unknown) as GraphQLErrorMessage;

  let message: string;

  if (typeof originalMessage === 'string') {
    message = originalMessage;
  } else if (
    typeof originalMessage === 'object' &&
    originalMessage.error &&
    typeof originalMessage.error === 'string'
  ) {
    message = originalMessage.error;
  } else {
    message = 'Something went wrong';
  }

  return {
    message,
    details: JSON.stringify(error, null, 2),
  };
}

export function fromError(error: Error): PrettyError[] {
  if (error instanceof ApolloError) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return fromApolloError(error);
  }

  return [
    {
      message: error.message,
      details: error.stack,
    },
  ];
}

export function fromString(message: string, details?: string): PrettyError {
  return { message, details };
}

export function fromApolloError(error: ApolloError): PrettyError[] {
  let pretty: PrettyError[] = [];

  if (error.graphQLErrors) {
    pretty = [...pretty, ...error.graphQLErrors.map(fromGraphQLError)];
  }

  if (error.networkError) {
    pretty = [...pretty, ...fromError(error.networkError)];
  }

  if (pretty.length === 0) {
    pretty = [
      fromString('Something went wrong', JSON.stringify(error, null, 2)),
    ];
  }

  return pretty;
}
