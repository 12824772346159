import * as _ from 'lodash';
import dayjs from 'dayjs';
import Axios from 'axios';
import { BACKEND_URI } from '../constants';
import { getPersistedToken } from './auth';
import fileDownload from 'js-file-download';
import { TFunction } from 'i18next';
import { IterationLabelFormat } from './client-config-response';
import { IterationDates } from '../lib/types';

export async function downloadFile(path: string) {
  const token = getPersistedToken();
  const res = await Axios.get(`${BACKEND_URI}${path}`, {
    responseType: 'blob', // must use "blob", anything else results in corrupted data
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
  let filename = '';
  let cd = res.headers['content-disposition'];
  if (!cd) {
    cd = res.headers['Content-Disposition'];
  }
  if (cd) {
    const index = String(cd).indexOf('filename=');
    if (index >= 0) {
      filename = String(cd).substr(index + 9);
    }
  }
  if (!filename) {
    throw new Error(`No or invalid Content-Disposition from server`);
  }
  fileDownload(res.data, filename);
}

export function iterationDatesToKey(iteration: IterationDates) {
  return `${iteration.startsAt} - ${iteration.endsBefore}`;
}

export function iterationKeyToDates(key: string): IterationDates {
  const [startsAt, _, endsBefore] = key.split(' ');
  return {
    startsAt,
    endsBefore,
  };
}

export function iterationDatesToLabel(
  iteration: IterationDates,
  format: IterationLabelFormat,
  t: TFunction,
): string {
  if (iteration.startsAt == '1970-01-01') {
    return t('components.storeView.lifetimeStats');
  }
  let d: dayjs.Dayjs;
  switch (format) {
    case 'fromFullDateToFullDate':
      return `${dayjs(iteration.startsAt).format('DD/MM/YYYY')} - ${dayjs(
        iteration.endsBefore,
      )
        .subtract(1, 'day')
        .format('DD/MM/YYYY')}`;
    case 'yearMonthOfIterationEnd':
      d = dayjs(iteration.endsBefore).subtract(1, 'day');
      return t(`enums.MonthNum.${d.get('month') + 1}`) + ' ' + d.get('year');
    case 'yearMonthOfIterationStart':
      d = dayjs(iteration.startsAt);
      return t(`enums.MonthNum.${d.get('month') + 1}`) + ' ' + d.get('year');
    default:
      return `${iteration.startsAt} - ${iteration.endsBefore}`;
  }
}

export function iterationsToOptions(
  iterations: IterationDates[],
  format: IterationLabelFormat,
  t: TFunction,
): Array<{ value: string; label: string }> {
  return iterations.map(iteration => {
    return {
      value: iterationDatesToKey(iteration),
      label: iterationDatesToLabel(iteration, format, t),
    };
  });
}
