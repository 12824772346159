import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRedirectToUberallMutation } from '../../lib/graphql/resolvers/mutations/redirect-to-uberall';
import { NotificationsContext } from '../../lib/use-notifications';
import { GetCurrentUser_currentUser as User } from '../../schema';

const RedirectToUberallView: React.FC<{
  user: User;
}> = ({ user }) => {
  const { addNotification } = useContext(NotificationsContext);
  const history = useHistory();
  const redirectToUberallMutation = useRedirectToUberallMutation();

  useEffect(() => {
    const fn = async () => {
      const res = await redirectToUberallMutation({});

      window.location.href = res.redirectToUberall.redirectUrl;
    };

    fn().catch(e => {
      addNotification({ message: String(e), variant: 'error' });
      history.push('/');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <h1>Loading...</h1>;
};

export default RedirectToUberallView;
