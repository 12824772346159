import { createContext, useState, useCallback } from 'react';

const TOKEN_KEY = 'token';

export type TokenType = string | null;

type Context = {
  token: TokenType;
  setToken: (newToken: TokenType) => void;
};

export const AuthContext = createContext<Context>({
  token: null,
  setToken: (newToken: TokenType) => {
    /**/
  },
});
AuthContext.displayName = 'AuthContext';

export function getPersistedToken(): TokenType {
  return window.localStorage.getItem(TOKEN_KEY);
}

export function persistToken(newToken: TokenType) {
  if (typeof newToken === 'string') {
    window.localStorage.setItem(TOKEN_KEY, newToken);
  } else {
    window.localStorage.removeItem(TOKEN_KEY);
  }
}

export function useAuthContextState() {
  const [token, setTokenInState] = useState<TokenType>(getPersistedToken());
  const setToken = useCallback(
    (newToken: TokenType) => {
      setTokenInState(newToken);
      persistToken(newToken);
    },
    [setTokenInState],
  );

  return { token, setToken };
}
