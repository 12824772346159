import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TypeaheadFacebookPage } from './FacebookPageAutocomplete';

const useStyles = makeStyles({
  image: {
    height: 30,
    width: 30,
    marginRight: 10,
    borderRadius: 2,
  },
});

const FacebookPageOption: React.FC<{ page: TypeaheadFacebookPage }> = ({
  page,
}) => {
  const classes = useStyles();

  return (
    <>
      <img className={classes.image} src={page.picture} alt="" />
      {page.name}
    </>
  );
};

export default FacebookPageOption;
