import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import { CancelStore, CancelStoreVariables } from '../../../../schema';

const CANCEL_STORE = gql`
  mutation CancelStore($input: CancelStoresInput!) {
    cancelStores(input: $input) {
      stores {
        id
      }
    }
  }
`;

export function useCancelStoreMutation() {
  return useDirectMutation<CancelStore, CancelStoreVariables>(CANCEL_STORE);
}
