import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import {
  CreateFbPageTasks,
  CreateFbPageTasksVariables,
} from '../../../../schema';

const CREATE_FB_PAGE_TASKS = gql`
  mutation CreateFbPageTasks($input: IterationsInput!) {
    createFbPageTasks(input: $input) {
      tasks {
        id
      }
    }
  }
`;

export function useCreateFbPageTasksMutation() {
  return useDirectMutation<CreateFbPageTasks, CreateFbPageTasksVariables>(
    CREATE_FB_PAGE_TASKS,
  );
}
