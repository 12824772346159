import { fromError } from '../../lib/pretty-error';
import { useContext, useMemo } from 'react';
import { NotificationsContext, Notification } from '../use-notifications';
import { useCreateStoreMutation } from './resolvers/mutations/create-store';
import { useUpdateStoreMutation } from './resolvers/mutations/update-store';
import { useEvaluateImportMutation } from './resolvers/mutations/evaluate-import';
import { useImportStoresMutation } from './resolvers/mutations/import-stores';
import { useGetStoresDirectQuery } from './resolvers/queries/stores';
import { useStoreOnboardingDoneMutation } from './resolvers/mutations/store-onboarding-done';
import { useSendInvitationEmailMutation } from './resolvers/mutations/send-invitation-email';
import { useSendPdfReportMutation } from './resolvers/mutations/send-pdf-report';
import { useDisableStoreMutation } from './resolvers/mutations/disable-store';
import { useGetTasksDirectQuery } from './resolvers/queries/tasks';
import { useGetTasksPerStoreDirectQuery } from './resolvers/queries/tasks-per-store';
import { useGetIterationListDirectQuery } from './resolvers/queries/iteration-list';
import { useEditPostTaskIssuesMutation } from './resolvers/mutations/edit-post-task-issues';
import { useEditPageTaskIssuesMutation } from './resolvers/mutations/edit-page-task-issues';
import { useSetTaskDoneMutation } from './resolvers/mutations/set-task-done';
import { useGetScheduledPublishingTargetsDirectQuery } from './resolvers/queries/scheduled-publishing-targets';
import { useSetPublishingTargetMutation } from './resolvers/mutations/set-publishing-target';
import { useCreateFbPageTasksMutation } from './resolvers/mutations/create-fb-page-tasks';
import { useGenerateIterationReportsMutation } from './resolvers/mutations/generate-iteration-reports';
import { useReactivateStoreMutation } from './resolvers/mutations/reactivate-store';
import { useGetAvailableReportsDirectQuery } from './resolvers/queries/available-reports';
import { useRefreshStoreStatisticsMutation } from './resolvers/mutations/refresh-store-statistics';
import { useUpdateFbPageTaskImageMutation } from './resolvers/mutations/update-fb-page-task-image';
import { useIterationPermissionsDirectQuery } from './resolvers/queries/iteration-permissions';
import { useResetTaskMutation } from './resolvers/mutations/reset-task';
import { useChangePasswordMutation } from './resolvers/mutations/change-password';
import { useRequestPasswordResetMutation } from './resolvers/mutations/request-reset-password';
import { useResetPasswordMutation } from './resolvers/mutations/reset-password';
import { useLoginSsoMutation } from './resolvers/mutations/login-sso';
import { useCancelTaskMutation } from './resolvers/mutations/cancel-task';
import { useUploadFbPageTaskImageMutation } from './resolvers/mutations/upload-fb-page-task-image';
import { useGetDashboardDirectQuery } from './resolvers/queries/dashboard';
import { useCancelStoreMutation } from './resolvers/mutations/cancel-store';
import {
  StoresVariables,
  Stores,
  CreateStoreVariables,
  CreateStore,
  UpdateStoreVariables,
  UpdateStore,
  ImportStoresVariables,
  ImportStores,
  EvaluateImportVariables,
  EvaluateImport,
  StoreOnboardingDoneVariables,
  StoreOnboardingDone,
  SendInvitationEmail,
  SendInvitationEmailVariables,
  SendPdfReport,
  SendPdfReportVariables,
  DisableStore,
  DisableStoreVariables,
  CancelStore,
  CancelStoreVariables,
  Tasks,
  TasksVariables,
  IterationList,
  IterationListVariables,
  EditPostTaskIssues,
  EditPostTaskIssuesVariables,
  EditPageTaskIssues,
  EditPageTaskIssuesVariables,
  SetTaskDone,
  SetTaskDoneVariables,
  SetPublishingTarget,
  SetPublishingTargetVariables,
  ScheduledPublishingTargets,
  ScheduledPublishingTargetsVariables,
  CreateFbPageTasks,
  CreateFbPageTasksVariables,
  GenerateIterationReports,
  GenerateIterationReportsVariables,
  ReactivateStore,
  ReactivateStoreVariables,
  AvailableReports,
  AvailableReportsVariables,
  RefreshStoreStatistics,
  RefreshStoreStatisticsVariables,
  UpdateFbPageTaskImage,
  UpdateFbPageTaskImageVariables,
  IterationPermissions,
  IterationPermissionsVariables,
  ResetTask,
  ResetTaskVariables,
  ChangePassword,
  ChangePasswordVariables,
  ResetPassword,
  ResetPasswordVariables,
  RequestPasswordReset,
  RequestPasswordResetVariables,
  LoginSso,
  LoginSsoVariables,
  CancelTask,
  CancelTaskVariables,
  UploadFbPageTaskImage,
  UploadFbPageTaskImageVariables,
  TasksPerStoreVariables,
  EditReviewTaskCompliancy,
  EditReviewTaskCompliancyVariables,
  Dashboard,
  DashboardVariables,
} from '../../schema';
import { useEditReviewCompliancyMutation } from './resolvers/mutations/edit-review-task-compliancy';

type AddNotification = (notification: Notification) => void;

async function executeResolver<T, V>(
  resolver: (variables: V) => Promise<T>,
  variables: V,
  addNotification?: AddNotification,
): Promise<T> {
  try {
    const res = await resolver(variables);
    return res;
  } catch (e) {
    const err = e as Error;
    if (addNotification) {
      fromError(err).forEach(pretty =>
        addNotification({ message: pretty.message, variant: 'error' }),
      );
    }
    throw e;
  }
}

export function useGqlClient() {
  const { addNotification } = useContext(NotificationsContext);
  const getStores = useGetStoresDirectQuery();
  const createStore = useCreateStoreMutation();
  const updateStore = useUpdateStoreMutation();
  const importStores = useImportStoresMutation();
  const evaluateImport = useEvaluateImportMutation();
  const storeOnboardingDone = useStoreOnboardingDoneMutation();
  const sendInvitationEmail = useSendInvitationEmailMutation();
  const sendPdfReport = useSendPdfReportMutation();
  const disableStore = useDisableStoreMutation();
  const cancelStore = useCancelStoreMutation();
  const getTasks = useGetTasksDirectQuery();
  const getTasksPerStore = useGetTasksPerStoreDirectQuery();
  const getIterationList = useGetIterationListDirectQuery();
  const editPostTaskIssues = useEditPostTaskIssuesMutation();
  const editPageTaskIssues = useEditPageTaskIssuesMutation();
  const editReviewTaskCompliancy = useEditReviewCompliancyMutation();
  const setTaskDone = useSetTaskDoneMutation();
  const getScheduledPublishingTargets = useGetScheduledPublishingTargetsDirectQuery();
  const setPublishingTarget = useSetPublishingTargetMutation();
  const createFbPageTasks = useCreateFbPageTasksMutation();
  const generateIterationReports = useGenerateIterationReportsMutation();
  const reactivateStore = useReactivateStoreMutation();
  const getAvailableReports = useGetAvailableReportsDirectQuery();
  const refreshStoreStatistics = useRefreshStoreStatisticsMutation();
  const updateFbPageTaskImage = useUpdateFbPageTaskImageMutation();
  const iterationPermissions = useIterationPermissionsDirectQuery();
  const resetTask = useResetTaskMutation();
  const changePassword = useChangePasswordMutation();
  const resetPassword = useResetPasswordMutation();
  const requestPasswordReset = useRequestPasswordResetMutation();
  const loginSso = useLoginSsoMutation();
  const cancelTask = useCancelTaskMutation();
  const uploadFbPageTaskImage = useUploadFbPageTaskImageMutation();
  const getDashboard = useGetDashboardDirectQuery();

  const notification = useMemo(
    () => (disableNotificationOnError?: boolean) =>
      disableNotificationOnError ? undefined : addNotification,
    [addNotification],
  );

  return useMemo(
    () => ({
      getStores(input: StoresVariables): Promise<Stores> {
        return executeResolver(getStores, input, addNotification);
      },
      createStore(
        input: CreateStoreVariables,
        disableNotificationOnError?: boolean,
      ): Promise<CreateStore> {
        return executeResolver(
          createStore,
          input,
          notification(disableNotificationOnError),
        );
      },
      updateStore(
        input: UpdateStoreVariables,
        disableNotificationOnError?: boolean,
      ): Promise<UpdateStore> {
        return executeResolver(
          updateStore,
          input,
          notification(disableNotificationOnError),
        );
      },
      evaluateImport(
        input: EvaluateImportVariables,
        disableNotificationOnError?: boolean,
      ): Promise<EvaluateImport> {
        return executeResolver(
          evaluateImport,
          input,
          notification(disableNotificationOnError),
        );
      },
      importStores(
        input: ImportStoresVariables,
        disableNotificationOnError?: boolean,
      ): Promise<ImportStores> {
        return executeResolver(
          importStores,
          input,
          notification(disableNotificationOnError),
        );
      },
      storeOnboardingDone(
        input: StoreOnboardingDoneVariables,
        disableNotificationOnError?: boolean,
      ): Promise<StoreOnboardingDone> {
        return executeResolver(
          storeOnboardingDone,
          input,
          notification(disableNotificationOnError),
        );
      },
      sendInvitationEmail(
        input: SendInvitationEmailVariables,
        disableNotificationOnError?: boolean,
      ): Promise<SendInvitationEmail> {
        return executeResolver(
          sendInvitationEmail,
          input,
          notification(disableNotificationOnError),
        );
      },
      sendPdfReport(
        input: SendPdfReportVariables,
        disableNotificationOnError?: boolean,
      ): Promise<SendPdfReport> {
        return executeResolver(
          sendPdfReport,
          input,
          notification(disableNotificationOnError),
        );
      },
      disableStore(
        input: DisableStoreVariables,
        disableNotificationOnError?: boolean,
      ): Promise<DisableStore> {
        return executeResolver(
          disableStore,
          input,
          notification(disableNotificationOnError),
        );
      },
      cancelStore(
        input: CancelStoreVariables,
        disableNotificationOnError?: boolean,
      ): Promise<CancelStore> {
        return executeResolver(
          cancelStore,
          input,
          notification(disableNotificationOnError),
        );
      },
      getTasks(input: TasksVariables): Promise<Tasks> {
        return executeResolver(getTasks, input, addNotification);
      },
      getTasksPerStore(input: TasksPerStoreVariables): Promise<Tasks> {
        return executeResolver(getTasksPerStore, input, addNotification);
      },
      getYearMonthList(input: IterationListVariables): Promise<IterationList> {
        return executeResolver(getIterationList, input, addNotification);
      },
      editPostTaskIssues(
        input: EditPostTaskIssuesVariables,
      ): Promise<EditPostTaskIssues> {
        return executeResolver(editPostTaskIssues, input, addNotification);
      },
      editPageTaskIssues(
        input: EditPageTaskIssuesVariables,
      ): Promise<EditPageTaskIssues> {
        return executeResolver(editPageTaskIssues, input, addNotification);
      },
      editReviewTaskCompliancy(
        input: EditReviewTaskCompliancyVariables,
      ): Promise<EditReviewTaskCompliancy> {
        return executeResolver(
          editReviewTaskCompliancy,
          input,
          addNotification,
        );
      },
      setTaskDone(input: SetTaskDoneVariables): Promise<SetTaskDone> {
        return executeResolver(setTaskDone, input, addNotification);
      },
      getScheduledPublishingTargets(
        input: ScheduledPublishingTargetsVariables,
      ): Promise<ScheduledPublishingTargets> {
        return executeResolver(
          getScheduledPublishingTargets,
          input,
          addNotification,
        );
      },
      setPublishingTarget(
        input: SetPublishingTargetVariables,
      ): Promise<SetPublishingTarget> {
        return executeResolver(setPublishingTarget, input, addNotification);
      },
      createFbPageTasks(
        input: CreateFbPageTasksVariables,
      ): Promise<CreateFbPageTasks> {
        return executeResolver(createFbPageTasks, input, addNotification);
      },
      generateIterationReports(
        input: GenerateIterationReportsVariables,
      ): Promise<GenerateIterationReports> {
        return executeResolver(
          generateIterationReports,
          input,
          addNotification,
        );
      },
      reactivateStore(
        input: ReactivateStoreVariables,
        disableNotificationOnError?: boolean,
      ): Promise<ReactivateStore> {
        return executeResolver(
          reactivateStore,
          input,
          notification(disableNotificationOnError),
        );
      },
      getAvailableReports(
        input: AvailableReportsVariables,
      ): Promise<AvailableReports> {
        return executeResolver(getAvailableReports, input, addNotification);
      },
      refreshStoreStatistics(
        input: RefreshStoreStatisticsVariables,
        disableNotificationOnError?: boolean,
      ): Promise<RefreshStoreStatistics> {
        return executeResolver(
          refreshStoreStatistics,
          input,
          notification(disableNotificationOnError),
        );
      },
      updateFbPageTaskImage(
        input: UpdateFbPageTaskImageVariables,
        disableNotificationOnError?: boolean,
      ): Promise<UpdateFbPageTaskImage> {
        return executeResolver(
          updateFbPageTaskImage,
          input,
          notification(disableNotificationOnError),
        );
      },
      iterationPermissions(
        input: IterationPermissionsVariables,
        disableNotificationOnError?: boolean,
      ): Promise<IterationPermissions> {
        return executeResolver(
          iterationPermissions,
          input,
          notification(disableNotificationOnError),
        );
      },
      resetTask(
        input: ResetTaskVariables,
        disableNotificationOnError?: boolean,
      ): Promise<ResetTask> {
        return executeResolver(
          resetTask,
          input,
          notification(disableNotificationOnError),
        );
      },
      changePassword(
        input: ChangePasswordVariables,
        disableNotificationOnError?: boolean,
      ): Promise<ChangePassword> {
        return executeResolver(
          changePassword,
          input,
          notification(disableNotificationOnError),
        );
      },
      resetPassword(
        input: ResetPasswordVariables,
        disableNotificationOnError?: boolean,
      ): Promise<ResetPassword> {
        return executeResolver(
          resetPassword,
          input,
          notification(disableNotificationOnError),
        );
      },
      requestPasswordReset(
        input: RequestPasswordResetVariables,
        disableNotificationOnError?: boolean,
      ): Promise<RequestPasswordReset> {
        return executeResolver(
          requestPasswordReset,
          input,
          notification(disableNotificationOnError),
        );
      },
      loginSso(
        input: LoginSsoVariables,
        disableNotificationOnError?: boolean,
      ): Promise<LoginSso> {
        return executeResolver(
          loginSso,
          input,
          notification(disableNotificationOnError),
        );
      },
      cancelTask(
        input: CancelTaskVariables,
        disableNotificationOnError?: boolean,
      ): Promise<CancelTask> {
        return executeResolver(
          cancelTask,
          input,
          notification(disableNotificationOnError),
        );
      },
      uploadFbPageTaskImage(
        input: UploadFbPageTaskImageVariables,
        disableNotificationOnError?: boolean,
      ): Promise<UploadFbPageTaskImage> {
        return executeResolver(
          uploadFbPageTaskImage,
          input,
          notification(disableNotificationOnError),
        );
      },
      getDashboard(input: DashboardVariables): Promise<Dashboard> {
        return executeResolver(getDashboard, input, addNotification);
      },
    }),
    [
      addNotification,
      cancelTask,
      changePassword,
      createFbPageTasks,
      createStore,
      disableStore,
      cancelStore,
      editPageTaskIssues,
      editPostTaskIssues,
      editReviewTaskCompliancy,
      evaluateImport,
      generateIterationReports,
      getAvailableReports,
      getDashboard,
      getIterationList,
      getScheduledPublishingTargets,
      getStores,
      getTasks,
      getTasksPerStore,
      importStores,
      iterationPermissions,
      loginSso,
      notification,
      reactivateStore,
      refreshStoreStatistics,
      requestPasswordReset,
      resetPassword,
      resetTask,
      sendInvitationEmail,
      sendPdfReport,
      setPublishingTarget,
      setTaskDone,
      storeOnboardingDone,
      updateFbPageTaskImage,
      updateStore,
      uploadFbPageTaskImage,
    ],
  );
}
