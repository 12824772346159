import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import {
  GenerateIterationReports,
  GenerateIterationReportsVariables,
} from '../../../../schema';

const GENERATE_ITERATION_REPORTS = gql`
  mutation GenerateIterationReports($input: IterationsInput!) {
    generateIterationReports(input: $input) {
      message
    }
  }
`;

export function useGenerateIterationReportsMutation() {
  return useDirectMutation<
    GenerateIterationReports,
    GenerateIterationReportsVariables
  >(GENERATE_ITERATION_REPORTS);
}
