import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { VisibleGmbPosts, VisibleGmbPostsVariables } from '../../../../schema';

const VISIBLE_GMB_POSTS = gql`
  query VisibleGmbPosts($iterationId: Int!, $storeId: Int!) {
    visibleGmbPosts(iterationId: $iterationId, storeId: $storeId) {
      name
      create_time
      update_time
      details {
        media {
          type
          url
          isLocalFile
        }
        previewUrl
        message
      }
    }
  }
`;

export function useGetVisibleGmbPostsQuery(
  variables: VisibleGmbPostsVariables,
) {
  return useQuery<VisibleGmbPosts, VisibleGmbPostsVariables>(
    VISIBLE_GMB_POSTS,
    {
      variables,
      fetchPolicy: 'network-only',
    },
  );
}
