export enum Permission {
  STORE_SEND_INVITATION_EMAIL = 'STORE_SEND_INVITATION_EMAIL',
  STORE_SET_ONBOARDING_DONE = 'STORE_SET_ONBOARDING_DONE',
  STORE_DISABLE = 'STORE_DISABLE',
  STORE_EDIT = 'STORE_EDIT',
  STORE_REACTIVATE = 'STORE_REACTIVATE',
  STORE_VIEW_DETAILS = 'STORE_VIEW_DETAILS',
  STORE_REFRESH_STATISTICS_FROM_UI = 'STORE_REFRESH_STATISTICS_FROM_UI',
  STORE_CANCEL = 'STORE_CANCEL',
  TASK_EDIT_ISSUES = 'TASK_EDIT_ISSUES',
  TASK_SET_DONE = 'TASK_SET_DONE',
  TASK_RESET = 'TASK_RESET',
  TASK_CANCEL = 'TASK_CANCEL',
  ITERATION_CREATE_FB_PAGE_TASK = 'ITERATION_CREATE_FB_PAGE_TASK',
  ITERATION_GENERATE_REPORT = 'ITERATION_GENERATE_REPORT',
  ITERATION_GET_REPORT = 'ITERATION_GET_REPORT',
  ITERATION_SEND_REPORT = 'ITERATION_SEND_REPORT',
  USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD',
  USER_RESET_PASSWORD = 'USER_RESET_PASSWORD',
}

export type StatChannel = 'FACEBOOK' | 'INSTAGRAM' | 'GOOGLE_MYBUSINESS';

export const CHANNEL_COLOURS: Record<StatChannel, string> = {
  FACEBOOK: '#EBA344',
  INSTAGRAM: '#8D6CAB',
  GOOGLE_MYBUSINESS: '#DD5143',
};

export enum StoreStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  ONBOARDING = 'ONBOARDING',
  ACTIVATING = 'ACTIVATING',
  CANCELED = 'CANCELED',
}

export enum TaskType {
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  FACEBOOK_POST = 'FACEBOOK_POST',
  GOOGLE_MYBUSINESS_POST = 'GOOGLE_MYBUSINESS_POST',
  GOOGLE_MYBUSINESS_REVIEW = 'GOOGLE_MYBUSINESS_REVIEW',
  INSTAGRAM_POST = 'INSTAGRAM_POST',
}

export enum TaskStatus {
  DONE = 'DONE',
  NEW = 'NEW',
  CANCELED = 'CANCELED',
}

export enum GlobalPermission {
  ACCESS_STORE_LIST = 'ACCESS_STORE_LIST',
  ACCESS_ITERATION_LIST = 'ACCESS_ITERATION_LIST',
  ACCESS_TASK_LIST = 'ACCESS_TASK_LIST',
  ACCESS_DASHBOARD = 'ACCESS_DASHBOARD',
  IMPORT_STORES = 'IMPORT_STORES',
  SET_SCHEDULED_PUBLISHING_TARGET = 'SET_SCHEDULED_PUBLISHING_TARGET',
  GET_STORES_REPORT = 'GET_STORES_REPORT',
  GET_TASKS_REPORT = 'GET_TASKS_REPORT',
  GET_ITERATIONS_REPORT = 'GET_ITERATIONS_REPORT',
}

export enum Role {
  STORE_MANAGER = 'STORE_MANAGER',
  AREA_MANAGER = 'AREA_MANAGER',
  HEADQUARTER = 'HEADQUARTER',
  OPERATOR = 'OPERATOR',
  SUPER_USER = 'SUPER_USER',
  SALES_DIRECTOR = 'SALES_DIRECTOR',
  DIGITAL_ACCOUNT = 'DIGITAL_ACCOUNT',
  SALES_ACCOUNT = 'SALES_ACCOUNT',
}

export enum CompliancyIssue {
  TOO_MANY_EMOJI = 'TOO_MANY_EMOJI',
  TOO_MUCH_TEXT = 'TOO_MUCH_TEXT',
  QUOTED_COMPETITORS_IN_TEXT = 'QUOTED_COMPETITORS_IN_TEXT',
  QUOTED_COMPETITORS_IN_IMAGE = 'QUOTED_COMPETITORS_IN_IMAGE',
  MISALIGNED_GRAPHICS = 'MISALIGNED_GRAPHICS',
  CANVAS_IMAGES = 'CANVAS_IMAGES',
  UNAUTHORIZED_OR_RESERVED_DATA = 'UNAUTHORIZED_OR_RESERVED_DATA',
  SHARED_FAKE_OR_UNAUTHORIZED_NEWS = 'SHARED_FAKE_OR_UNAUTHORIZED_NEWS',
  LOW_IMAGE_QUALITY = 'LOW_IMAGE_QUALITY',
  UNCLEAR_IMAGE = 'UNCLEAR_IMAGE',
  UNAUTHORIZED_IMAGES = 'UNAUTHORIZED_IMAGES',
  BRAND_COMMUNICATION_IMAGE_CHANGED = 'BRAND_COMMUNICATION_IMAGE_CHANGED',
  LOGO_WITH_BUSINESS_NAME = 'LOGO_WITH_BUSINESS_NAME',
  BRAND_COMMUNICATION_TEXT_NOT_RESPECTED = 'BRAND_COMMUNICATION_TEXT_NOT_RESPECTED',
  BRAND_COMMUNICATION_TEXT_PARTIALLY_RESPECTED = 'BRAND_COMMUNICATION_TEXT_PARTIALLY_RESPECTED',
  TOO_MANY_HASHTAG = 'TOO_MANY_HASHTAG',
  MISSING_STREAM_HASHTAG = 'MISSING_STREAM_HASHTAG',
  WRONG_USAGE_OF_STREAM_HASHTAG = 'WRONG_USAGE_OF_STREAM_HASHTAG',
  WRONG_USAGE_OF_EDITABLE_TEXT_IN_IMAGE = 'WRONG_USAGE_OF_EDITABLE_TEXT_IN_IMAGE',
  GRAMMAR_ERROR_OR_FOUL_LANGUAGE = 'GRAMMAR_ERROR_OR_FOUL_LANGUAGE',
  INAPPROPRIATE_IMAGE = 'INAPPROPRIATE_IMAGE',
  WRONG_PLATFORM_IMAGE = 'WRONG_PLATFORM_IMAGE',
  WRONG_PLATFORM_TEXT = 'WRONG_PLATFORM_TEXT',
  GDPR_VIOLATION = 'GDPR_VIOLATION',
  CONDITIONS_NOT_RESPECTED = 'CONDITIONS_NOT_RESPECTED',
  PRIVACY_VIOLATION = 'PRIVACY_VIOLATION',
}

export enum TaskFlag {
  POST_CHANGED = 'POST_CHANGED',
  PROCESSED_BY_SYSTEM = 'PROCESSED_BY_SYSTEM',
  STATUS_MANUALLY_SET = 'STATUS_MANUALLY_SET',
}

export enum GmbStarRating {
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE = 'FIVE',
}

export enum MediaType {
  IMAGE = 'IMAGE',
  VIDEO_THUMBNAIL = 'VIDEO_THUMBNAIL',
}

export enum Channel {
  FB = 'FB',
  IG = 'IG',
  GMB = 'GMB',
}

export enum ChannelStatus {
  COMPLETE = 'COMPLETE',
  PARTIAL = 'PARTIAL',
}

export enum SsoType {
  DEFAULT = 'DEFAULT',
  GOTU = 'GOTU',
}

export type IterationDates = { startsAt: string; endsBefore: string };
