import gql from 'graphql-tag';
import { useDirectMutation } from '../../lib/graphql/graphql';
import {
  ClaimFacebookPageWithPageToken,
  ClaimFacebookPageWithPageTokenVariables,
} from '../../schema';

const CLAIM_FACEBOOK_PAGE_WITH_PAGE_TOKEN = gql`
  mutation ClaimFacebookPageWithPageToken(
    $pageId: String!
    $pageToken: String!
  ) {
    claimFacebookPageWithPageToken(pageToken: $pageToken, pageId: $pageId) {
      success
    }
  }
`;

export function useClaimFacebookPageWithPageTokenMutation() {
  return useDirectMutation<
    ClaimFacebookPageWithPageToken,
    ClaimFacebookPageWithPageTokenVariables
  >(CLAIM_FACEBOOK_PAGE_WITH_PAGE_TOKEN);
}
