import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  genericBox: {
    background: '#eee',
    borderRadius: 7,
    textAlign: 'center',
  },
}));

const GenericBox: React.FC<{
  label: string;
  value: string | number;
  color: string;
}> = ({ label, value, color }) => {
  const classes = useStyles();
  return (
    <Box p={2}>
      <Box p={1} className={classes.genericBox}>
        <Box color={color}>
          <Typography variant="body1">{label}</Typography>
        </Box>
        <Typography variant="body1">{value}</Typography>
      </Box>
    </Box>
  );
};
export default GenericBox;
