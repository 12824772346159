import { useContext } from 'react';
import { UpsertStoreInput } from '../../schema';
import { applyValidators, ValidatorInput, Validator } from './apply-validators';
import {
  createMinTextLengthValidator,
  createRequiredValidator,
  createNoWhiteSpacesValidator,
  createEmailValidator,
  createUrlValidator,
  createOnlyNumbersValidator,
  createDateStringValidator,
} from './common-validators';
import { COUNTRIES } from '../countries';
import {
  InterfaceLanguageLocale,
  ClientDependantEditableStoreField,
} from '../client-config-response';
import { ClientConfigContext } from '../client-config';
import { useTranslation } from 'react-i18next';

export function useStoreInputValidate() {
  const {
    config: { storeLanguages, editableStoreFields, featureFlags },
  } = useContext(ClientConfigContext);
  const { t } = useTranslation();

  const availableLocales = storeLanguages.map(l => l.locale);

  return function (
    input: UpsertStoreInput,
  ): Record<keyof UpsertStoreInput, string[] | null> {
    const setValidators = (
      fieldName: ClientDependantEditableStoreField,
      validators: Validator[],
    ) => {
      const field = editableStoreFields.find(f => f.name == fieldName);
      const isRequired = field ? field.required : false;
      if (isRequired) {
        validators.push(createRequiredValidator(t));
      }
      return applyValidators(input[fieldName], validators);
    };

    return {
      remoteId: applyValidators(input.remoteId, [
        createRequiredValidator(t),
        createMinTextLengthValidator(t, 5),
        createNoWhiteSpacesValidator(t),
      ]),
      companyName: applyValidators(input.companyName, [
        createRequiredValidator(t),
        createMinTextLengthValidator(t, 3),
      ]),
      facebookPage: applyValidators(input.facebookPage, [
        createMinTextLengthValidator(t, 10),
        createOnlyNumbersValidator(t),
      ]),
      googleMybusinessLocation: applyValidators(
        input.googleMybusinessLocation,
        [
          (val: ValidatorInput) => {
            if (!val) {
              return undefined;
            }
            const strVal = String(val);
            const minLen = 10;
            if (strVal.length < minLen) {
              return t('validation-errors.must-be-characters-long', {
                num: minLen,
              });
            }

            const hasSlashes = strVal.includes('/');
            // if client has multiple gmb accounts then locations must be specified in full
            if (
              featureFlags.includes('HAS_MULTIPLE_GMB_ACCOUNTS') &&
              !hasSlashes
            ) {
              return t('validation-errors.not-a-canonical-gmb-location');
            }

            // if location is specified in full then it must be valid
            if (hasSlashes) {
              if (!/accounts\/[0-9]+\/locations\/[0-9]+$/.test(strVal)) {
                return t('validation-errors.not-a-canonical-gmb-location');
              }
            } else {
              if (!/^[0-9]+$/.test(strVal)) {
                return t('validation-errors.only-numbers');
              }
            }

            return undefined;
          },
        ],
      ),
      // following fields depend on the client, required validator depends on client config
      contactName: setValidators('contactName', []),
      storeManagerEmail: setValidators('storeManagerEmail', [
        createEmailValidator(t),
      ]),
      phone: setValidators('phone', []),
      website: setValidators('website', [createUrlValidator(t)]),
      area: setValidators('area', [createMinTextLengthValidator(t, 3)]),
      areaManagerEmail: setValidators('areaManagerEmail', [
        createEmailValidator(t),
      ]),
      salesDirectorEmail: setValidators('salesDirectorEmail', [
        createEmailValidator(t),
      ]),
      salesAccountEmail: setValidators('salesAccountEmail', [
        createEmailValidator(t),
      ]),
      digitalAccountEmail: setValidators('digitalAccountEmail', [
        createEmailValidator(t),
      ]),
      operatorEmail: setValidators('operatorEmail', []),
      // need to validate possible values for import from file
      locale: setValidators('locale', [
        (val: ValidatorInput) => {
          if (!val) {
            return undefined;
          }
          if (
            !availableLocales.includes(String(val) as InterfaceLanguageLocale)
          ) {
            return t('validation-errors.invalid-locale', {
              availableLocales: availableLocales.join(', '),
            });
          }
          return undefined;
        },
      ]),
      // need to validate possible values for import from file
      country: setValidators('country', [
        (val: ValidatorInput) => {
          if (!val) {
            return undefined;
          }
          const countryCodes = COUNTRIES.map(country => country.alpha3code);
          if (!countryCodes.includes(String(val))) {
            return t('validation-errors.invalid-country');
          }
          return undefined;
        },
      ]),
      city: setValidators('city', [createMinTextLengthValidator(t, 3)]),
      address: setValidators('address', [createMinTextLengthValidator(t, 3)]),
      zipCode: setValidators('zipCode', [createMinTextLengthValidator(t, 3)]),
      // need to validate possible values for import from file
      serviceStartedAt: setValidators('serviceStartedAt', [
        createDateStringValidator(t),
      ]),
      notes: setValidators('notes', []),
    };
  };
}
