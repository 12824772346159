import { useDirectQuery } from '../../graphql';
import gql from 'graphql-tag';
import {
  IterationPermissions,
  IterationPermissionsVariables,
} from '../../../../schema';

const ITERATION_PERMISSIONS = gql`
  query IterationPermissions($id: Int!, $storeId: Int!) {
    iteration(id: $id, storeId: $storeId) {
      id
      permissions
    }
  }
`;

export function useIterationPermissionsDirectQuery() {
  return useDirectQuery<IterationPermissions, IterationPermissionsVariables>(
    ITERATION_PERMISSIONS,
  );
}
