import { useDirectMutation } from '../../graphql';
import gql from 'graphql-tag';
import { CancelTask, CancelTaskVariables } from '../../../../schema';

const CANCEL_TASK = gql`
  mutation CancelTask($input: CancelTaskInput!) {
    cancelTask(input: $input) {
      task {
        id
        permissions
      }
    }
  }
`;

export function useCancelTaskMutation() {
  return useDirectMutation<CancelTask, CancelTaskVariables>(CANCEL_TASK);
}
