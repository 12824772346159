import React from 'react';
import { StatChannel } from '../../lib/types';

const ICON_STYLE: Record<string, unknown> = {
  width: 24,
  height: 24,
  marginRight: 7,
};

const channelIcons = (grayscale: boolean, size?: number) => {
  let style = { ...ICON_STYLE };
  if (grayscale) {
    style = { ...style, filter: 'grayscale(1)' };
  }
  if (size) {
    style = { ...style, width: size, height: size };
  }
  return {
    FACEBOOK: <img src="/img/facebook-icon.png" alt="fb" style={style} />,
    INSTAGRAM: <img src="/img/instagram-icon.png" alt="ig" style={style} />,
    GOOGLE_MYBUSINESS: (
      <img src="/img/google-my-business-icon.png" alt="gmb" style={style} />
    ),
  };
};

interface Props {
  channel: StatChannel;
  grayscale?: boolean;
  size?: number;
}

const ChannelsIcon: React.FC<Props> = ({ channel, grayscale, size }) => {
  return channelIcons(grayscale ? grayscale : false, size)[channel];
};

export default ChannelsIcon;
